import classNames from 'classnames';
import { X16x16 } from '@bamboohr/grim';
import { IconButton } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import React, { Fragment } from 'react';
import './styles.styl';

export const ModalTitle = function ModalTitle(props) {
	const {
		handleClose,
		hasCloseButton,
		isHeadless,
		isLoading,
		isMobileFriendly = false,
		isMobileFriendlySheetFullScreen = false,
		isProcessing,
		isSheet,
		title,
		type,
	} = props;

	const encoreCloseButton = (
		<div
			className={classNames('legacyModal__titleGrid', {
				'legacyModal__titleGrid--noTitle': isHeadless || !title,
				'legacyModal__titleGrid--fullScreen': type === 'fullScreen',
				'legacyModal__titleGrid--hidden': isLoading,
			})}
		>
			{!isHeadless &&
				type !== 'fullScreen' &&
				React.createElement(
					isSheet ? 'h4' : 'h3',
					{
						className: classNames('legacyModal__title', {
							'legacyModal__title--hidden': isLoading,
							'legacyModal__title--hasCloseButton':
								hasCloseButton && !isProcessing,
							[`legacyModal__title--${type}--mobileHide`]:
								(isMobileFriendly && !isSheet) ||
								(isMobileFriendlySheetFullScreen && isSheet),
						}),
						id: 'fabricModalTitle',
					},
					title,
				)}
			{hasCloseButton && !isProcessing && (
				<div className="legacyModal__titleCloseButton">
					<IconButton
						aria-label={$.__('close modal')}
						icon="xmark-solid"
						onClick={handleClose}
						size={type === 'fullscreen' ? 'medium' : 'small'}
						type="button"
						variant="outlined"
					/>
				</div>
			)}
		</div>
	);

	/**
	 * NOTE: do not cleanup until Greenhouse is updated for Encore.
	 * Will cause breaking changes to Greenhouse Jade Styles
	 */
	// @startCleanup encore
	const jadeCloseButton = (
		<Fragment>
			{!isHeadless &&
				type !== 'fullScreen' &&
				React.createElement(
					isSheet ? 'h4' : 'h3',
					{
						className: classNames('legacyModal__title', {
							'legacyModal__title--hidden': isLoading,
							'legacyModal__title--hasCloseButton':
								hasCloseButton && !isProcessing,
							[`legacyModal__title--${type}--mobileHide`]:
								(isMobileFriendly && !isSheet) ||
								(isMobileFriendlySheetFullScreen && isSheet),
						}),
						id: 'fabricModalTitle',
					},
					title,
				)}
			{hasCloseButton && !isProcessing && (
				<IconButton
					aria-label={$.__('Close Modal')}
					className={classNames(
						'legacyModal__closeButton',
						isLoading && 'legacyModal__closeButton--hidden',
					)}
					icon={<X16x16 />}
					noBoundingBox={true}
					onClick={handleClose}
					type="button"
				/>
			)}
		</Fragment>
	);
	// @endCleanup encore

	return ifFeature('encore', encoreCloseButton, jadeCloseButton);
};
