import { FunctionComponent } from 'react';
import { Props } from './text';

const TextHoverField: FunctionComponent<React.PropsWithChildren<Props>> = ({ props, settings, input, context }) => {
	const { value = '' } = props;

	const { hover, initialValue } = settings;

	const {
		form: { isEditable = true },
		controls: { HoverAction, HoverEmail, HoverLink, HoverMap },
	} = context;

	const hoverElements = {
		link: HoverLink,
		email: HoverEmail,
		map: HoverMap,
		action: HoverAction,
	};

	// only show if initialValue was set. Don't create a hover input for a new field.
	if (hover && hover.template && initialValue) {
		const HoverTemplate = hoverElements[hover.template];
		if (HoverTemplate) {
			return (
				<HoverTemplate
					context={ context }
					hover={ hover }
					isEditable={ isEditable }
					value={ value }
				>
					{ input }
				</HoverTemplate>
			);
		}
	}
	return input;
};

export default TextHoverField;
