import { hasTrax } from 'BambooHR.util';
import { isSelectedPSSyncing, isNewEmployeePage } from 'dynamic-form';

export const currencyTypeMatch = (
	context: DynamicForm.Context,
	field: DynamicForm.DataProps,
	params: any,
) => {
	return (value: string | undefined): boolean => {
		const {
			settings: {
				currency: { code = '' },
			},
		} = field as DynamicForm.TextElement;

		let valid = code === params.currency;
		const { Employee } = window;

		const name = Employee.firstName ? Employee.firstName : 'an employee';

		if (params.onlyIfTrax) {
			const isTraxAndSyncing = hasTrax() && isSelectedPSSyncing(context);
			if (!isTraxAndSyncing) {
				valid = true;
			}
		}

		if (isNewEmployeePage()) {
			valid = true;
		}

		if (!valid) {
			window.setMessage(
				$.__(
					'Oops, please make sure the pay rate is in %1 before adding %2 to a pay schedule that syncs with BambooHR Payroll.',
					params.currency,
					name,
				),
				'error',
			);
		}

		return valid;
	};
};
