import {
	BodyText,
	Flex,
	IconV2,
	LayoutBox,
} from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';

import './deposit-account.styl';

export function DepositAccount(props) {
	const {
		amount,
		errorMessage = '',
		imageUrl,
		isBankImageUrl,
		name,
		number,
		order,
		type,
		first,
		last,
		single,
	} = props;

	const boldClass = 'DepositAccount__text--bold';

	const text = first || single
		? (
			<Message
				em={ {tag: 'span', className: boldClass} }
				params={ [amount, name, type, number] }
				text={ $._(`*{1} of each check* goes to {2} {3} {4}`) }
			/>
		)
		: (
			<Message
				em={ {tag: 'span', className: boldClass} }
				params={ [amount, name, type, number] }
				text={ $._(`*{1} of the remaining amount* goes to {2} {3} {4}`) }
			/>
		);

	return (
			<Flex alignItems="center" marginBottom={4}>
				{isBankImageUrl ? (
					<img alt="bank logo" className="DepositAccount__logo" src={ imageUrl } />
				) : (
					<LayoutBox marginRight={1.25} textAlign="center" width="81px">
						<IconV2
							color="neutral-weak"
							name="building-columns-regular"
							size={24}
						/>
					</LayoutBox>
				)}
				<BodyText>{ text }</BodyText>
				{errorMessage && (
					<BodyText color="error-medium">
						{ errorMessage }
					</BodyText>
				)}
			</Flex>
		);
}
