import {
	Fragment,
	useState,
	useEffect,
} from 'react';

import { ifFeature } from '@bamboohr/utils/lib/feature';
import Ajax from '@utils/ajax';

import ModalContent from './modal-content.react';

const ENDPOINT = '/access_levels/payroll-admin/edit';

export default function showModal(user) {
	let title = $.__('Payroll Admin Settings');
	let btnText = $.__('Save');
	const url = new URL(ENDPOINT, window.location.href);
	const submitData = {};

	if (user) {
		title = $.__('Payroll Admin Employee Access Level');

		if (typeof user === 'object') {
			const {
				firstName,
				employeeId,
				userId,
			} = user;

			btnText = $.__('Save & Add %1$s', firstName);

			url.searchParams.append('user[firstName]', firstName);
			url.searchParams.append('user[employeeId]', employeeId);
			url.searchParams.append('user[userId]', userId);
		} else {
			url.searchParams.append('user', 'true');
		}
	}

	return new Promise((resolve, reject) => {
		window.BambooHR.Modal.setState({
			isOpen: true,
			title,
			headerType: 'text',
			headline: $.__('Payroll Admin'),
			icon: 'lock-regular',
			isLoading: true,
			primaryActionText: btnText,
			primaryAction: () => {
				if (!submitData.employeeAccessLevel) {
					window.BambooHR.Modal.setState({
						isOpen: false,
					});

					return;
				}

				submitModal(submitData)
					.then(resolve)
					.catch(reject);
			},
			content: (
				<ModalContent
					onEmployeeAccessLevelChange={ (value) => {
						submitData.employeeAccessLevel = value;
					} }
					url={ url.href }
				/>
			),
		});
	});
}

function submitModal({
	employeeAccessLevel,
}) {
	window.BambooHR.Modal.setState({
		isProcessing: true,
	}, true);

	return Ajax.post(ENDPOINT, {
		employeeAccessLevel,
	})
		.then(({ data }) => {
			if (!data.success) {
				throw new Error(data);
			}

			if (data.message) {
				window.setMessage(data.message, 'success');
			}

			window.BambooHR.Modal.setState({
				isOpen: false,
			});

			return {
				...data,
				employeeAccessLevel,
			};
		})
		.catch((err) => {
			window.BambooHR.Modal.setState({
				isProcessing: false,
			}, true);

			window.setMessage(err.errorMessage || window.DEFAULT_ERROR_MESSAGE, 'error');
			throw new Error(err.error || err);
		});
}
