import { LayoutBox, Section } from '@bamboohr/fabric';
import { FunctionComponent, useEffect, useState } from 'react';
import { Fields, getField, getSelectedValue, isSelectedPSSyncing, forceFormRender } from 'dynamic-form';

const taxEmployeeTypeId1099 = 3;

const PayrollFieldSet: FunctionComponent<React.PropsWithChildren<DynamicForm.FieldSetElementProps>> = (fieldSetProps) => {
	const {
		props,
		children,
		context,
	} = fieldSetProps;
	const { className = '' } = props;
	const [show, setShow] = useState(false);

	const isSyncing = isSelectedPSSyncing(context);
	const taxTypeField = getField(context, Fields.employeeTaxType) as DynamicForm.SelectElement;
	const taxTypeValue = getSelectedValue(taxTypeField);

	useEffect(() => {
		// only show the payroll fields if syncing and TT isn't 1099
		if (isSyncing) {
			if (taxTypeValue && taxTypeValue !== taxEmployeeTypeId1099.toString()) {
				setShow(true);
				return;
			}
		}
		setShow(false);
	}, [isSyncing, taxTypeValue]);

	useEffect(() => {
		// we only need to force re-render if true (so children properly get set required)
		if (show) {
			setTimeout(() => {
				forceFormRender(context);
			}, 10);
		}
	}, [show]);

	return (
        <fieldset
			{...props}
			className={`${className} ${show ? '' : 'hidden'}`}
		>
			<LayoutBox marginTop={4}>
				<Section>{children}</Section>
			</LayoutBox>
		</fieldset>
    );
};

export default PayrollFieldSet;
