import { Component } from 'react';
import { Select } from '@fabric/select';
import {
	noop,
	uniqueId,
} from 'lodash';
import { timeOfDayInputEnforcer } from 'time-tracking/utils';
import { classNameFromObject } from '@utils/dom';
import './styles.styl';

export default class ClockFieldBox extends Component {
	_handleOnInput = (event) => {
		this._inputVal.value = timeOfDayInputEnforcer(event.target.value, this._previousVal);
		this._previousVal = this._inputVal.value;
	}

	_previousVal = '';

	componentDidUpdate() {
		this._previousVal = this.props.timeVal;
	}

	render() {
		const {
			autoFocus,
			label,
			timeVal,
			handleInputChange,
			handleInputBlur,
			handleInputFocus,
			selectProps,
			error,
			disabled,
		} = this.props;

		const autoFocusInput = autoFocus ? 'autofocus' : null;
		const inputChangeHandler = typeof handleInputChange === 'function' ? handleInputChange : noop;
		const inputBlurHandler = typeof handleInputBlur === 'function' ? handleInputBlur : noop;
		const inputFocusHandler = typeof handleInputFocus === 'function' ? handleInputFocus : noop;
		const fieldBoxClassObj = {
			'ClockField': true,
			'error': error
		};
		const id = uniqueId('clockField');

		return (
			<div className={ classNameFromObject(fieldBoxClassObj) }>
				<label className="fab-Label" htmlFor={ id }>{ label }</label>
				<div className="fieldDiv fab-FormField">
					<input
						autoFocus={ autoFocusInput }
						className="ClockField__formInput fab-TextInput fab-TextInput--width3"
						disabled={ disabled }
						id={ id }
						onBlur={ inputBlurHandler }
						onChange={ inputChangeHandler }
						onFocus={ inputFocusHandler }
						onInput={ this._handleOnInput }
						ref={ element => (this._inputVal = element) }
						type="text"
						value={ timeVal }
					/>
					<Select { ...selectProps } />
				</div>
			</div>
		);
	}

}
