import './styles.styl';
import { v4 as uuid } from 'uuid';
import {INCORRECT_TIME_ENTRIES_MSG} from 'time-tracking/constants';
import { render } from 'base/wrapped-render';
import { ClockedOutErrorModal } from './clocked-out-error-modal';

export default function (generalError, callback) {
	const modalRootId = uuid();

	const onClose = () => {
		callback();
		if (generalError) {
			window.setMessage(INCORRECT_TIME_ENTRIES_MSG, 'error');
		}
		document.body.removeChild(document.getElementById(modalRootId));
	}

	const modalRoot = document.createElement('div');
	modalRoot.setAttribute('id', modalRootId);
	render(<ClockedOutErrorModal onClose={onClose} />, document.body.appendChild(modalRoot));
}
