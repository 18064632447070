import {
	Flex,
	FullScreenModal,
	StyledBoxV2,
} from '@bamboohr/fabric';
import { ComponentClass, PureComponent, ReactElement, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { HeaderNavigation } from '../HeaderNavigation';
import { PreviewerFooter } from '../PreviewerFooter';
import UsesBambooHR from '../UsesBambooHR';
import ContentRouter from './contentRouter';

type State = {
	modalState: boolean;
	currentPage: number;
	headerName: string;
	companyName?: string;
	loading?: boolean;
};

type Props = State &
	RouteComponentProps<any> &
	PageData & {
		logo: Logo;
		withFinishedFooter: boolean;
		renderHeader: (props: {
			closeModal: () => void;
			headerAction: () => void;
		}) => ReactElement;
		headerAction: () => void;
		isNHPT: boolean;
		companyName: string;
	};

type PageData = {
	whenAndWhereComponent: ReactElement;
	newEmployeeInfoComponent: ReactElement;
	getIntroducedComponent?: ReactElement;
	showGetIntroduced?: boolean;
	tasksComponent?: ReactElement;
	showTasks?: boolean;
	finishedComponent: ReactElement;
};

export const PREVIEWER_MODAL_ID = 'PreviewerModal';

class PreviewerComponent extends PureComponent<Props, State> {
	pages: Array<any>;

	currentPage: number;

	constructor(props: Props) {
		super(props);

		const {
			loading = true,
			modalState,
			headerName,
			whenAndWhereComponent,
			newEmployeeInfoComponent,
			getIntroducedComponent,
			tasksComponent,
			finishedComponent,
			showGetIntroduced,
			showTasks,
		} = props;

		this.pages = this._populatePages({
			whenAndWhereComponent,
			newEmployeeInfoComponent,
			getIntroducedComponent,
			showGetIntroduced,
			tasksComponent,
			showTasks,
			finishedComponent,
		});

		let startingPage = 0;
		this.pages.forEach((page, ind) => {
			page.index = ind;
			if (location.hash.includes(page.route)) {
				startingPage = ind;
			}
		});

		this.state = {
			loading,
			modalState,
			currentPage: startingPage,
			headerName,
		};

		this._onRequestClose = this._onRequestClose.bind(this);
		this._nextPage = this._nextPage.bind(this);
		this._prevPage = this._prevPage.bind(this);
		this._changePage = this._changePage.bind(this);
		this._getPageNames = this._getPageNames.bind(this);
		this._resetPreview = this._resetPreview.bind(this);
	}

	_populatePages(pageData: PageData) {
		const pages = [];
		const {
			whenAndWhereComponent,
			newEmployeeInfoComponent,
			getIntroducedComponent,
			showGetIntroduced,
			tasksComponent,
			showTasks,
			finishedComponent,
		} = pageData;

		if (whenAndWhereComponent) {
			pages.push({
				name: $.__('When & Where'),
				route: 'when_and_where',
			});
		}
		if (newEmployeeInfoComponent) {
			pages.push({
				name: $.__('New Employee Info'),
				route: 'new_employee_info',
			});
		}
		if (getIntroducedComponent && showGetIntroduced) {
			pages.push({
				name: $.__('Get Introduced'),
				route: 'get_introduced',
			});
		}
		if (tasksComponent && showTasks) {
			pages.push({
				name: $.__('Tasks to Complete'),
				route: 'tasks_to_complete',
			});
		}
		if (finishedComponent) {
			pages.push({
				name: $.__('Finished'),
				route: 'finished',
			});
		}

		return pages;
	}

	_onRequestClose(): void {
		this.setState({
			modalState: false,
			loading: true,
			currentPage: 0,
		});

		window.previewUser = null;

		const { history } = this.props;

		history.replace({
			hash: '',
			search: window.location.search,
		});
	}

	_resetPreview(): void {
		window.ASSUMED_USER = undefined;
		this.setState({
			currentPage: 0,
		});
	}

	_nextPage() {
		let { currentPage } = this.state;
		this._changePage(++currentPage);
	}

	_prevPage() {
		let { currentPage } = this.state;
		this._changePage(--currentPage);
	}

	_changePage(page: number): void {
		if (page > -1 && page < this.pages.length) {
			this.setState({ currentPage: page });
			const { history } = this.props;
			history.push({
				hash: `preview/${this.pages[page].route}`,
				search: window.location.search,
			});
		}
	}

	_getPageNames(): Array<string> {
		return this.pages.map((page) =>
			page.name.replace(/([A-Z])/g, ' $1').trim(),
		);
	}

	componentDidUpdate(prevProps: Readonly<Props>) {
		const {
			whenAndWhereComponent,
			newEmployeeInfoComponent,
			getIntroducedComponent,
			showGetIntroduced,
			tasksComponent,
			showTasks,
			finishedComponent,
		} = this.props;

		this.pages = this._populatePages({
			whenAndWhereComponent,
			newEmployeeInfoComponent,
			getIntroducedComponent,
			showGetIntroduced,
			tasksComponent,
			showTasks,
			finishedComponent,
		});

		let activePage = 0;
		this.pages.forEach((page, ind) => {
			page.index = ind;
			if (location.hash.includes(page.route)) {
				activePage = ind;
			}
		});

		const { modalState: prevModalOpen, loading: prevIsLoading } = prevProps;

		const { modalState: modalOpen, loading: isLoading } = this.props;

		let modalStatus = prevModalOpen;
		let loading = prevIsLoading;
		if (modalOpen !== prevModalOpen) {
			modalStatus = modalOpen;
		}

		if (isLoading !== prevIsLoading) {
			loading = isLoading;
		}

		window.ASSUMED_USER = modalStatus ? 1 : undefined;

		this.setState({
			loading,
			modalState: modalStatus,
			currentPage: activePage,
		});
	}

	render(): ReactElement {
		const {
			companyName,
			isNHPT = false,
			whenAndWhereComponent,
			newEmployeeInfoComponent,
			getIntroducedComponent,
			tasksComponent,
			finishedComponent,
			headerAction,
			renderHeader,
		} = this.props;

		const { modalState, currentPage, loading = false } = this.state;
		const onLastPage = currentPage === this.pages.length - 1;

		const headerProps = {
			closeModal: this._onRequestClose,
			headerAction,
		};
		const header = renderHeader(headerProps);
		const footer = (
			<PreviewerFooter
				currentPage={currentPage}
				nextPageAction={this._nextPage}
				previousPageAction={this._prevPage}
				totalPages={this.pages.length}
			/>
		);

		const routes: {
			path: string;
			component: ReactNode;
		}[] = [
			{
				path: '/when_and_where*',
				component: whenAndWhereComponent,
			},
			{
				path: '/new_employee_info*',
				component: newEmployeeInfoComponent,
			},
			{
				path: '/get_introduced*',
				component: getIntroducedComponent,
			},
			{
				path: '/tasks_to_complete*',
				component: tasksComponent,
			},
			{
				path: '/finished*',
				component: finishedComponent,
			},
		];

		return (
			<FullScreenModal
				id={PREVIEWER_MODAL_ID}
				isLoading={loading}
				isOpen={modalState}
				onCloseComplete={this._resetPreview}
				onOpenComplete={() => {
					window.previewUser = true;
					window.closeMessage();
				}}
				onRequestClose={this._onRequestClose}
			>
				<FullScreenModal.Body
						noPadding={true}
						renderFooter={footer}
						renderHeader={header}
					>
						<StyledBoxV2
							backgroundColor="neutral-forced-white"
							flexGrow={1}
							padding="40px"
							paddingTop={0}
						>
							<Flex height="100%">
								<StyledBoxV2
									backgroundColor="neutral-extra-extra-weak"
									borderRadius="medium"
									flexGrow={1}
									overflow="auto"
									padding="40px"
								>
									<Flex flexDirection="column" height="100%">
										{!location.hash.includes('finished') && (
											<HeaderNavigation
												currentPage={currentPage}
												pageNames={this._getPageNames()}
											/>
										)}
										<ContentRouter
											currentPage={currentPage}
											loading={loading}
											pageCount={this.pages.length - 1}
											routes={routes}
										/>

										<UsesBambooHR
											companyName={companyName}
											isVisible={!isNHPT && onLastPage}
										/>
									</Flex>
								</StyledBoxV2>
							</Flex>
						</StyledBoxV2>
					</FullScreenModal.Body>
			</FullScreenModal>
		);
	}
}

const Previewer = withRouter(
	PreviewerComponent as ComponentClass<Props>,
);

export { Previewer };
