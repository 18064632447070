import Ajax from '@utils/ajax';
import { isEnabled } from 'FeatureToggle.util';
import { validateAddress } from './address-validation';

const MODAL = window.BambooHR.Modal;

const messages = {
	locationInUseError: $.__(
		'Oops. This location is already in use. Please choose a different one.',
	),
	locationGeneralError: $.__('Oops. Something went wrong. Please try again.'),
};

/**
 *   Opens up a modal with fields to gather information for location lists
 *
 *
 * @param {int} locationId		- required for edit, if null we assume we are adding
 * @param {type} editData		- an object with values to be passed in to the modal to populate the fields. Not needed if locationId exists.
 * @param {type} callback		- the function to call once this succeeds.
 * @returns void
 */
function showModal(locationId, editData, showEditRadioButtons, saveCallback, cancelCallback, options = {}) {
	// set vars
	const listValueId = editData['location[listValueId]'];
	const isEditing = !!locationId || !!listValueId;
	showEditRadioButtons = (typeof showEditRadioButtons === 'undefined' || showEditRadioButtons === 'undefined') ? true : showEditRadioButtons;
	const data = { locationId, action: isEditing ? 'Update' : 'Add', listValueId: listValueId, showEditRadioButtons };
	if (options && options.zenpayroll) {
		data.zenpayroll = options.zenpayroll;
	}
	let formObj = {}; // setting this as a variable so it can be used in multiple places

	const isSheet = options.hasOwnProperty('isSheet') && options.isSheet;

	const updateModalState = ((isSheet, state, persist = true) => {
		if (isSheet) {
			MODAL.setState({
				sheetProps: state,
			}, persist);
		} else {
			MODAL.setState(state, persist);
		}
	});

	const onClose = function() {
		formObj = {}; // reset formObj
		if (typeof cancelCallback === 'function') {
			cancelCallback(data);
		}
	};

	const onOpen = function() {
		const $form = $('#location_form');
		const $focusField = $form.find('input[type=text]:eq(0)');

		// Focus on first input field upon opening modal
		$focusField.attr('onfocus', 'this.value = this.value').focus();

		// Update each field with the zen payroll data or current location data (looks for location in the name attribute)
		// TODO: Update for Jade
		if (editData && typeof editData !== 'undefined') {
			$.each(editData, function(key, value) {
				if (key.indexOf('location') > -1 && value && value !== '') {
					const jObject = $form.find(`:input[name="${ key }"], ba-select[name="${ key }"]`);
					jObject.val(value);

					// Update the chosen list
					if (jObject.prop('tagName') === 'SELECT') {
						jObject.trigger('liszt:updated');
					// Or hide the label
					} else {
						jObject.siblings('label').hide();
					}
				}
			});
		}

		// Mask the phone number for zen payroll
		$('.zen_phone').mask('(999)999-9999');

		$form.initializeElements();
		$form.bhrValidate();

		function showHideAddressInfo() {
			if ($('#remoteLocation').is(':checked')) {
				$('.js-remote-toggle').hide();
			} else {
				$('.js-remote-toggle').show();
				$form.initializeElements();
			}
		}

		showHideAddressInfo();
		$('#remoteLocation').change(function() {
			showHideAddressInfo();
		});

		createCheckboxTooltip();
	};

	const createLocation = (formData) => {
		const url = '/location'
		return Ajax.post('/location', formData);
	}

	const updateLocation = (formData) => {
		// not sure if listValueId matters here.
		// const url = locationId ? `/location/${locationId}` : `/location/lv/${listValueId}`;
		const url = locationId ? `/location/${locationId}` : `/location/lv/${listValueId}`;

		return Ajax.put(url, formData);
	}

	const onSubmitForm = () => {
		// processing
		updateModalState(isSheet, { isProcessing: true });

		// validate form
		const $form = $('#location_form');
		if (!$form.valid()) {
			updateModalState(isSheet, { isProcessing: false });
			return;
		}

		formObj = {};
		// build form param object
		$.each($form.serializeArray(), function(_, keyVal) {
			if (formObj.hasOwnProperty(keyVal.name)) {
				formObj[keyVal.name] = $.makeArray(formObj[keyVal.name]);
				formObj[keyVal.name].push(keyVal.value);
			} else {
				formObj[keyVal.name] = keyVal.value;
			}
		});

		// address validation hook
		if (isEnabled('ADDRESS_VERIFICATION_FOR_WORK_LOCATIONS')) {
			const originalAddress = {
				addressLine1: formObj['location[street1]'],
				addressLine2: formObj['location[street2]'],
				city: formObj['location[city]'],
				state: formObj['location[state]'],
				zipCode: formObj['location[zipcode]'],
				country: formObj['location[country]'],
			}
			validateAddress(originalAddress, submit, () => {
				updateModalState(isSheet, { isProcessing: false });
			});
		} else {
			submit();
		}

	};

	const submit = (validatedAddress = null) => {
		const formData = {
			listValueId: (isEditing && listValueId) || '',
			changeHistory: 'history',
			label: formObj.label,
			location: {
				remoteLocation: formObj['location[remoteLocation]'] || '',
				street1: validatedAddress?.addressLine1 || formObj['location[street1]'],
				street2: validatedAddress?.addressLine2 || formObj['location[street2]'],
				city: validatedAddress?.city || formObj['location[city]'],
				state: validatedAddress?.stateObject?.id || formObj['location[state]'],
				zipcode: validatedAddress?.zipCode || formObj['location[zipcode]'],
				country: validatedAddress?.countryObject?.id || formObj['location[country]'],
				timezone: formObj['location[timezone]'],
			},
		};

		// determine whether to update or create the location.
		const submitAction = isEditing ? updateLocation : createLocation;

		// create or update
		submitAction(formData).then((response) => {
			if (typeof saveCallback === 'function') {
				updateModalState(isSheet, { isOpen: false });
				saveCallback(response.data);
			}
			window.closeMessage();
		}).catch((error) => {
			if (
				error.response.data.includes('existing location') ||
				error.response.data.includes('already exists')
			) {
				window.setMessage(messages.locationInUseError, 'error');
			} else {
				window.setMessage(messages.locationGeneralError, 'error');
			}
		}).finally(() => {
			updateModalState(isSheet, { isProcessing: false });
		})
	}

	Ajax.get('/ajax/dialog/location.php', data)
		.then((res) => {
			if (typeof res.data === 'object') {
				MODAL.setState({
					...(isSheet ? {
						sheetProps: {
							isOpen: false,
						},
					} : {
						isOpen: false,
					}),
				}, isSheet);
				window.setMessage(res.data.error || window.DEFAULT_ERROR_MESSAGE, 'error');
				return;
			}

			const modalProps = {
				biId: `employee-field-${ isEditing ? $.__('save') : $.__('add') }-location`,
				dangerousContent: res.data,
				isOpen: true,
				isProcessing: false,
				onClose,
				onOpen,
				primaryAction: onSubmitForm,
				primaryActionText: isEditing ? $.__('Save') : $.__('Add'),
				title: isEditing ? $.__('Edit Location') : $.__('Add a New Location'),
			};

			if (isSheet) {
				MODAL.setState({
					sheetProps: {
						...modalProps,
						type: 'mediumSheet',
					},
				}, true);
			} else {
				MODAL.setState({
					...modalProps,
				});
			}
		});
}

function createCheckboxTooltip() {
	const $fieldRow = $('.js-remote-checkbox');
	const $checkbox = $fieldRow.find('input#remoteLocation');
	if ($checkbox.is(':disabled')) {
		window.BambooHR.Popover.create($fieldRow[0], {
			closeX: false,
			html: `<div style="max-width: 320px; padding: 8px 12px; background-color: #646464; font-size: 12px; color: #ffffff; text-align: left; line-height: 1.2">${
				$.__('This location is used by at least one job opening, and job openings require an address.') } ${
				$.__('You cannot change this location to remote until you adjust the location on the job opening(s).')
			}</div>`,
			push: 12,
			tailBackground: '#646464',
			tailOnAnchor: 8,
			tailOnBalloon: 20,
			triggerEvent: 'hover',
		});
	}
}

export default {
	showModal,
};
