import { Modal } from 'modal-legacy';
import { showModal, closeModal } from 'show-modal.mod';

const CreateModal = (props) => {
	const { filename } = props;

	return (
		<Modal
			alternativeActionText=""
			content={$.__(
				`We can't create a secure link for it. Upload a different version of the file and try again.`,
			)}
			headline={$.__(`%1 doesn't contain any data`, filename)}
			icon="triangle-exclamation-regular"
			iconV2Color="warning-strong"
			isOpen={true}
			onClose={() => closeModal()}
			primaryAction={() => closeModal()}
			primaryActionText={$.__('Done')}
			title={$.__('Empty File')}
		/>
	);
};

export const create = (props) => showModal(<CreateModal {...props} />);
