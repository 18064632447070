import { FunctionComponent } from 'react';
import { IconButton } from '@fabric/button';

type Props = DynamicForm.FieldGroupElementProps & {
    handleRemove: (groupId: string) => void;
}

const DependentGroup: FunctionComponent<React.PropsWithChildren<Props>> = ({ props, settings, handleRemove, children }) => {
    const { canDelete } = settings;
    const { disabled, id } = props;

    // user can delete if BE permission, or if it was dynamically added by the FE.
    const canRemove: boolean = canDelete || id.includes('add');

    return (
        <div { ...props }>
            { canRemove && (
                <IconButton
                    className="fab-FloatingIconButton fab-FloatingIconButton--secondary formDelete withIconLabel"
                    disabled={ disabled }
                    floatingIcon={true}
                    icon='trash-can-regular'
                    onClick={ (): void => { handleRemove(id); } }
                    type="button"
                />
            )}
            <div className="fieldGroup divided">
                { children }
            </div>
        </div>
    );
};

export default DependentGroup;
