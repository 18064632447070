import { pick } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { getValidationContext } from '../context';
import { checkDateValidity } from '../form-fields/types/date';

/**
 * FooterActions
 * @param string 		formId
 * @param function		primaryAction
 * @param function		cancelAction
 *
 */
const FooterActions = (props) => {
	/**
	 * The lifetime of a file temp upload is 12 hours.
	 * If a candidate uploads a file and waits too long before submitting the uploaded file(s) will expire.
	 * This will prevent them from moving forward with an expired file.
	 * 1000 * 60 * 60 * 10 = 10 hours in microseconds
	 */
	const TEN_HOURS = 36e6;
	const { actions = {}, fields, formId } = props;
	const { cancelAction, primaryAction } = actions;

	const formElementRef = useRef(null);
	const validation = getValidationContext();
	const { reset, setSpecialErrors, specialErrors, validate } = validation;

	const PRIMARY_ACTION = useCallback(
		(e) => {
			if (window.GLOBAL_IS_ASSUMED_USER) {
				window.disabledForPermsTest();
				return;
			}
			let isFileUploadExpired = false;
			['resumeFileId', 'coverLetterFileId'].forEach((inputName) => {
				const key = `${inputName}Timestamp`;
				if (specialErrors[key]) {
					const timeDiff = Date.now() - specialErrors[key].timestamp;
					if(timeDiff > TEN_HOURS) {
						isFileUploadExpired = true;
					}
				}
			});
			const isFileUploadPending = errorObjectContainsRule(specialErrors, 'fileUploadPending');
			const isValidSpecial = validateSpecial(
				formElementRef.current,
				pick(fields, ['dateAvailable', 'resumeFileId', 'coverLetterFileId', 'veteranStatusId', 'customQuestions']),
				setSpecialErrors
			);

			if (isFileUploadPending || !isValidSpecial || isFileUploadExpired) {
				handleSpecialErrors(specialErrors, isFileUploadExpired);
			}

			const isValid = validate([], isValidSpecial);

			if (isFileUploadPending || isFileUploadExpired || !isValidSpecial || !isValid) {
				return;
			}

			if (typeof primaryAction === 'function') {
				e.stopPropagation();
				primaryAction();
				return;
			}

			if (formId) {
				document.dispatchEvent(new CustomEvent('SiteFooterActions:toggleProcessing'));
				formElementRef.current.submit();
				return;
			}

			console.warn(`No formId or Action provided, What were you expecting to happen?`);
		},
		[fields, formId, primaryAction, setSpecialErrors, specialErrors, validate]
	);

	const CANCEL_ACTION = useCallback(
		(e) => {
			if (typeof cancelAction === 'function') {
				e.stopPropagation();
				cancelAction(reset);
			}
		},
		[cancelAction, reset]
	);

	useEffect(() => {
		const FOOTER_ACTION_PRIMARY = document.querySelector('.SiteFooter__action--primary, .js-SiteFooter__action--primary');
		const FOOTER_ACTION_LINK = document.querySelector('.SiteFooter__action--link, .js-SiteFooter__action--link');

		FOOTER_ACTION_PRIMARY.addEventListener('click', PRIMARY_ACTION);
		FOOTER_ACTION_LINK.addEventListener('click', CANCEL_ACTION);

		return () => {
			FOOTER_ACTION_PRIMARY.removeEventListener('click', PRIMARY_ACTION);
			FOOTER_ACTION_LINK.removeEventListener('click', CANCEL_ACTION);
		};
	});

	useEffect(() => {
		formElementRef.current = document.getElementById(formId);
	}, [formId]);

	return null;
};

export default FooterActions;

function errorObjectContainsRule(errorObject, ruleName) {
	return Object.keys(errorObject).some((key) => errorObject[key].rule === ruleName);
}

export function handleSpecialErrors(specialErrors, isFileUploadExpired) {
	if (errorObjectContainsRule(specialErrors, 'fileUploadPending')) {
		window.setMessage($.__('Your file is still uploading. Please try again once the upload is complete.'), 'error');
	} else if (isFileUploadExpired) {
		window.setMessage($.__('Your file upload has expired.  Please remove your file and upload it again.'), 'error');
	} else {
		window.setMessage($.__('Whoops... No worries. Please fix any missing or incorrect information and try again.'), 'error');
		const [fieldName] = Object.keys(specialErrors);
		const element = document.querySelector(`[name="${fieldName}"]`);
		if (element) {
			element.focus();
		}
	}
}

export function validateSpecial(formElement, specialFields, setSpecialErrors) {
	const formData = new FormData(formElement);
	let isValid = true;

	if (specialFields.dateAvailable) {
		const dateAvailableValue = formElement.elements.dateAvailable.value;
		isValid = checkDateValidity('dateAvailable', specialFields.dateAvailable.isRequired, dateAvailableValue, setSpecialErrors);
	}

	['resumeFileId', 'coverLetterFileId'].forEach((inputName) => {
		if (!specialFields[inputName] || !formElement.elements[inputName]) {
			return;
		}

		if (formData.getAll(inputName).some(value => Boolean(value))) {
			setSpecialErrors(inputName, null);
		}
	});

	if (specialFields.customQuestions) {
		specialFields.customQuestions.forEach((question) => {
			if (
				question.type === 'multi' &&
				question.isRequired &&
				!formData.getAll(`customQuestions[${ question.id }]`).some(value => Boolean(value))
			) {

				setSpecialErrors(`customQuestions[${ question.id }]`, { rule: 'required' });
				isValid = false;
			}
			if (
				question.type === 'multi' &&
				formData.get(`customQuestions[${ question.id }]`) &&
				!formData.get(`customQuestions[${ question.id }][other]`) &&
				!formElement.elements[`customQuestions[${ question.id }][other]`].disabled
			) {
				setSpecialErrors(`customQuestions[${ question.id }][other]`, { rule: 'required' });
				isValid = false;
			}
		});
	}

	if (specialFields.veteranStatusId && specialFields.veteranStatusId.isRequired && document.querySelector('.CandidateField--veteranStatusesShown')) {
		if (!formData.getAll('veteranStatusId[]').some(value => Boolean(value))) {
			setSpecialErrors('veteranStatusId', { rule: 'required' });
			isValid = false;
		}
	}

	return isValid;
}
