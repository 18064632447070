import React, {Component, Fragment} from 'react';
import {consume} from '../../../store/context';
import {
	Avatar,
	BodyText,
	Divider,
	Flex,
	Headline,
	InlineMessage,
	InlineMessageStatusType,
	LayoutBox,
	Tooltip,
	TextButton,
} from '@bamboohr/fabric';
import {formatTime} from 'time-tracking/utils';
import {sumAllEarnableHoursInDays} from '../../../utils';
import { Button } from '@fabric/button';

import './styles.styl';
import SummaryBreakdown from '../summary-breakdown.react';

export class SummaryApproval extends Component {
	state = {
		approveTimesheetPending: false,
	};
	_approvalDisabledTooltip = null;

	_afterApproveTimesheet = () => this.setState({approveTimesheetPending: false});

	_approveTimesheet = () => {
		const {
			focusedTimesheet,
			workflow,
			approveTimesheet,
		} = this.props;

		this.setState({approveTimesheetPending: true});
		approveTimesheet(focusedTimesheet.id, workflow.hoursLastChangedAt)
			.then(this._afterApproveTimesheet)
			.catch(this._afterApproveTimesheet);
	};

	_renderStatus() {
		const {
			userCanApprove,
			status,
		} = this.props.workflow;

		if (status === 'approved') {
			return this._renderApproved();
		} else if (status === 'pending' && !userCanApprove) {
			return this._renderPendingWithoutPermission();
		} else if (status === 'pending' && userCanApprove) {
			return this._renderPendingWithPermission();
		}
	}

	_renderApproved() {
		const {approverName, approvalMoment} = this.props.workflow;
		return(
			<InlineMessage
				description={$.__('Approved by %1$s on %2$s.', approverName, approvalMoment.format('MMM D, YYYY'), {
					note: 'e.g. "Approved by Jane Smith on Sep 4, 2017"',
				})}
				icon='circle-check-solid'
				status={InlineMessageStatusType.success}
				title={
					<BodyText color='success-strong' weight='semibold'>
						{$.__('Approved')}
					</BodyText>
				}
			/>
		);
	}

	_renderPendingWithoutPermission() {
		const {approverName} = this.props.workflow;
		return(
			<InlineMessage
				description={approverName
					? $.__('Waiting for approval from %1$s', approverName, { note: 'e.g. "Waiting for approval from John Henry' })
					: $.__('Waiting for approval')}
				icon='hourglass-regular'
				title={$.__('Pending')}
			/>
		);
	}

	_renderPendingWithPermission() {
		const {
			workflow: {dueMoment},
			getChangedEntries,
		} = this.props;

		const disableApproval = Boolean(getChangedEntries().length);
		const button = (
			<Button color="primary" onClick={this._approveTimesheet} size="medium" startIcon="circle-check-regular" type="button">
				{$.__('Approve')}
			</Button>
		);

		return(
			<>
				<Flex alignSelf='stretch' flexDirection='column'>
					{disableApproval && !this._approvalDisabledTooltip ? (
						<Tooltip content={$.__('Make sure your changes are saved before you approve.')}>{button}</Tooltip>
					) : (
						button
					)}
				</Flex>
				<BodyText color='neutral-weak'>
					{$.__('Must approve by %1$s', dueMoment.format('MMM D'), { note: 'e.g. "Must approve by May 17"' })}
				</BodyText>
			</>
		);
	}

	render() {
		const {
			employeeId,
			employeeName,
			employeePhotoUrl,
			focusedTimesheet,
			payPeriodTitleText,
			payPeriodRangeText,
			getFocusedDays,
			summaryTotals,
			isViewOnly,
		} = this.props;

		const {
			periodOvertime,
			periodTimeOff,
			periodHoliday,
			shiftDifferentials,
			holidaySummary,
			hourSummary,
		} = summaryTotals;

		const focusedDays = getFocusedDays();

		return(
			<Flex alignItems='center' flexDirection='column' gap={1.5} >
				<LayoutBox>
					<Avatar alt={$.__('employee avatar')} src={employeePhotoUrl} />
				</LayoutBox>
				<BodyText size='large' weight='bold'>
					{isViewOnly ? employeeName : payPeriodTitleText}
				</BodyText>
				<Headline color='primary' size='medium'>
					{formatTime(sumAllEarnableHoursInDays(focusedDays))}
				</Headline>
				<BodyText color='neutral-strong' size='small'>
					{payPeriodRangeText}
				</BodyText>
				{!!periodOvertime.length ||
				!!periodTimeOff ||
				!!periodHoliday ||
				!!shiftDifferentials.length ||
				!!holidaySummary.length ||
				!!hourSummary.length ? (
					<>
						<LayoutBox width='100%'>
							<Divider color='neutral-extra-weak' marginTop={0.5}/>
						</LayoutBox>
						<SummaryBreakdown {...{ periodOvertime, periodTimeOff, periodHoliday, shiftDifferentials, holidaySummary, hourSummary }} />
					</>
				) : null}
				{!isViewOnly && this._renderStatus()}
				{isViewOnly && (
					<TextButton
						component="a"
						endIcon="chevrons-right-solid"
						href={`/employees/timesheet?id=${employeeId}&et_id=${focusedTimesheet.id}`}
						text={$.__('Go to timesheet')} />
				)}
			</Flex>
		);
	}
}


export default consume([
	// Properties
	'focusedTimesheet',
	'workflow',
	'summaryTotals',
	'isViewOnly',
	// Actions
	'approveTimesheet',
	// Queries
	'getFocusedDays',
	'getChangedEntries',
], SummaryApproval);
