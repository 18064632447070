import { FunctionComponent, useEffect } from 'react';
import classNames from 'classnames';
import { isRequired } from 'dynamic-form';

const CheckboxList: FunctionComponent<React.PropsWithChildren<DynamicForm.CheckboxListElementProps>> = (checkboxListProps) => {
	const {
		settings,
		context,
		children,
		props,
	} = checkboxListProps;
	const { id, name } = props;
	const { label, fieldBoxClasses } = settings;
	const {
		controls: { Legend, FieldBox },
		validation: { register, setValue },
	} = context;
	const validationName = `checkbox_${ name }`;

	useEffect(() => {
		setValue(validationName, '');
	}, []);

	const isFieldRequired = isRequired(context, checkboxListProps);
	// @ts-ignore
	const atLeastOneSelected = children.findIndex((child) => { return child?.props?.props?.checked === true; }) === -1;
	register(validationName, { required: isFieldRequired && atLeastOneSelected });

	const legendClasses = classNames('fab-CheckboxGroup__legend', {
		'fab-CheckboxGroup__legend--required': isFieldRequired,
		'fab-CheckboxGroup__legend--error': isFieldRequired && atLeastOneSelected,
	});

	const legendProps = {
		className: legendClasses,
		id: `${ id }-checkbox-legend`,
	};

	return (
		<FieldBox fieldBoxClasses={ fieldBoxClasses } id={ id }>
			<fieldset className="fab-CheckboxGroup">
				{ label && <Legend context={ context } props={ legendProps } settings={ { label } } /> }
				{ children }
			</fieldset>
		</FieldBox>
	);
};

export default CheckboxList;
