import { FunctionComponent, useEffect } from 'react';
import classNames from 'classnames';
import { getInputMask } from './text-mask';
import {
	getClassBasedValidation,
	hasErrors,
	isRequired,
	handleControlledInput,
	fixValidationRegisterName,
} from 'dynamic-form';

import './text.styl';

export type Props = DynamicForm.TextElementProps & {
	input?: React.ReactElement;
};

const TextField: FunctionComponent<React.PropsWithChildren<Props>> = (textFieldProps) => {
	const { props, input, settings, context } = textFieldProps;
	const { className = '', disabled, id, name } = props;

	const {
		icon,
		label = '',
		isObfuscated,
		condition,
		fieldBoxClasses,
		pending,
		type,
		note = false,
	} = settings;

	const {
		controls: {
			TextMaskedField,
			TextIconField,
			TextHoverField,
			FieldBox,
			FieldDiv,
			Label,
			Note,
			PendingRequest,
		},
		validation: {
			register,
			formState: { errors },
			setValue,
		},
		form: {
			isEditable,
		},
	} = context;
	const validationName = fixValidationRegisterName(name);
	const hasError = hasErrors(errors, validationName);
	const isFieldRequired = isRequired(context, textFieldProps);

	useEffect(() => {
		// init value on load so the pattern/normal validation doesn't error if field isn't edited
		setValue(validationName, value);
	}, []);

	const handleOnChange = (e): void => {
		handleControlledInput('text', id, e.target.value, context);
		setValue(validationName, e.target.value, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	// switching to uncontrolled input
	const { value, ...propsWithoutValue } = props;
	const inputFieldProps: React.InputHTMLAttributes<HTMLInputElement> = {
		type: 'text',
		...propsWithoutValue,
		defaultValue: value,
		className: classNames(className, {
			disabled,
			'fab-TextInput--error': hasError,
		}),
	};

	const createInput = (): React.ReactElement => {
		const hasInputMask = !!getInputMask(className, isEditable);

		if (hasInputMask && !pending) {
			return (
				<TextMaskedField
					context={ context }
					props={ inputFieldProps }
					settings={ settings }
				/>
			);
		}

		register(validationName, { ...getClassBasedValidation(context, textFieldProps), required: isFieldRequired });
		return (
			<div className="fab-InputWrapper">
				<PendingRequest context={ context } props={ props } settings={ settings } />
				<input onBlur={ handleOnChange } { ...inputFieldProps } />
				{
					icon && (
						<TextIconField
							props={ props }
							settings={ settings }
						/>
					)
				}
			</div>
		);
	};

	// use custom input passed in (used for calendar and other types) then either a masked or normal input
	const inputElement = input || createInput();

	const getInput = (): React.ReactElement => {
		const inputComponent = inputElement

		const HoverComponent = (
			<TextHoverField
				context={ context }
				input={ inputComponent }
				props={ props }
				settings={ settings }
			/>
		);

		return HoverComponent;
	};

	return (
		<FieldBox fieldBoxClasses={ fieldBoxClasses } isRequired={ isFieldRequired }>
			<Label
				error={ hasError }
				info={ condition === 'info' }
				inputId={ id }
				label={ label }
				required={ settings?.validation?.requiredWhenVisible === true }
			/>
			<FieldDiv isObfuscated={ isObfuscated } type={ type }>
				{ getInput() }
			</FieldDiv>
			{ note && <Note { ...note } context={ context } /> }
		</FieldBox>
	);
};

export default TextField;
