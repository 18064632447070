import Ajax from '@utils/ajax';
import { FunctionComponent, useState, useEffect } from 'react';
import {
	isNewEmployeePage
} from 'dynamic-form';
import { isEnabled } from 'FeatureToggle.util';
import SensitiveTextFieldLegacy from './text-sensitive-legacy';

const SensitiveTextField: FunctionComponent<React.PropsWithChildren<DynamicForm.SensitiveTextElementProps>> = ({
	props,
	settings,
	context,
}) => {

	const {
		id
	} = props;
	const {
		form: { isEditable = true },
		controls: { TextField },
	} = context;
	const [showSensitiveValue, setShowSensitiveValue] = useState(isEditable);
	const [sensitiveValue, setSensitiveValue] = useState(settings.sensitive.value);

	const getValue = (): void => {
		if (isNewEmployeePage()) {
			return;
		}

		Ajax.get(`/employees/get_secure_field/${props['data-secure-field-type']}`, { id: props['data-employeeid'] as string })
			.then((response) => {
				if (response.data) {
					setSensitiveValue(response.data);
					setShowSensitiveValue(true);
				}
			})
			.catch(() => {
				window.setMessage($.__('There was a problem getting the sensitive data'), 'error');
			});
	};

	const showValue = (): void => {
		if (sensitiveValue === settings.sensitive.value) {
			getValue();
		} else {
			setShowSensitiveValue(true);
		}
	};

	const hideValue = (): void => {
		setShowSensitiveValue(false);
	};

	useEffect(() => {
		if (isEditable) {
			showValue();
		} else {
			hideValue();
		}
	}, [isEditable]);

	const hover: DynamicForm.HoverWithCallback = {
		text: showSensitiveValue ? $.__('Hide') : $.__('Show'),
		template: 'action',
		className: '',
		handleHoverAction: showSensitiveValue ? hideValue : showValue,
	};

	const inputProps = {
		...props,
		value: showSensitiveValue ? sensitiveValue : settings.sensitive.value,
	};

	const inputSettings = {
		...settings,
		...( !!sensitiveValue && { hover } ),
	};

	// key must change otherwise the defaultValue is not updated for the uncontrolled input.
	return (
		<TextField
			context={ context }
			key={ `${ id }${ showSensitiveValue ? 'sensitive' : '' }` }
			props={ inputProps }
			settings={ inputSettings }
		/>
	);
};

export default SensitiveTextField;
