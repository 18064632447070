import { Flex, IconV2, LayoutBox, StyledBox } from '@bamboohr/fabric';
import { FunctionComponent } from 'react';
import classnames from 'classnames';

const iconMap = {
	// In Jade, we also had these types defined in the iconMap object:
	// 'benefits', 'payrollCheckbox', 'payrollInformation',
	// 'stateUnemploymentInsurance', 'taxWithholding', 'vaccinationStatus'.
    address: <IconV2 color='primary-strong' name='house-solid' size={16} />,
    compensation: <IconV2 color='primary-strong' name='money-check-solid' size={16} />,
    contact: <IconV2 color='primary-strong' name='address-book-solid' size={16} />,
    default: <IconV2 color='primary-strong' name='id-card-solid' size={16} />,
    dependents: <IconV2 color='primary-strong' name='users-solid' size={16} />,
    education: <IconV2 color='primary-strong' name='book-solid' size={16} />,
    directDeposit: <IconV2 color='primary-strong' name='building-columns-solid' size={16} />,
    emergency:<IconV2 color='primary-strong' name='truck-medical-solid' size={20} />,
    emergencyContacts:<IconV2 color='primary-strong' name='truck-medical-solid' size={20} />,
    employmentStatus: <IconV2 color='primary-strong' name='id-card-solid' size={16} />,
    job: <IconV2 color='primary-strong' name='briefcase-solid' size={20} />,
    jobInformation: <IconV2 color='primary-strong' name='briefcase-solid' size={16} />,
    personal: <IconV2 color='primary-strong' name='id-card-solid' size={16} />,
    selfService: <IconV2 color='primary-strong' name='user-lock-solid' size={24} />,
    socialLinks: <IconV2 color='primary-strong' name='comments-solid' size={16} />,
    projectPayRates: <IconV2 color='primary-strong' name='stopwatch-solid' size={16} />,
};

import './legend.styl';

const Legend: FunctionComponent<React.PropsWithChildren<DynamicForm.LegendElementProps>> = ({ settings, props }) => {
	const { label, icon, fabIcon } = settings;
	const { className = '' } = props;
	let legendLabel = label;

	if (icon === 'emergency' || icon === 'emergencyContacts') {
		 legendLabel = $.__("Emergency Contact");
	}

	if (!label) {
		console.error('Label is required for Legend component');
		return null;
	}

	// fabIcon takes precedence, then mapped icon, default: false
	const mapIcon = typeof icon === 'undefined' ? false : iconMap[icon] || iconMap.default;

	const classes = classnames(
		{
			DynamicForm__Legend: true,
			'fab-FormSection__legend': !className.includes('fab-CheckboxGroup__legend'),
		},
		className
	);

	const biId = `quickform-form-section-for-${label?.toLowerCase().replace(' ', '-')}-legend`;

	return (
        <legend {...props} className={classes} data-bi-id={biId}>
            {!!mapIcon &&
                (icon === 'selfService' ? (
                    <LayoutBox marginRight={'12px'}>
                        <Flex alignItems={'center'}>{mapIcon}</Flex>
                    </LayoutBox>
                ) : (
                    <StyledBox backgroundColor='neutral-medium' borderRadius='medium' marginRight={2} padding={1.5}>
                        <Flex alignItems={'center'}>{mapIcon}</Flex>
                    </StyledBox>
                ))}
            <div className='DynamicForm__LegendText'>{legendLabel}</div>
        </legend>
    );
};

export default Legend;
