import { FunctionComponent } from 'react';

const InformationField: FunctionComponent<React.PropsWithChildren<DynamicForm.InformationProps>> = ({
	settings,
	context,
}) => {
	const {
		controls: { FieldDiv },
	} = context;
	const { note } = settings;
	return <FieldDiv>{ note }</FieldDiv>;
};

export default InformationField;
