import { useEffect, useState } from 'react';
import {
	InPageMessaging,
	Dropdown,
	BodyText,
	List,
	LayoutBox,
} from '@bamboohr/fabric';
import { SadToGoBoxProps, ProductSelectionNamesMap } from './types';
import {
	CancellationModal,
	CancellationType,
	StopCancellationModal,
} from 'in-app-cancellation.mod';
import { CancelProductSelectionModal } from './components/CancelProductSelectionModal';
import { DateTime } from 'luxon';
import { Message } from '@bamboohr/utils/lib/message';

export const SadToGoBox = ({
	cancellationData,
	updateCancellationData,
	isAddon,
	render,
}: SadToGoBoxProps) => {
	const [openSelectionModal, setOpenSelectionModal] = useState(false);
	const [openCancellationModal, setOpenCancellationModal] = useState(false);
	const [openStopCancellationModal, setOpenStopCancellationModal] =
		useState(false);
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [productsBeingCancelled, setProductsBeingCancelled] = useState(null);
	const [productsAvailableToCancel, setProductsAvailableToCancel] =
		useState(null);
	const [hasMultipleProducts, setHasMultipleProducts] = useState(false);

	const handleCloseSelectionModal = () => {
		setSelectedProducts([]);
		setOpenSelectionModal(false);
	};

	const convertDate = (date) => {
		return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
	};

	const convertPreviousDate = (date) => {
		return DateTime.fromISO(date)
			.minus({ days: 1 })
			.toLocaleString(DateTime.DATE_MED);
	};

	const handleOnSelect = (val) => {
		if (val === 'moreDetail') {
			window.location.href = '/app/settings/cancellation';
		} else if (val === 'stopCancellation') {
			setOpenStopCancellationModal(true);
		} else if (val === 'cancelAdditional') {
			if (hasMultipleProducts) {
				setOpenSelectionModal(true);
			} else {
				setOpenCancellationModal(true);
			}
		}
	};

	const customAction = () => {
		if (!productsBeingCancelled || !productsAvailableToCancel) {
			return;
		}

		const dropdownItems = [
			{
				text: 'More Detail',
				value: 'moreDetail',
			},
			{
				text: 'Stop Cancellation Request',
				value: 'stopCancellation',
			},
		];

		if (
			!productsBeingCancelled.find(
				(item) => item.feature === CancellationType.ACCOUNT,
			) &&
			productsAvailableToCancel.length > 0 &&
			!isAddon
		) {
			dropdownItems.push({
				text: 'Cancel Additional Products',
				value: 'cancelAdditional',
			});
		}

		return (
			<Dropdown
				ButtonProps={{
					size: 'teenie',
					variant: 'outlined',
				}}
				items={dropdownItems}
				onSelect={handleOnSelect}
			>
				{$.__('Options')}
			</Dropdown>
		);
	};

	const formatMessages = (sortedArray, convertingDate) => {
		let message = $.__('');
		sortedArray.forEach((element, index) => {
			if (sortedArray.length > 2 && index === sortedArray.length - 1) {
				message += 'and ';
			}
			if (convertingDate) {
				message += convertDate(element);
			} else {
				message += element;
			}
			if (sortedArray.length === 2 && index === 0) {
				message += ', and ';
			} else if (sortedArray.length > 2 && index !== sortedArray.length - 1) {
				message += ', ';
			}
		});

		return message;
	};

	const message = () => {
		if (!productsBeingCancelled) {
			return;
		}

		const cancellationRequestData = [];
		const cancellationRequestUsers = Array.from(
			new Set(
				productsBeingCancelled.map(
					(item) => item.user_who_requested_cancellation,
				),
			),
		);

		cancellationRequestUsers.forEach((user) => {
			const userCancelData = productsBeingCancelled.filter(
				(products) => products.user_who_requested_cancellation === user,
			);
			const userCancelDates = Array.from(
				new Set(userCancelData.map((item) => item.date_requested)),
			);
			const sortedUserCancelDates = userCancelDates.sort(
				(objA: any, objB: any) =>
					Number(new Date(objA)) - Number(new Date(objB)),
			);

			cancellationRequestData.push({
				user: user,
				datesRequested: sortedUserCancelDates,
			});
		});

		const payrollItem = productsBeingCancelled.filter(
			(item) => item.feature === CancellationType.PAYROLL,
		);

		const addonItemList = [];

		if (productsBeingCancelled.length > 0) {
			const addonCancellationDates = Array.from(
				new Set(productsBeingCancelled.map((item) => item.cancellation_date)),
			);
			addonCancellationDates.forEach((item) => {
				const addonNames = productsBeingCancelled.filter(
					(addon) => addon.cancellation_date === item,
				);
				const sortedNames = Array.from(
					new Set(
						addonNames.map((addon) => ProductSelectionNamesMap[addon.feature]),
					),
				);
				addonItemList.push({
					names: sortedNames,
					date: item,
				});
			});
		}

		return (
			<>
				<LayoutBox marginBottom={1.5} marginTop={0.5}>
					<BodyText>
						{$.__('BambooHR Product cancellation was requested on ')}
						{cancellationRequestData.map((item, index) => {
							const dates = formatMessages(item.datesRequested, true);
							if (index === cancellationRequestData.length - 1) {
								return (
									<Message
										key={item}
										params={[dates, item.user]}
										text={$.__('{1}, by **{2}**')}
									/>
								);
							} else {
								return (
									<Message
										key={item}
										params={[dates, item.user]}
										text={$.__('{1}, by **{2}** and on ')}
									/>
								);
							}
						})}
					</BodyText>
				</LayoutBox>
				<LayoutBox marginLeft={0.5}>
					{(productsBeingCancelled.length > 0 || payrollItem.length > 0) && (
						<List>
							{productsBeingCancelled.length > 0 &&
								addonItemList.map((item) => {
									const addonNames = formatMessages(item.names, false);
									return (
										<List.Item key={addonNames}>
											<Message
												params={[addonNames, convertPreviousDate(item.date)]}
												text={$.__(
													'Access to {1} will end on **{2}** at **11:59PM Mountain Time**',
												)}
											/>
										</List.Item>
									);
								})}
							{payrollItem.length > 0 &&
								payrollItem[0].cancellation_details != undefined && (
									<List.Item>
										<Message
											params={[
												convertDate(
													payrollItem[0].cancellation_details.final_pay_date,
												),
											]}
											text={$.__('Final Payroll will run on **{1}**')}
										/>
									</List.Item>
								)}
						</List>
					)}
				</LayoutBox>
			</>
		);
	};

	useEffect(() => {
		if (!cancellationData || !cancellationData.filter) {
			return;
		}

		setProductsBeingCancelled(
			cancellationData.filter((item) => item.pending_cancellation !== false),
		);
		setProductsAvailableToCancel(
			cancellationData.filter((item) => item.pending_cancellation !== true),
		);
		setHasMultipleProducts(cancellationData.length > 1);
	}, [cancellationData]);

	const messageProps = {
		body: message(),
		header: $.__('We’re Sad to See You Go'),
		icon: 'face-frown-solid',
		customAction: customAction(),
		info: true,
	};

	const modals = (
		<>
			<CancelProductSelectionModal
				cancellationStatuses={cancellationData}
				isOpen={openSelectionModal}
				onClose={handleCloseSelectionModal}
				onNext={(products) => {
					setSelectedProducts(products);
					setOpenCancellationModal(true);
				}}
			/>
			<CancellationModal
				isOpen={openCancellationModal}
				onClose={() => setOpenCancellationModal(false)}
				onSubmitSuccess={() => {
					updateCancellationData();
					handleCloseSelectionModal();
				}}
				types={
					hasMultipleProducts ? selectedProducts : [CancellationType.ACCOUNT]
				}
			/>
			<StopCancellationModal
				isOpen={openStopCancellationModal}
				onClose={() => setOpenStopCancellationModal(false)}
				onSubmitSuccess={updateCancellationData}
				cancellationData={cancellationData}
			/>
		</>
	);

	return (
		<>
			{render ? (
				render(messageProps)
			) : (
				<LayoutBox marginBottom={2} marginTop={1}>
					<InPageMessaging {...messageProps} />
				</LayoutBox>
			)}
			{modals}
		</>
	);
};
