import {
	Avatar,
	BodyText,
	createStyles,
	Flex,
	Headline,
	IconV2,
	makeStyles,
	StyledBoxV2
} from '@bamboohr/fabric';
import { isEmpty } from 'lodash';
import { ReactElement } from 'react';

interface Props {
	closeModal: () => Promise<void>;
	headerAction: () => void;
	renderActionButtons: (actions: Array<() => any>) => ReactElement;
	email?: string;
	subtitle: string;
	name: string;
	imageUrl: string;
}
const styles = makeStyles(({ palette, typography }) => {
	return createStyles({
		subTitle: {
			flexGrow: 1,
			fontSize: typography.fabricFontSize('small'),
			display: 'flex',
			flexDirection: 'column',
			color: palette.grey[600],
			'& p': {
				position: 'relative',
				top: '5px',
			},
			'&__email': {},
		},
	});
});

/**
 * The NHP endpoint currently returns images/photo_person<dimensions> as an image URL, but other implementations for
 * employee data return initials and there are references to `initials` as empty state images in the NHP code. Unless
 * the backend is updated to return null when fetching NHP details, detecting one of the two fallback images can tell us
 * if we do not have a real employee photo.
 *
 * If either of these are returned, we can render our fallback icon.
 *
 * @see app/BambooHR/Silo/NewHirePacket/Shared/NewHirePacketInfoFieldTypeSet.php for backend fallback field
 */
const IMAGE_FALLBACK_URLS = ['images/photo_person', 'initials'];

export function PreviewerHeader(props: Props): ReactElement {
	const {
		closeModal,
		name = '',
		imageUrl = '',
		email = '',
		subtitle,
		headerAction,
		renderActionButtons = () => {
			return null;
		},
	} = props;

	// If no image url is passed, or if we have one of the backend-defined employee fallback images, replace with the
	// user-regular icon
	const renderFallbackImage =
		isEmpty(imageUrl) ||
		IMAGE_FALLBACK_URLS.some((urlFragment) => imageUrl.includes(urlFragment));

	const classes = styles();

	const headerClick = () => {
		headerAction();
		closeModal();
	};

	return (
		<>
			<div className="PreviewerHeader">
					<StyledBoxV2
						backgroundColor="neutral-extra-extra-strong"
						padding="26px 50px"
					>
						<Flex alignItems="center" display="flex">
							{renderFallbackImage ? (
								<IconV2
									color="neutral-forced-white"
									name="user-regular"
									size={60}
								/>
							) : (
								<Avatar
									alt={$.__('Image of employee %1', name)}
									size={64}
									src={imageUrl}
								/>
							)}
							<Flex
								alignItems="stretch"
								display="flex"
								flexDirection="column"
								height="100%"
								justifyContent="center"
								paddingLeft="15px"
								width="auto"
							>
								<BodyText color="neutral-inverted" size="small">
									{subtitle}
								</BodyText>
								<Headline color="neutral-inverted" size="large">
									{name}
								</Headline>
								{email && (
									<Flex flexDirection="column" flexGrow={1}>
										<div className={`${classes.subTitle}__email`}>
											<BodyText color="neutral-inverted" size="small">
												{email}
											</BodyText>
										</div>
									</Flex>
								)}
							</Flex>
							<Flex
								alignItems="center"
								display="flex"
								height="100%"
								justifyContent="center"
								marginLeft="auto"
								width="auto"
							>
								{renderActionButtons([closeModal, headerClick])}
							</Flex>
						</Flex>
					</StyledBoxV2>
				</div>
		</>
	);
}
