import {
	showSlidedown,
} from 'Alerts.mod';
import {
	downloadFile,
} from 'file.util';
import {
	doGetNewBackupCodes,
} from '../../service';
import {
	ERROR_MESSAGE,
} from './constants';
/**
 * Accept an array of BackupCodes and use them to generate a string of backup codes with newlines added.
 * @param {*} codes
 * @returns
 */
const generateFileData = (codes) => {
	const fileData = codes.reduce((acc, codeData) => {
		const {
			backupCode,
			dateUsed,
		} = codeData;

		let text = backupCode;
		if (dateUsed) {
			text = text.concat($.__(' (used)'));
		}
		return acc.concat(text, '\n');
	}, '');
	return fileData;
}

/**
 * Download backup codes. An array of Backup Codes can be passed in, otherwise the codes will be fetched from the server.
 * @param {*} id
 * @param {*} codes
 */
export const downloadCodes = async (id = window.SESSION_USER?.id, codes = undefined ) => {
	try {
		// use the passed in codes if they exist
		if (codes) {
			downloadFile({
				fileName: 'backup-codes.txt',
				fileData: generateFileData(codes)
			});
			return;
		}
		// otherwise get new backup codes from the server
		const resp = await doGetNewBackupCodes(id);
		const fileData = generateFileData(resp.data);
		downloadFile({ fileName: 'backup-codes.txt', fileData });
	} catch (error) {
		showSlidedown(ERROR_MESSAGE, 'error');
	}
}
