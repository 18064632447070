import moment from 'moment.lib';
import {
	camelCase,
	defaults,
	each,
	includes,
	isElement,
	isEqual,
	isFunction,
	isPlainObject,
	isString,
	keys,
	last,
	pick,
	toArray as _toArray,
	upperFirst,
	values,
} from 'lodash';
import { Decimal } from 'decimal.js-light';
import leven from 'leven';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import {
	htmlentities,
	htmldecode,
} from 'String.util';
export { htmlentities, htmldecode };

import { canUseLocalStorage } from '@utils/localstorage';
export { canUseLocalStorage };
import * as Dom from '@utils/dom';
export { Dom };
export {
	getMaxZIndex,
} from '@utils/dom';

import { queryStringToObject } from '@utils/url';
export {
	extendURL,
	getParameterByName,
	queryStringToObject,
	toQueryString,
} from '@utils/url';
import colors from '@bamboohr/fabric/dist/definitions/json/colors.json';
import encoreColors from '@bamboohr/fabric/dist/definitions/json/encore-colors.json';
import themes from '@bamboohr/fabric/dist/definitions/json/theme-colors.json';

import * as History from '@utils/url/history';
export { History };

import {
	validateEmail,
} from '@utils/validation/rules';

export { validateEmail };

/**
 * Makes the targetInput field formatted as a $ or a % string based on the toggle value
 * For USD currencies only.
 *
 * Each radio <input> much include:
 * onchange="BambooHR.Utils.formatFixedPercentToggle(this, '.js-class')"
 * data-currency-toggle="$" or data-currency-toggle="%"
 *
 * @param toggleEl Radio button
 * @param targetInput string Full class name of the currency input field
 * @param {number} decimalPlaces number which indicates how many decimals to round to, if not provided we will not modify the input
 * @param {boolean} fixNegativeVals optional boolean which determines whether we correct any negative values
 *
 * @return null
 */
export function formatCurrencyPercentToggle(toggleEl, targetInput, decimalPlaces, fixNegativeVals) {
	const toggleValue = $(toggleEl).data('currency-toggle');

	const $inputField = $(targetInput);
	const fieldValue = $inputField.val();

	const parsedValue = window.getFloatFromStringJS(fieldValue.replace('$', '').replace('%', ''));

	if (toggleValue === '$') {
		$inputField.addClass('currency-field');
		$inputField.removeClass('percent-field');
		let formattedValue = parsedValue;
		if (decimalPlaces) {
			if (typeof formattedValue === 'number' && typeof decimalPlaces === 'number') {
				formattedValue = Number(new Decimal(formattedValue).toFixed(decimalPlaces));
			}
		}
		if (fixNegativeVals) {
			formattedValue = Math.abs(formattedValue);
		}
		$inputField.val(formattedValue);
	} else if (toggleValue === '%') {
		$inputField.removeClass('currency-field');
		$inputField.addClass('percent-field');

		// Dont allow a percentage value go to beyond 100
		let formattedValue = parsedValue > 100 ? 100 : parsedValue;
		if (decimalPlaces) {
			if (typeof formattedValue === 'number' && typeof decimalPlaces === 'number') {
				formattedValue = Number(new Decimal(formattedValue).toFixed(decimalPlaces));
			}
		}
		if (fixNegativeVals) {
			formattedValue = Math.abs(formattedValue);
		}
		$inputField.val(formattedValue);
	} else {
		throw new Error(`Invalid Radio Toggle data-currency-toggle value -- must be $ or %, currently set to: ${ toggleValue }`);
	}

	$inputField.blur();
}

/**
* Check for any active/focused element in the document object
*
* @param {object} document the document object
*
* @return {boolean} true false if focused element
*/
export function hasFocusedElement(document) {
	if (!document.activeElement || document.activeElement === document.body) {
		return false;
	}
	return true;
}

/**
 * send trackEvent to Google Analytics
 *
 * @param string category
 * @param string action
 * @param string label
 * @param string value (optional)
 *
 */
export function eventTrack(category, action, label, value) {
	const _gaq = window._gaq || [];
	window._gaq = _gaq;

	_gaq.push(['_setAccount', 'UA-5806974-3']);
	_gaq.push(['_trackEvent', category, action, label, value]);

	if (window.BambooHR.env && window.BambooHR.env.prod) {
		(function() {
			const ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
			ga.src = `${ document.location.protocol === 'https:' ? 'https://ssl' : 'http://www' }.google-analytics.com/ga.js`;
			const s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
		})();
	}
}

/**
 * Convert jQuery selectors into actual wrappers
 *
 * e.g.
 * {
 *   $one: '.one',
 *   $two: '.two',
 * }
 * becomes:
 * {
 *   $one: $('.one'),
 *   $two: $('.two'),
 * }
 *
 * @param object selectors Object of named jQuery selector strings
 *
 * @return object jQuery-wrapped selectors
 */
export function setupWrappers(selectors) {
	const cache = {};
	const props = Object.keys(selectors)
		.reduce((wrappers, key) => {
			wrappers[key] = {
				get() {
					cache[key] = cache[key] || $(selectors[key]);
					return cache[key];
				},
			};

			return wrappers;
		}, {});

	return Object.defineProperties({}, props);
}

/**
 * Execute a function without any parameters. The "function" can be type function or a string and will be executed
 * properly. It can even be an anonymous function.
 *
 * @param {function|string} func The function as an actual function or a string
 *
 * @returns {mixed} The return value of the executed function
 */
export function executeFunction(func) {
	if (typeof func === 'function') {
		return func();
	}

	const funcReg = /function *\(([^()]*)\)[ \n\t]*{(.*)}/gmi;
	const match = funcReg.exec(func.replace(/\n/g, ' '));

	if (match) {
		// @ts-ignore
		const newFunc = new Function(match[1].split(','), match[2]);
		return newFunc();
	}

	return eval(func);
}

/**
 * Execute an array of functions
 *
 * @param {Function[]} queue - an array of functions
 * @param {*} [context] - the scope for each function call
 * @param {...*} [args] - the arguments to be passed to each function
 *
 * @returns {void}
 */
export function callFunctionQueue(queue, context, ...args) {
	queue.forEach(cb => cb.call((context || undefined), ...args));
}

/**
 * Capatalize the first letter of the entire string (same as PHP's ucfirst)
 *
 * @param {String} string The string to capitalize the first letter of
 *
 * @returns {String}
 */
export function ucfirst(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Does the element have the class?
 *
 * @param El     el  the element
 * @param string cls the class
 *
 * @return bool
 */
export function elementHasClass(el, cls) {
	// This will return a false positive if cls is an empty string or array
	return (` ${ el.className } `).includes(` ${ cls } `);
}

/**
 * Get position of an html element relative to the body
 *
 * @param {elem} reference   The reference element (accepts html and JQuery elements )
 * @param {string} [attribute] The single attribute to return
 *
 * @return {object|string} pos
 *
 * @TODO Allow detection of passed parameters, which will allow for "relativeTo" do not default to body
 */
export function getRelativePosition(reference, attribute, correctBodyPosition = false) {

	// Find that DOM element
	if (reference instanceof jQuery) {
		// @ts-ignore
		reference = reference.get(0);
	} else if (typeof reference === 'string') {
		reference = $(reference).get(0);
	}

	const htmlPos = document.getElementsByTagName('HTML')[0].getBoundingClientRect();
	let refPos;

	// Check to make sure only DOM or JQuery elements are being passed
	try {
		refPos = reference.getBoundingClientRect();
	} catch (err) {
		console.log('Class Name, DOM or JQuery element required for getRelativePosition(reference)');
		return false;
	}

	let top = Math.abs(htmlPos.top) + refPos.top;
	if (correctBodyPosition) {
		top -= parseInt(document.body.style.top || '0');
	}
	const left = Math.abs(htmlPos.left) + refPos.left;

	const position = {
		top,
		bottom: top + refPos.height,
		left,
		right: left + refPos.width,
		width: refPos.width,
		height: refPos.height,
	};

	// Make optional attributes available
	if (position.hasOwnProperty(attribute)) {
		return position[attribute];
	}
	return position;
}

/**
 * Checks to see if all values of an array are equal
 * To see if all values in an array equal something specific use the "optionalValue" parameter
 *
 * Examples:
 * var myArray = [2,2,2];
 * var otherArray = [9,9,7];
 *
 * allValuesEqual(myArray) // returns true
 * allValuesEqual(myArray, 3) // returns false
 *
 * allValuesEqual(otherArray) // returns false
 *
 * @param array
 * @param optionalValue
 * @returns {boolean}
 */
export function allValuesEqual(array, optionalValue) {
	const check = (optionalValue === undefined) ? array[0] : optionalValue;

	for (let i = 0; i < array.length; i++) {
		if (array[i] !== check) {
			return false;
		}
	}
	return true;
}

/**
 * Converts a string to PascalCase
 *
 * Examples:
 * 'just some-string' --> 'JustSomeString'
 *
 * @param {string} str
 * @returns {string}
 */
export function toPascalCase(str) {
	return upperFirst(camelCase(str));
}

/**
 * Converts a string to camelCase
 *
 * Examples:
 * 'just some-string' --> 'justSomeString'
 *
 * @param {string} str
 * @returns {string}
 */
export const toCamelCase = camelCase;

/**
 * Converts an array-like object into an array
 *
 * @param obj
 * @returns {Array}
 */

export const toArray = _toArray;

/**
 * Gets elements from the document matching
 * the given XPath
 *
 * @param path
 * @returns {Array}
 */
export function XPath(path) {
	let elements;
	if (typeof document.evaluate !== 'undefined') {
		const result = document.evaluate(path, document, null, XPathResult.ANY_TYPE, null);
		let item = result.iterateNext();
		elements = [];

		while (item) {
			elements.push(item);
			item = result.iterateNext();
		}
	}

	return elements || [];
}

/**
 * Prints a single div, or container, instead of the entire window.
 * Pass a selector, example: printDiv('#divToPrint');
 *
 * This function takes the innerHTML of the div that is selected. Make sure that any styles applied
 * to the content do not rely on the outer div or container as that will no longer exist.
 *
 * @param selector
 * @param useFlex - whether we should use display: flex; for the print div, default is display: block;
 */
export function printDiv(selector, useFlex: boolean) {
	const styleName = 'print-only-one-div';
	const source = document.querySelector(selector).innerHTML;
	const head = document.head || document.getElementsByTagName('head')[0];
	const style = document.createElement('style');
	style.type = 'text/css';

	const displayType = useFlex ? 'flex' : 'block';
	const print = document.createElement('div');
	const css = [
		`.${ styleName }{display: none;}`,
		'@media print {',
		`.${ styleName }{display: ${displayType};}`,
		`body >:not(.${ styleName }) {display: none !important;}}`,
	].join('');

	// @ts-ignore
	if (style.styleSheet) {
		// @ts-ignore
		style.styleSheet.cssText = css;
	} else {
		style.appendChild(document.createTextNode(css));
	}

	head.appendChild(style);

	print.innerHTML = source;
	print.className = styleName;
	document.body.appendChild(print);

	window.print();

	head.removeChild(style);
	document.body.removeChild(print);
}

/**
 * check if a string contains html
 *
 * @method hasHtml
 *
 * @param  {String}  str the string to check
 *
 * @return {Boolean}
 */
export function hasHtml(str) {
	if (
		str.charAt(0) === '<' &&
		str.charAt(str.length - 1) === '>' &&
		str.length >= 3
	) {
		return true;
	}

	const match = (/^(?:[^#<]*(<[\w\W]+>)[^>]*$|#([\w\-]*)$)/).exec(str);

	return !!(match && match[1]);
}

export function sanitizeHtml(html, opts?) {
	return Dom.sanitize(html, {
		...(isPlainObject(opts) ? opts : {}),
		USE_PROFILES: {
			html: true,
		},
	});
}

export function sanitizeSvg(svg, opts) {
	return Dom.sanitize(svg, {
		...(isPlainObject(opts) ? opts : {}),
		USE_PROFILES: {
			svg: true,
		},
	});
}

/**
 * Returns a file size in a human readable format
 * (i.e 2.5MB)
 *
 * credit: http://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable
 *
 * @param {Number} bytes
 *
 * @return {String}
 */
export function humanFileSize(bytes) {
	const thresh = 1024; // 1024

	if (Math.abs(bytes) < thresh) {
		return `${ bytes } B`;
	}

	const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	let u = -1;

	do {
		bytes /= thresh;
		++u;
	} while (Math.abs(bytes) >= thresh && u < units.length - 1);

	return bytes.toFixed(1) + units[u];
}

/**
 * Custom triggering of events
 *
 * Shout out:
 * http://jehiah.cz/a/firing-javascript-events-properly
 *
 */
export function triggerEvent(element, event) {
	let evt;
	// @ts-ignore
	if (document.createEventObject) {
		/* Dispatch for IE */
		// @ts-ignore
		evt = document.createEventObject();
		return element.fireEvent(`on${ event }`, evt);
	}

	/* Dispatch for firefox + others */
	evt = document.createEvent('HTMLEvents');
	evt.initEvent(event, true, true); // event type, bubbling, cancelable
	return !element.dispatchEvent(evt);
}

/**
 * Get extension via filename
 *
 * @param {String} fileName    The file name to parse through
 * @returns {String} extension The file extension
 */
export function getExtFromFileName(fileName) {
	return fileName.toLowerCase().split('.').pop();
}

/**
 * Get image type via fileName
 *
 * @param {String} fileName   The file name to parse through
 * @returns {String} fileType The type of file
 */
export function getFileTypeFromExt(fileName) {
	switch (getExtFromFileName(fileName)) {
		case 'pdf':
			return 'pdf';

		case 'docx':
		case 'doc':
		case 'docm':
		case 'pages':
		case 'odt':
			return 'doc';

		case 'xls':
		case 'xlsx':
		case 'csv':
		case 'xlsm':
		case 'ods':
			return 'xls';

		case 'ppt':
		case 'key':
		case 'kth':
			return 'ppt';

		case 'txt':
		case 'rtf':
			return 'txt';

		case 'msg':
		case 'eml':
			return 'email';

		case 'htm':
		case 'html':
		case 'url':
		case 'mht':
		case 'webarchive':
			return 'webpage';

		case 'zip':
		case 'rar':
			return 'archive';

		case 'jpg':
		case 'jpeg':
		case 'tif':
		case 'gif':
		case 'png':
		case 'ai':
		case 'eps':
		case 'tiff':
		case 'psd':
		case 'bmp':
			return 'img';

		default:
			return 'default';
	}
}

/**
 * Get the <ba-icon> name from the given file name
 *
 * @method getIconNameFromFileName
 * @param  {String}                fileName       the fileName
 * @param  {String}                [size='16x20'] the dimensions of the icon
 * @param  {Boolean}               [isEsig=false] whether the file is an esignature

 *
 * @return {String}                               the icon name for the type of file given
 */
export function getIconNameFromFileName(fileName, size='16x20', isEsig=false) {
	const ext = getFileTypeFromExt(fileName);
	let fileType = ext;

	if (isEsig) {
		return `fab-file-esig-${ size }`;
	}

	switch (ext) {
		case 'pdf':
			fileType = 'pdf';
			break;
		case 'doc':
			fileType = 'doc';
			break;
		case 'xls':
			fileType = 'excel';
			break;
		case 'ppt':
			fileType = 'powerpoint';
			break;
		case 'txt':
			fileType = 'text';
			break;
		case 'email':
			fileType = 'mail';
			break;
		case 'webpage':
			fileType = 'link';
			break;
		case 'archive':
			fileType = 'zip';
			break;
		case 'img':
			fileType = 'image';
			break;
		default:
			fileType = 'unknown';
			break;
	}
	return `fab-file-${ fileType }-${ size }`;
}

/**
 * Get image fileName via extension
 *
 * @param {String} fileName   The file name to parse through
 * @param {String} size       Either sm, or lrg
 * @returns {String} fileType The file name to reference
 */
export function getFileTypeIconFromExt(fileName, size) {
	let fileType = getFileTypeFromExt(fileName);

	if (size === 'sm' || size === 'lrg') {
		fileType += `-${ size }.png`;
	} else {
		fileType += '.png';
	}

	return fileType;
}

/**
 *
 * @param fileType type returned from `getFileTypeFromExt` including type 'esig'
 * @returns the hash color the icon should be
 */
export function getFileTypeIconColor(fileType: string) {
	switch (fileType) {
		case 'pdf':
			return '#C42428';
		case 'doc':
			return '#0772B3';
		case 'esig':
			return '#883290';
		case 'xls':
			return '#3FA146';
		case 'ppt':
			return '#C36A00';
		case 'txt':
		case 'email':
		case 'webpage':
		case 'archive':
		case 'img':
		default:
			return colors.colors.gray7;

	}
}

/**
 * Bolds termToHighlight where it is found inside of templateMarkup
 *
 * NOTE: Add the .js-qsPrimaryText class to elements whose text content can be bolded
 *
 * @param  {String} templateMarkup  [description]
 * @param  {String} termToHighlight [description]
 */
export function highlightTermInMarkup(templateMarkup, termToHighlight) {
	const $tempFragment = $('<div>').html(templateMarkup);
	const $primaryText = $tempFragment.find('.js-qsPrimaryText');

	const cleanTerm = termToHighlight.replace(/([\^\$\(\)\[\]\{\}\*\.\+\?\|\\])/gi, '\\$1');
	const markup = $primaryText.html() || '';
	const highlightedMarkup = markup.replace(new RegExp(`(?![^&;]+;)(?!<[^<>]*)(\\b${ cleanTerm })(?![^<>]*>)(?![^&;]+;)`, 'igm'), '<strong>$1</strong>');

	$primaryText.html(highlightedMarkup);
	return $tempFragment.html();
}

/**
 * Add a class to an element.
 *
 * @param element
 * @param className
 */
export function addClass(element, className) {
	if (!window.BambooHR.Utils.hasClass(element, className)) {
		if (window.BambooHR.Utils.isSvg(element)) {
			if (element.hasAttribute('class')) {
				element.setAttribute('class', (`${ element.getAttribute('class') } ${ className }`).trim());
			} else {
				element.setAttribute('class', className);
			}
		} else {
			element.className = (`${ element.className } ${ className }`).trim();
		}
	}
}

/**
 * Removes a class from an element.
 *
 * @param element
 * @param className
 */
export function removeClass(element, className) {
	if (window.BambooHR.Utils.hasClass(element, className)) {
		const reg = new RegExp(`(\\s|^)${ className }(\\s|$)`);

		if (window.BambooHR.Utils.isSvg(element)) {
			if (element.hasAttribute('class')) {
				element.setAttribute('class', element.getAttribute('class').replace(reg, ' ').trim());
			}
		} else {
			element.className = element.className.replace(reg, ' ').trim();
		}
	}
}

/**
 * Checks if an element has a className applied to it.
 * SVG's "class" is not a normal attribute, and has to be checked differently.
 *
 * @param element
 * @param className
 * @returns {boolean}
 */
export function hasClass(element, className) {
	if (window.BambooHR.Utils.isSvg(element)) {
		if (element.hasAttribute('class')) {
			return !!element.getAttribute('class').match(new RegExp(`(\\s|^)${ className }(\\s|$)`));
		}
		return false;
	}
	return !!element.className.match(new RegExp(`(\\s|^)${ className }(\\s|$)`));
}

/*
 * Check if account is able to email
 */
export function canEmailFilesAndReports() {
	if (window.CAN_EMAIL_FILES_AND_REPORTS !== 'undefined') {
		return window.CAN_EMAIL_FILES_AND_REPORTS;
	}

	console.warn('CAN_EMAIL_FILES_AND_REPORTS is not available.');
}

/**
 * Checks whether an element is an instance of an SVG element
 *
 * @param element
 * @returns {boolean}
 */
export function isSvg(element) {
	return !!(element instanceof SVGElement);
}

/**
 * Converts a number to the ordinal version as a string
 *
 * @param {Number} n
 * @returns {String}
 */
export function ordinal(n) {
	return moment.localeData().ordinal(n);
}

/**
 * gets the HTML script element which sourced the
 * currently running file
 *
 * @method currentScript
 *
 * @param {String}  className  a class name to look for (IE11 only)
 *
 * @return {jQuery}
 */
export function getCurrentScript(className) {
	let scriptEl = document.currentScript;

	if (
		!scriptEl &&
		isString(className) &&
		className.length > 0
	) {
		// @ts-ignore
		scriptEl = $(`script.${ className }:not(.${ className }--loaded)`)
			.first()
			.addClass(`.${ className }--loaded`);
	}

	if (!scriptEl) {
		const scripts = document.getElementsByTagName('script');
		scriptEl = scripts[scripts.length - 1];
	}

	return $(scriptEl);
}

/**
 * parse the content of the given $script and
 * return it as a plain object, after merging
 * with the optional defaultConfig argument.
 *
 * @method getScriptConfig
 *
 * @param  {jQueryElement}  $script the script tag
 * @param  {Object}         defaultConfig your default config
 * @param  {Object}         args a hash of named arguments to expose
 *
 * @return {Object}         the resulting object (merged)
 */
export function getScriptConfig($script, defaultConfig = {}, args = {}) {
	$script = $($script).first();

	const content = ($script.html() || '').trim() || '{}';
	let config = {};

	try {
		config = new Function(
			'defaultConfig',
			'$script',
			...keys(args),
			`return (${ content });`
		).call($script[0], defaultConfig, $script, ...values(args));
	} catch (e) {
		console.error(e);
	}

	return defaults(config || {}, defaultConfig || {});
}

/**
 * get json from a <script> element
 * @method getJsonScriptVar
 * @param  {String}         varName
 * @param  {String}         elemId
 * @return {Object|void}
 */
export function getJsonScriptVar(varName, elemId) {
	const $el = $(`script[type="application/json"]#${ elemId }__${ varName }_json`);
	const json = $el.text().trim();

	$el.remove();

	return json ? JSON.parse(json) : null;
}

/**
 * get attributes from an element, with an optional
 * regex match
 *
 * @param {HTMLElement} elem
 * @param {String} pattern
 * @return {Object}
 */
export function getAttrs(elem, pattern) {
	elem = $(elem)[0];

	const attrs = {};
	const attributes = elem ? elem.attributes : [];

	each(attributes, (attr) => {
		if (!pattern || attr.nodeName.match(new RegExp(pattern, 'i'))) {
			attrs[attr.nodeName] = attr.value;
		}
	});

	return attrs;
}

/**
 * checks if obj1 contains properties and values from obj2
 *
 * @param {Object} obj1 the object to check
 * @param {Object} obj2 the object to compare against
 */
export function objContains(obj1, obj2) {
	return isEqual(pick(obj1, keys(obj2)), obj2);
}

export function getFormData(form) {
	return queryStringToObject($(form).serialize());
}

/**
 * redirect the browser window, with an optional session message
 *
 * @method redirect
 *
 * @param  {String} url                     the URL to redirect to
 * @param  {String} [msg=null]              the optional session message
 * @param  {String} [msgType=null]          the optional message type
 * @param  {Number} [msgTimeout=null]       the optional session message timeout (in ms)
 * @param  {Boolean} [useLocalStorage=null] the optional set as localStorage as well as searchParam -> messages will work with redirects
 *
 * @return {void}
 */
export function redirect(url, msg = null, msgType = null, msgTimeout = null, useLocalStorage = null) {
	const redirectURL = new URL(url || '', window.location.href);
	const shouldUseLocalStorage = useLocalStorage && canUseLocalStorage();

	if (msg !== null) {
		redirectURL.searchParams.set('__message__', msg);
		if (shouldUseLocalStorage) { localStorage.setItem('__message__', msg); }
	}
	if (msgType !== null) {
		redirectURL.searchParams.set('__messageType__', msgType);
		if (shouldUseLocalStorage) { localStorage.setItem('__messageType__', msgType); }
	}
	if (msgTimeout !== null) {
		redirectURL.searchParams.set('__messageTimeout__', msgTimeout);
		if (shouldUseLocalStorage) { localStorage.setItem('__messageTimeout__', msgTimeout); }
	}

	// @ts-ignore
	window.location = redirectURL.href;
}

const uids = [];
/**
 * generate a random and unique string of a given length (defaults to 8 characters)
 *
 * @method makeUid
 *
 * @param  {Number} [length] the length of the resulting string
 * @param  {Number} radix the numeral system to use for the string
 *
 * @return {String}            the random, unique string
 */
export function makeUid(length = 8, radix = 36) {
	let uid = '';
	while (uid == '' || !includes(uids, uid)) {
		uid = (Math.random() + 1).toString(radix).substr(2, length);
		uids.push(uid);
	}

	while (uid.length < length) {
		uid += makeUid(length - uid.length, radix);
	}

	return uid;
}

/**
 * calculate the difference between two or more strings
 *
 * @method diffStrings
 *
 * @param {String} a the string to compare
 *
 * @param {String} b... the string(s) to compare against
 *
 * @param {Number} [min] the min difference to determine a match 0-1
 *
 * @return {Object}
 */
export function diffStrings(a, ...comparisons) {
	let min = 2 / 3;
	if (typeof last(comparisons) === 'number') {
		min = comparisons.pop();
	}

	min = Math.round(min * 100) / 100;

	a = camelCase(a);
	comparisons = comparisons.map(camelCase);

	const characterDiff = Math.min(...comparisons.map(b => leven(a, b)));
	const percentDiff = Math.round((characterDiff / a.length) * 100) / 100;
	const isMatch = percentDiff <= min;

	// console.log(characterDiff, a, ...comparisons);

	return {
		percentDiff,
		characterDiff,
		isMatch,
	};
}

/**
 * Will return the brand theme name Example: "lime2"
 * Fallback to sending null
 *
 * @method getBrandColor
 * @returns {*}
 */
export function getBrandColor() {
	let brandColor = window.document.body.getAttribute('ba-theme');
	if (!brandColor) {
		brandColor = 'lime1';
	}

	if (brandColor.startsWith('{')) {
		return JSON.parse(brandColor);
	}

	return brandColor;
}

/**
 * Return the current fabric theme color hex color. Example: "#82AF13"
 * Fallback to returning the legacy brand color
 *
 * @method getFabricBrandColor
 * @returns {*}
 */
export function getFabricBrandColor(variant = 'base') {
	const validOptions = ['base', 'light', 'lighter', 'lightest'];

	const brandColor = getBrandColor();
	const brandTheme = typeof brandColor === 'string' ? themes.themeColors.find(color => (
		color.name === brandColor ||
		color.base === brandColor
	)) : {
		light: brandColor[300],
		lighter: brandColor[300],
		lightest: brandColor[100],
		base: brandColor[500],
	}

	if (!validOptions.includes(variant)) {
		console.warn('Invalid Fabric variant. Valid options are: base, light, lighter, lightest');
		variant = 'base';
	}

	return brandTheme[variant];

}

/**
 * Returns the non-brand Fabric color's hex value as a string. Example: "#0772b3"
 * @method getFabricNonBrandColor
 * @param {String} colorName Name of non-brand Fabric color
 * @returns {String} Color hex value as string or undefined.
 */
export function getFabricNonBrandColor(colorName) {
	if (!isString(colorName)) {
		throw new Error('Invalid input type.');
	}

	const { colors: nonBrandColors } = ifFeature('encore', encoreColors, colors);
	const camelCaseColorName = camelCase(colorName);

	const color = nonBrandColors[camelCaseColorName];
	if (!color) {
		console.warn('Invalid Fabric non-brand color name.');
		return;
	}

	return color;
}

/**
 * an extension of jQuery's $.when function
 * which allows for passing non-promise arguments
 * that will instantly resolve, as well as functions
 * that can return a promise or a value.
 *
 * @method when
 *
 * @param  {any} vals any number of values to resolve
 *
 * @return {$.Deferred.promise}      The jQuery promise
 */
export function when(...vals) {
	return $.when(...vals.map((val) => {
		const defer = $.Deferred();

		if (isJQueryPromise(val)) {
			return val
				.done((result, status) => {
					if (
						(
							!result.success ||
							status !== 'success'
						) &&
						result.error &&
						result.errorMessage
					) {
						window.setMessage(result.errorMessage, 'error');
						console.error(result.error);
						defer.reject(result);
					}
				})
				.fail((...args) => {
					window.setMessage($.__('Uh Oh! There was an error. Please try again or contact BambooHR support.'), 'error');
					defer.reject(...args);
				});
		}

		defer.resolve(val);

		return defer.promise();
	}));
}

/**
 * determines if the given obj is a
 * valid jQuery object
 *
 * @method isJquery
 *
 * @param  {any}  obj the var to test
 *
 * @return {Boolean}     whether obj is valid
 */
export function isJquery<T extends {selector: any; jquery: JQuery}>(obj: T) {
	return (
		obj instanceof jQuery &&
		isString(obj.selector) &&
		isString(obj.jquery) &&
		obj.jquery === $.fn.jquery
	);
}

/**
 * determines if the given object is a
 * valid jQuery promise
 *
 * @method isPromise
 *
 * @param  {any}  obj the var to test
 *
 * @return {Boolean}     whether obj is valid
 */
export function isJQueryPromise(obj) {
	return (
		isPlainObject(obj) &&
		isFunction(obj.always) &&
		isFunction(obj.done) &&
		isFunction(obj.fail) &&
		isFunction(obj.pipe) &&
		isFunction(obj.progress) &&
		isFunction(obj.promise) &&
		isFunction(obj.state) &&
		isFunction(obj.then)
	);
}

/**
 * determines if the given object is a
 * valid Promise (or jQuery promise)
 *
 * @param obj
 * @returns {Boolean} whether obj is valid
 */
export function isPromise(obj) {
	return (
		isJQueryPromise(obj) ||
		obj instanceof Promise
	);
}

/**
 * determines if the given obj is a
 * valid jQuery object and contains actual
 * DOM elements
 *
 * @method isJqueryElement
 *
 * @param  {any}        obj the var to test
 *
 * @return {Boolean}           whether obj is valid
 */
export function isJqueryElement(obj) {
	return (
		isJquery(obj) &&
		obj.length > 0 &&
		isElement(obj[0])
	);
}

/**
 * checks if the given object is a
 * callable function. If it is, then
 * it's called, and the result is returned.
 * Otherwise, the object itself is returned.
 *
 * @method fnResult
 *
 * @param  {Function}     obj    the function to call
 * @param  {any}       scope the scope to use
 * @param  {any}       args  arguments to pass
 *
 * @return {any}             the result or the object
 */
export function fnResult(obj, scope, ...args) {
	if (isFunction(obj)) {
		return scope ? obj.apply(scope, args) : obj(...args);
	}

	return obj;
}

import * as Image from 'Image.util';
export { Image };

import Mediator from 'Mediator.util';
export { Mediator };

import Rect from 'Rect.util';
export { Rect };

import Form from 'Form.util';
export { Form };

const { env } = window.BambooHR;
export { env };

const prefetchedUrls = {};

/**
 * issue an xhr get and cache response content
 *
 * additional calls will queue the callback if the xhr is still pending. on
 * completion of xhr, queued callbacks are executed with response data. calls
 * after the xhr will reuse cached response.
 *
 * @param string url resource to GET
 * @param function cb callback to execute with response data
 */
export function prefetch(url, cb) {
	if (!prefetchedUrls.hasOwnProperty(url)) {
		prefetchedUrls[url] = {
			content: null,
			callbacks: typeof cb === 'undefined' ? [] : [cb],
		};
		$.get(url, (data) => {
			prefetchedUrls[url].content = data;
			prefetchedUrls[url].callbacks.forEach(c => c(data));
		});
	} else if (typeof cb !== 'undefined') {
		if (prefetchedUrls[url].content === null) {
			prefetchedUrls[url].callbacks.push(cb);
		} else {
			cb(prefetchedUrls[url].content);
		}
	}
}

/**
 * Returns a formatted array of strings as a string
 * @param  {object} array The array of strings
 * @return {string}       A string representation of the array
 */
export function getArrayString(array) {
	return `["${ array.join('", "') }"]`;
}

/**
 * If test function is false, sets interval and calls test function until true, then call callback
 * @param  function  test function, must return a boolean
 * @param  function  callback function that is called once test function returns true
 */

export function waitUntilReady(testFunc, cb) {
	const MAX_WAIT_TIME = 10000;
	const TIME_BEFORE_WARN = 1000;
	const INTERVAL_DELAY = 100;
	const startTime = new Date().getTime();
	if (!testFunc()) {
		const poll = setInterval(() => {
			const timePassed = new Date().getTime() - startTime;
			const timedOut = timePassed >= MAX_WAIT_TIME;
			if (timedOut) {
				window.Rollbar.error('The waitUntilReady util function timed out when testing this function', { testFunc });
			}
			if (timedOut || testFunc()) {
				if (!timedOut && timePassed >= TIME_BEFORE_WARN) {
					window.Rollbar.warning(`The waitUntilReady util function took longer than ${ TIME_BEFORE_WARN }ms to resolve.`, { timePassed });
				}
				clearInterval(poll);
				cb();
			}
		}, INTERVAL_DELAY);
		return;
	}
	cb();
}

export function jQueryFabTableSorter($table, sortList = [[0, 0]]) {
	$table.tablesorter({
		sortList,
		onRenderHeader(index, config) {
			const $this = $(this);

			if (!this[0].classList.contains('noSort')) {
				$this.addClass('fab-Table__header--sortable')
					.wrapInner('<span></span>');
			}
		},
		headers: {
			0: { sorter: 'text' },
		} });

	$table.bind('sortEnd', function(e, table) {
		const headers = Array.from(this.querySelectorAll('.fab-Table__header'));

		headers.forEach((header: HTMLElement) => {
			const sort = header.querySelector('span');

			if (sort) {
				if (header.classList.contains('sort-descending')) {
					sort.className = 'fab-Table__sorted fab-Table__sorted--dsc';
				} else if (header.classList.contains('sort-ascending')) {
					sort.className = 'fab-Table__sorted fab-Table__sorted--asc';
				} else {
					sort.className = '';
				}
			}
		});
	});
}

/**
 * Checks if the inner width of the browser is below our current support width per Fabric
 */
export function isWidthBelowSupportedMinWidth(): boolean {
	return window.innerWidth < 1124;
}

/**
 * Returns whether the current company is TRAX or not
 * Handles different variables set between controllers
 */
export function hasTrax(): boolean {
	return (!!window.companyHasTrax || !!window.hasTrax || false);
}
