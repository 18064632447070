import { Component } from 'react';
import { cloneDeep } from 'lodash';
import { TextButton } from '@fabric/button';
import { 
	StandardModal, 
	Button, 
	BodyText, 
	Flex, 
	IconV2, 
	SheetModal,
	LayoutBox 
} from '@bamboohr/fabric';
import Ajax from '@utils/ajax';

import { 
	formatDirectDepositAmount, 
	getPercentOrFlat
} from './direct-deposit-modal.domain';
import { AccountCard } from '../account-card';
import {
	accountNumIsValid,
	validateAccountData,
	formatAccountsToEdit,
	formatSaveData,
	getUnmaskedAccountNums,
	togglePaperCheckMsg,
	createNewAccount,
	DEFAULT_ERROR,
	updateFieldErrorData,
} from '../../utils';

import './direct-deposit-modal.styl';

export class DirectDepositModal extends Component {
	constructor(props) {
		super(props);

		const {
			accounts: existingAccounts,
			employeeId
		} = props;
		let accounts = [createNewAccount()];

		if (existingAccounts && existingAccounts.length) {
			accounts = formatAccountsToEdit(existingAccounts, false);
		}

		this.state = {
			employeeId,
			accounts,
			fieldErrorData: undefined,
			newId: -2,
			hidePaperCheckMsg: togglePaperCheckMsg(accounts[accounts.length - 1]),
			showJustCheckingModal: false,
		};

		this._initialState = cloneDeep(this.state);
	}

	_getFabricModalProps = (visible, primaryAction, onCancel) => {
		const { hasEditPermission } = this.props;
		const { isProcessing } = this.state;

		return {
			headerType: 'text',
			headline: $.__('Direct Deposit Accounts'),
			icon: 'fab-bank-20x19',
			isMobileFriendly: true,
			isOpen: visible,
			isProcessing,
			onClose: onCancel,
			primaryAction: hasEditPermission
				? primaryAction
				: () => {
						window.setMessage(
							$.__("You don't have access to edit Direct Deposit."),
							'error',
						);
				  },
			primaryActionText: $.__('Save'),
			sheetProps: this._getFabricSheetProps(),
			title: $.__('Update Direct Deposit'),
			type: 'medium',
		};
	};

	_getFabricSheetProps = () => {
		const { showJustCheckingModal } = this.state;

		return {
			content: (
				<p className="DDModal__checkingText">
					{ $.__('Are you sure you want to delete all direct deposit information and start receiving paper checks?') }
				</p>
			),
			headerType: 'stacked',
			headline: $.__('You will start receiving paper checks'),
			icon: 'fab-checkbook-70x56', // TODO: Test icon--replace with proper icon!
			isMobileFriendly: true,
			isOpen: showJustCheckingModal,
			onClose: () => this.setState({showJustCheckingModal: false}),
			primaryAction: this._handleDeleteAllAccounts,
			primaryActionText: $.__('Yes, Delete Direct Deposit'),
			title: $.__('Just Checking...')
		};
	};

	_handleAddAccount = () => {
		let {
			accounts,
			newId
		} = this.state;
		const newAccounts = [...accounts];
		newAccounts.push(createNewAccount(newId));

		const hidePaperCheckMsg = togglePaperCheckMsg(newAccounts[newAccounts.length - 1]);

		newId--;

		this.setState({
			accounts: newAccounts,
			newId,
			hidePaperCheckMsg,
		});

	};

	_handleAfterDeleteAllAccountsSaveSuccess = (response) => {
		const { onSaveSuccess } = this.props;

		this.setState({
			accounts: [createNewAccount()],
			fieldErrorData: undefined,
			showJustCheckingModal: false,
		}, () => {
			this._initialState = cloneDeep(this.state);
			if (typeof onSaveSuccess === 'function') {
				onSaveSuccess(response);
			}
		});
	}

	_handleAfterSaveSuccess = (response) => {
		const { onSaveSuccess } = this.props;

		this.setState({ isProcessing: false }, () => {
			this._initialState = cloneDeep(this.state);
			if (typeof onSaveSuccess === 'function') {
				onSaveSuccess(response);
			}
		});
	}

	_handleDeleteAllAccounts = () => {
		const { employeeId } = this.state;
		const {
			hasEditPermission,
			hasTrax,
			onSaveSuccess,
			customSave
		} = this.props;

		if (!hasTrax) {
			return;
		}

		if (!hasEditPermission) {
			window.setMessage(
				$.__("You don't have access to edit Direct Deposit."),
				'error',
			);
			return;
		}

		if (customSave && typeof customSave === 'function') {
			// New employee page -> handle callback instead!
			customSave(null, this._handleAfterDeleteAllAccountsSaveSuccess);
			return;
		}

		Ajax.post(`/payroll/direct_deposit/accounts/save/${ employeeId }`, {
			accounts: []
		})
			.then((response) => {
				if (response.status === 200) {
					this._handleAfterDeleteAllAccountsSaveSuccess(response);

					window.setMessage($.__('Direct Deposit info updated.'), 'success');
				}
			})
			.catch(() => {
				window.setMessage($.__('Failed to save Direct Deposit changes.'), 'error');
			});
	};

	_handleDeleteAccount = (id) => {
		const {accounts} = this.state;
		const newAccounts = [];

		if (accounts.length === 1) {
			this._handleDeleteLastAccount();
			return;
		}

		accounts.forEach((account) => {
			if (account.accountId !== id) {
				newAccounts.push(account);
			}
		});

		const hidePaperCheckMsg = togglePaperCheckMsg(newAccounts[newAccounts.length - 1]);

		this.setState({
			accounts: newAccounts,
			hidePaperCheckMsg,
		});
	};

	_handleDeleteLastAccount = () => {
		const {
			hasTrax,
		} = this.props;

		if (!hasTrax) {
			return;
		}

		this.setState({showJustCheckingModal: true});

	};

	_handleModalClose = () => {
		const {onCancelClick} = this.props;

		onCancelClick();
		this.setState(cloneDeep(this._initialState));
	};

	_handleRoutingNumBlur = (number, id) => {
		if (!number || !id) {
			return;
		}

		const {customRoutingValidationUrl} = this.props;
		const url = customRoutingValidationUrl ? `${ customRoutingValidationUrl }${ number }` : `/payroll/direct_deposit/routing/validate/${ number }`;
		const {accounts} = this.state;
		const newAccounts = [...accounts];
		const accountIndex = newAccounts.findIndex(account => account.accountId === id);
		newAccounts[accountIndex].routingNumPending = true;

		this.setState({accounts: newAccounts});

		const beforeGetModalOpenCount = this._modalOpenCount;
		Ajax.get(url)
			.then((res) => {
				if (!this._isModalOpen || this._modalOpenCount !== beforeGetModalOpenCount) {
					return;
				}
				const {data: {imageUrl, name}} = res;
				newAccounts[accountIndex].imgUrl = imageUrl;
				newAccounts[accountIndex].bankName = name;
				newAccounts[accountIndex].routingNumPending = false;
				newAccounts[accountIndex].routingNumError = false;
			})
			.catch(() => {
				if (!this._isModalOpen || this._modalOpenCount !== beforeGetModalOpenCount) {
					return;
				}
				newAccounts[accountIndex].imgUrl = null;
				newAccounts[accountIndex].bankName = null;
				newAccounts[accountIndex].routingNumPending = false;
				newAccounts[accountIndex].routingNumError = true;
			})
			.finally(() => {
				if (!this._isModalOpen || this._modalOpenCount !== beforeGetModalOpenCount) {
					return;
				}
				this.setState({accounts: newAccounts})
			});
	};

	_handleValueChange = (event, id, property, checkLastAmount = false) => {
		let {
			accounts,
			hidePaperCheckMsg,
		} = this.state;
		const newAccounts = [...accounts];
		const accountIndex = newAccounts.findIndex(account => account.accountId === id);
		
		const value = property === 'accountType' || property === 'flatOrPercent' ? event.value : event.target.value;

		if (property === 'accountNum' && !accountNumIsValid(value)) {
			return;
		}

		this.setState((prevState) => ({
			fieldErrorData: updateFieldErrorData(
				prevState.fieldErrorData,
				id,
				property,
				false,
			),
		}));

		newAccounts[accountIndex][property] = value;

		// Fix direct deposit amount on blur
		if (property === 'amount' && event.type === 'blur') {
			newAccounts[accountIndex].amount = formatDirectDepositAmount(newAccounts[accountIndex].amount, newAccounts[accountIndex].flatOrPercent);
		}

		if (property === 'flatOrPercent') {
			const $depositAmount = $(`.js-depositAmount${ id }`);
			newAccounts[accountIndex].flatOrPercent = getPercentOrFlat(event.value);
			newAccounts[accountIndex].amount = formatDirectDepositAmount($depositAmount.val(), newAccounts[accountIndex].flatOrPercent);
		}

		if (checkLastAmount) {
			const lastAccount = newAccounts[newAccounts.length - 1];
			hidePaperCheckMsg = togglePaperCheckMsg(lastAccount);
		}

		this.setState({
			accounts: newAccounts,
			hidePaperCheckMsg,
		});
	};

	_handleSaveClick = () => {
		const {
			employeeId,
			customSave,
			hasEditPermission,
		} = this.props;
		
		if (!hasEditPermission) {
			window.setMessage(
				$.__("You don't have access to edit Direct Deposit."),
				'error',
			);
			return;
		}

		this.setState({ isProcessing: true });

		const url = customSave && typeof customSave !== 'function' ? `${ customSave }${ employeeId }` : `/payroll/direct_deposit/accounts/save/${ employeeId }`;
		const {accounts} = this.state;
		const validate = validateAccountData(accounts);

		this.setState({ fieldErrorData: validate.fieldErrorData });

		if (!validate.valid) {
			this.setState({ isProcessing: false });
			window.setMessage(validate.error, 'error');
			return;
		}

		const formattedAccounts = formatSaveData(accounts);

		if (typeof customSave === 'function') {
			// New employee page -> handle callback instead!
			customSave(formattedAccounts, this._handleAfterSaveSuccess);
			return;
		}

		Ajax.post(url, {accounts: formattedAccounts})
			.then((res) => {
				const newStateAccounts = formatAccountsToEdit(res.data.direct_deposits.BANK, true);

				this.setState({ accounts: newStateAccounts }, () => {
					this._handleAfterSaveSuccess(res);
				});
			})
			.catch(() => {
				window.setMessage(DEFAULT_ERROR, 'error');
				this.setState({ isProcessing: false });
			});
	};

	_renderAccountCards = (hasTrax) => {
		let accountCards = [];
		const {hasEditPermission} = this.props;
		const { accounts, fieldErrorData, isProcessing } = this.state;
		const accountsToRender = [...accounts];
		accountsToRender[0].showDelete = hasTrax;

		accountsToRender.forEach((account, index) => {
			const accountFieldErrorData = fieldErrorData?.[account.accountId];

			account.order = index + 1;
			accountCards.push(
				<AccountCard
					hasAccountNumberError={!!accountFieldErrorData?.accountNum}
					hasAccountTypeError={!!accountFieldErrorData?.accountType}
					hasAmountError={!!accountFieldErrorData?.amount}
					hasEditPermission={hasEditPermission}
					hasRoutingNumberError={
						account.routingNumError ||
						!!accountFieldErrorData?.routingNum ||
						!!accountFieldErrorData?.routingNumError
					}
					isProcessing={isProcessing}
					key={ account.accountId }
					onAmountBlur={ this._handleAmountBlur }
					onDeleteAccount={ this._handleDeleteAccount }
					onRoutingNumBlur={ this._handleRoutingNumBlur }
					onValueChange={ this._handleValueChange }
					{ ...account }
				/>
			);
		});

		return accountCards;
	};

	_isModalOpen = false;
	// max number of accounts allowed if TRAX is not enabled
	_maxAccounts = 4;
	_modalOpenCount = 0;

	componentDidMount() {
		const {
			accounts,
			customAccountUnmaskUrl,
		} = this.props;

		// This operation should only be performed if editing existing accounts
		if (Array.isArray(accounts) && accounts.length) {
			const {accounts: stateAccounts} = this.state;
			const accountsCopy = cloneDeep(stateAccounts);
			const accountPromises = getUnmaskedAccountNums(accountsCopy, customAccountUnmaskUrl);

			Promise.all(accountPromises)
				.then((updatedAccounts) => {
					this.setState({accounts: updatedAccounts});
					this._initialState.accounts = cloneDeep(updatedAccounts);
				});
		}
	}

	componentDidUpdate(prevProps) {
		const {visible} = this.props;

		if (visible && (!prevProps || !prevProps.visible)) {
			this._modalOpenCount++;
		}
	}

	render() {
		const {
			accounts,
			fieldErrorData,
			hidePaperCheckMsg,
			isProcessing,
		} = this.state;
		const {
			visible,
			hasEditPermission,
			hasTrax,
			optionalPreHeader,
		} = this.props;

		this._isModalOpen = visible;
		const hasChangesRestriction = !hasEditPermission || isProcessing;

		return (
			<>
				<StandardModal isOpen={visible} onRequestClose={this._handleModalClose}>
					<StandardModal.Body
						renderFooter={<StandardModal.Footer actions={[
							<Button
								color="secondary"
								disabled={isProcessing}
								key="secondary"
								onClick={this._handleModalClose}
								type="button"
							>
								{ $.__('Close') }
							</Button>,
							<Button
								key="primary"
								onClick={this._handleSaveClick}
								processing={isProcessing}
								type="button"
							>
								{$.__('Save')}
							</Button>
						]}/>}
						renderHeader={<StandardModal.Header hasCloseButton={!isProcessing} title={$.__('Update Direct Deposit')} /> }
						size="medium"
					>
						{ optionalPreHeader && optionalPreHeader }
						<Flex flexDirection="row" gap={1} margin={3} marginBottom={2}>
							<IconV2 color="primary-strong" name="building-columns-solid" size={20} />
							<BodyText>
								{$.__('Direct Deposit Accounts')}
							</BodyText>
						</Flex>
						<StandardModal.Constraint canScroll={true}>
							<LayoutBox margin={2} marginTop={0}>

								<LayoutBox>
									{ this._renderAccountCards(hasTrax) }
								</LayoutBox>

								<Flex alignItems="center" flexDirection="row" justifyContent="space-between" marginBottom={2}>
									{accounts.length === this._maxAccounts && !hasTrax ? (
										<BodyText>
											{$.__('You can only have four direct deposit accounts')}.
										</BodyText>
									) : (
										<TextButton
											clickAction={this._handleAddAccount}
											isDisabled={hasChangesRestriction}
											text={`+ ${$.__('Add Account')}`}
											type="button"
										/>
									)}

									{hasTrax && (
										<TextButton
											clickAction={this._handleDeleteLastAccount}
											isDisabled={hasChangesRestriction}
											muted={true}
											text={$.__(`I'd prefer a paper check`)}
											type="button"
										/>
									)}
								</Flex>

								{!hidePaperCheckMsg && (
									<Flex flexDirection="row" gap={1} marginBottom={3}>
										<IconV2 color="neutral-medium" name="pen-field-solid" size={20} />
										<BodyText color="neutral-weak">{ $.__(`You'll receive a paper check with any remaining balance`) }.</BodyText>
									</Flex>
								)}

							</LayoutBox>
						</StandardModal.Constraint>
					</StandardModal.Body>
				</StandardModal>
				<SheetModal isOpen={this.state.showJustCheckingModal} onRequestClose={() => this.setState({showJustCheckingModal: false})}>
					<SheetModal.Body 
						renderFooter={
							<SheetModal.Footer
								actions={[
									<Button
										color="secondary"
										key="secondary"
										onClick={() => this.setState({showJustCheckingModal: false})}
										type="text"
									>
										{ $.__('Cancel') }
									</Button>,
									<Button
										disabled={hasChangesRestriction}
										key="primary"
										onClick={this._handleDeleteAllAccounts}
										type="button"
									>
										{$.__('Yes, Delete Direct Deposit')}
									</Button>
								]}
							/>
						}
						renderHeader={
							<SheetModal.Header 
								title={$.__('Just Checking...')}  
							/>}
					>
						<LayoutBox margin={2}>
							<SheetModal.HeroHeadline icon="pen-field-regular" text={$.__('You will start receiving paper checks')} />
							<BodyText justify="center">
								{ $.__('Are you sure you want to delete all direct deposit information and start receiving paper checks?') }
							</BodyText>
						</LayoutBox>
					</SheetModal.Body>
				</SheetModal>
			</>
		);
	}
}
