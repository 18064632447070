import { Component, createRef } from 'react';
import moment from 'moment';
import { isEnabled } from 'FeatureToggle.util';
import { BadgeV2, BodyText, Button, Flex, LayoutBox, NameValuePair, SelectField, StandardModal, TextArea, TextButton, TextField } from '@bamboohr/fabric';

import {
	formatTime,
	getTimeDuration,
	timeOfDayInputEnforcer,
	convertNormalizedProjectsToDropdownItems,
	getProjectDataFromId,
	getProjectSelectToggleText
} from 'time-tracking/utils';
import * as TimeTrackingService from 'time-tracking/http';
import { Map } from 'map.react';

import TimeSpinner from 'time-tracking/components/time-spinner.react';

const geolocationEnabled = isEnabled('timeTrackingGeolocation');

export default class AddNote extends Component {
	constructor(props) {
		super(props);

		const {
			clock,
			note,
			today,
			timezone,
			projectData,
		} = props;

		let startTimeMeridiem = null;
		let startTime = null;
		let noteText = '';
		let workedTime = null;

		if (clock && clock.latest) {
			startTimeMeridiem = today.format('A');
			startTime = today.format('h:mm');
			workedTime = getTimeDuration(today, moment().tz(timezone), 'hours');
		}

		if (note) {
			noteText = note;
		}

		this.state = {
			noteText: noteText,
			startTimeMeridiem: startTimeMeridiem,
			startTime: startTime,
			workedTime: workedTime,
			isModalOpen: false,
			isModalProcessing: false,
			projectData,
			showMap: false
		};

		this.timeInputRef = createRef();
	}

	_handleTimeInput = (event) => {
		const { startTimeMeridiem, startTime } = this.state;
		const value = event.target.value;
		this.timeInputRef.current.value = timeOfDayInputEnforcer(value, startTime);
		let workedTime = this._getWorkedTime(this.timeInputRef.current.value, startTimeMeridiem);
		if (workedTime < 0) {
			workedTime = 0.0;
		}

		this.setState({
			startTime: this.timeInputRef.current.value,
			workedTime
		});
	}

	_handleNoteInput = (event) => {
		const value = event.target.value;
		this.setState({
			noteText: value
		});
	}

	_handleProjectChange = (value) => {
		const { projectsWithTasks } = this.props;
		const projectData = getProjectDataFromId(value, projectsWithTasks);

		this.setState({
			projectData
		});
	}

	_getModalData = () => {
		const { noteText, startTimeMeridiem, startTime } = this.state;
		const { clock, today, timezone } = this.props;
		const todayDate = today.format('YYYY-MM-DD');
		const format = 'YYYY-MM-DD h:mm A';
		const selectedTime = moment.tz(`${ todayDate } ${ startTime } ${ startTimeMeridiem }`, format, timezone);
		let timeWasChanged = false;

		if (clock) {
			const originalTime = moment(clock.latest.start).tz(timezone);
			timeWasChanged = !originalTime.isSame(selectedTime);
		}

		const modalData = {
			note: noteText,
			selectedTime,
			timeWasChanged,
			timezone
		};

		return modalData;
	}

	_getWorkedTime = (time, meridiem) => {
		const { today, timezone } = this.props;
		const todayDate = today.format('YYYY-MM-DD');
		const format = 'YYYY-MM-DD h:mm A';
		const selectedTime = moment.tz(`${ todayDate } ${ time } ${ meridiem }`, format, timezone);
		return getTimeDuration(selectedTime, moment().tz(timezone), 'hours');
	}

	_saveNote = () => {
		const { projectData: { projectId, taskId } } = this.state;
		const { afterSave, canEdit, employeeId, totalTime, type } = this.props;
		const noteData = this._getModalData();
		this.setState({ isModalProcessing: true });

		if (type === 'clock') {
			const postData = {
				note: noteData.note,
				projectId,
				taskId
			};

			if (noteData.timeWasChanged && canEdit) {
				postData.start = noteData.selectedTime.format('HH:mm');
				postData.timezone = noteData.timezone;
			}

			TimeTrackingService.saveClockInData(employeeId, postData)
				.then(() => {
					this.setState({ isModalOpen: false });
					afterSave();
				})
				.catch(() => {
					this.setState({ isModalProcessing: false });
				});
		} else if (type === 'single') {
			const postData = {
				employeeId,
				hours: totalTime,
				date: noteData.selectedTime.format('YYYY-MM-DD'),
				note: noteData.note,
				projectId,
				taskId
			};
			TimeTrackingService.saveSingleDailyHours([postData])
				.then(() => {
					this.setState({isModalOpen: false});
					afterSave();
				})
				.catch(() => {
					this.setState({isModalProcessing: false});
				});
		}
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({isModalOpen: true});
		});
	}

	render() {
		const { isModalOpen, isModalProcessing, noteText, showMap, startTimeMeridiem, startTime, workedTime, projectData } = this.state;
		const { canEdit, clock, note, today, type, isViewOnly, projectsWithTasks } = this.props;
		const clockEditPermissions = clock && clock.clockedIn;
		const clockInAddress = clockEditPermissions ? clock?.latest.clockInAddress : false;
		const { clockInLatitude, clockInLongitude, clockInLocationAccuracy } = clock?.latest || {};
		const clockInLocationErrorExists = clock?.latest.clockInLocationAccuracy === -1 || false;

		let title = $.__('Update Time Entry');
		if (type === 'single') {
			title = note ? $.__('Update Note') : $.__('Add Note');
		}

		let selectedProjectOrTask = null;
		if (projectData && projectData.projectId && projectData.taskId) {
			selectedProjectOrTask = `${ projectData.projectId }-${ projectData.taskId }`;
		} else if (projectData && projectData.projectId) {
			selectedProjectOrTask = projectData.projectId;
		}

		const timeSpinner = (
			<Flex alignItems='center' gap={1} whiteSpace='nowrap'>
				<TimeSpinner />
				<BodyText color='primary'>{formatTime(workedTime)}</BodyText>
			</Flex>
		);

		const projectSelectSettings = {
			items: projectsWithTasks ? convertNormalizedProjectsToDropdownItems(projectsWithTasks) : [],
			onSelect: this._handleProjectChange,
			onClear: () => this._handleProjectChange(null),
			placeholder: projectData.projectName
				? getProjectSelectToggleText(null, projectData.projectName, projectData.taskName)
				: `--${$.__('Select Project/Task')}--`,
			renderToggleContent: (selectedItem) => getProjectSelectToggleText(selectedItem, projectData.projectName, projectData.taskName),
			selectedValues: [selectedProjectOrTask],
			width: 7,
		};

		const showProjectTaskSelect = projectsWithTasks && projectsWithTasks.allIds.length > 0;
		const projectTaskSelect = <SelectField
			label={$.__('Save time to...')}
			{...projectSelectSettings}
			notePlacement='inline'
		/>;

		const editTimeContent = (
			<Flex alignItems='end' gap={1}>
				<TextField
					defaultValue={startTime}
					disabled={!canEdit}
					label={$.__('Start Time')}
					// name='startTime'
					onChange={this._handleTimeInput}
					placeholder='hh:mm'
					ref={this.timeInputRef}
					required
					width={3}
				/>
				<SelectField
					disabled={!canEdit}
					isClearable={false}
					items={[
						{
							text: 'AM',
							value: 'AM'
						},
						{
							text: 'PM',
							value: 'PM'
						}
					]}
					note={showProjectTaskSelect ? null : timeSpinner}
					notePlacement='inline'
					onSelect={(value) => {
						const { startTime } = this.state;
						let newMeridiem = value;
						let workedTime = this._getWorkedTime(startTime, newMeridiem);
						if (workedTime < 0) {
							workedTime = 0.0;
						}

						this.setState({
							startTimeMeridiem: newMeridiem,
							workedTime
						});
					}}
					value={[startTimeMeridiem]}
					width={1}
				/>
				<LayoutBox marginLeft={1}>
					{showProjectTaskSelect ? projectTaskSelect : null}
				</LayoutBox>
			</Flex>
		);

		const locationWarningMessage = (
			<BodyText color='warning' icon='triangle-exclamation-solid'>
				{$.__(`There wasn’t a strong signal where they clocked in, so we can’t show it on the map.`)}
			</BodyText>
		);

		const content = (
			<Flex flexDirection='column' gap={2.5}>
				{clock && editTimeContent}
				{type === 'single' && showProjectTaskSelect ? projectTaskSelect : null}
				{geolocationEnabled && clockInAddress && (
					<Flex alignItems='start' flexDirection='column' gap={1}>
						<NameValuePair
							name={$.__('Clocked In Near')}
							value={clockInLocationErrorExists ? locationWarningMessage : clockInAddress}
						/>
						{ !showMap && !clockInLocationErrorExists && (
							<TextButton
								onClick={() => this.setState({ showMap: true })}
								endIcon='angles-down-regular'
								startIcon='location-dot-solid'
								type='button'
							>
								{$.__('Show Map')}
							</TextButton>
						)}
						{ showMap && !clockInLocationErrorExists && (
							<Map
								markers={[
									{ accuracy: clockInLocationAccuracy, coordinates: { lat: clockInLatitude, lng: clockInLongitude }, label: 'Clock In' },
								]}
								height='291px'
								width='396px'
								zoom={17}
							/>
						)}
					</Flex>
				)}
				<TextArea
					label={$.__('Note')}
					autoFocus
					value={noteText}
					disabled={!canEdit && !clockEditPermissions}
					InputProps={{ maxLength: 200 }}
					onChange={this._handleNoteInput}
					placeholder={$.__("Add note...")}
				/>
			</Flex>
		);

		return(
			<StandardModal
				isOpen={isModalOpen}
				isProcessing={isModalProcessing}
				onRequestClose={() => this.setState({ isModalOpen: false })}
			>
				<StandardModal.Body
					renderHeader={<StandardModal.Header title={title} />}
					renderFooter={
						<StandardModal.Footer
							actions={[
								<TextButton onClick={() => this.setState({ isModalOpen: false })} type='button'>
									{$.__('Cancel')}
								</TextButton>,
								...((canEdit && isViewOnly === false) || clockEditPermissions
									? [
										<Button onClick={this._saveNote} type='button'>
											{$.__('Save')}
										</Button>,
									]
									: []),
							]}
						/>
					}
				>
					<StandardModal.UpperContent>
						<BadgeV2 icon='calendar-days-regular' size='large' title={today.format('dddd, MMM D')} />
					</StandardModal.UpperContent>
					<StandardModal.Constraint>{content}</StandardModal.Constraint>
				</StandardModal.Body>
			</StandardModal>
		);
	}
}
