import { FC, useEffect, useRef } from 'react';
import { PanelProps, panelPropCheck } from '../types';
import {CaretRight5x9} from '@bamboohr/grim';
import useStyle from '../collapse.style';
import classNames from 'classnames';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { IconV2 } from '@bamboohr/fabric';

export const Panel:FC<React.PropsWithChildren<PanelProps>> = (props) => {
	const firstRenderRef = useRef(null);
	const fullProps = panelPropCheck(props);

	if (!fullProps) {
		throw new Error('Panel must appear as child of Collapse component.');
	}

	const {
		panelKey,
		openKeys,
		classes = {},
		handlePanelChange,
		icon,
		title,
		children,
		endElement,
		disabled,
		onClose,
		onOpen
	} = fullProps;

	const style = useStyle();

	const isOpen = openKeys.includes(panelKey);

	useEffect(() => {
		if (!firstRenderRef.current) {
			if (isOpen && onOpen && !disabled) {
				onOpen(panelKey);
			}

			firstRenderRef.current = true;
		} else if (firstRenderRef.current && isOpen && onOpen && !disabled) {
			onOpen(panelKey);
		} else if (firstRenderRef.current && !isOpen && onClose && !disabled) {
			onClose(panelKey);
		} 
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen, panelKey]);

	const handleKeyDown = (e) => {
		if (e.key === 'Enter' && !disabled) {
			handlePanelChange(panelKey);
		}
	};

	return (
		<div className={ classNames(style.root, classes.root, {[classes.rootOpen]: isOpen}) }>
			<div
				className={ classNames(style.header, classes.header, {[classes.headerOpen]: isOpen,
					[classes.headerDisabled]: disabled}) }
				onClick={ disabled ? () => null : () => handlePanelChange(panelKey) }
				onKeyDown={ handleKeyDown }
				role="button"
				tabIndex={ 0 }
			>
				<span 
					className={ 
						classNames(
							style.icon, 
							classes.icon, 
							{ [style.iconOpen]: isOpen && !disabled, 
								[classes.iconOpen]: isOpen
							}) 
					}
				>
					{ icon ? icon : ifFeature('encore', <IconV2 name='caret-right-solid' size={10}/>, <CaretRight5x9 />) }
				</span>
				<p className={ 
					classNames(
						classes.headerText, 
						{[classes.headerTextOpen]: isOpen,
							[classes.headerTextDisabled]: disabled
						}) 
				}
				>{ title }
				</p>
				{ endElement ? endElement : null }
			</div>
			{ isOpen ? (
				<div className={ classNames(classes.content, {
					[style.closed]: !isOpen,
					[classes.contentOpen]: isOpen
				}) }
				>{ children }
				</div>
			) : null }
		</div>
	);
};
