import {
	Checkbox,
	Select,
	SelectField,
	TextArea,
	BodyText,
	Flex,
	Label,
} from '@bamboohr/fabric';
import React, { useEffect, useMemo, useState } from 'react';
import {
	CancellationType,
	CancellationContentProps,
	CancellationFormsData,
} from '../../types';
import { ModalContentWrapper } from '../modal-content-wrapper';
import { isEnabled } from '@bamboohr/utils/lib/feature';
import getHasDiscountPackage from '../../../../util/getHasDiscountPackage';
import { CancellationItemsMap } from '../../constants';

export type ProductKey = Exclude<
	CancellationType,
	CancellationType.JOB_OPENINGS | CancellationType.PAYROLL
>;
interface Props extends CancellationContentProps {
	productKeys: ProductKey[];
}

const OTHER_REASON = ['other', 'other reason...', 'other reason…'];

const ProductsFormConstants = {
	[CancellationType.ACCOUNT]: {
		label: `${$.__('What is your primary reason for cancelling?')}*`,
		detailsLabel: `${$.__(
			"Are there any additional reasons you are cancelling that aren't listed above?",
		)}*`,
		valuesThatTriggerDetails: [
			...OTHER_REASON,
			'we don’t use our bamboohr account',
			'bamboohr doesn’t have the functionality we need',
		],
	},
	[CancellationType.PERFORMANCE]: {
		label: `${$.__(
			'What is your primary reason for cancelling Performance Management?',
		)}*`,
		detailsLabel: `${$.__(
			'Could you describe why you are cancelling Performance Management?',
		)}*`,
		valuesThatTriggerDetails: [...OTHER_REASON, 'missing functionality'],
	},
	[CancellationType.TIME_TRACKING]: {
		label: `${$.__(
			'What is your primary reason for cancelling Time Tracking?',
		)}*`,
		detailsLabel: `${$.__(
			'Could you describe why you are cancelling Time Tracking?',
		)}*`,
		valuesThatTriggerDetails: [...OTHER_REASON, 'missing functionality'],
	},
	[CancellationType.BENEFIT_ADMIN]: {
		label: `${$.__(
			'What is your primary reason for cancelling Benefits Administration?',
		)}*`,
		detailsLabel: `${$.__(
			'Could you describe why you are cancelling Benefits Administration?',
		)}*`,
		valuesThatTriggerDetails: [...OTHER_REASON, 'missing functionality'],
	},
};

export function StandardCancellationForm({
	isLastStep,
	nextBillingDates,
	onClose,
	onNext,
	productKeys,
	reasons,
	renderHeader,
	biId,
	onPrevious,
}: Props): JSX.Element {
	const [cancellationFormsData, setCancellationFormsData] =
		useState<CancellationFormsData>({
			cancellation_date: nextBillingDates[1]?.value,
		});
	const [showDiscountCheckbox, setShowDiscountCheckbox] = useState(false);
	const [acknowledge, setAcknowledge] = useState(false);

	const allowedProductKeys = [
		CancellationType.ACCOUNT,
		CancellationType.PERFORMANCE,
		CancellationType.TIME_TRACKING,
		CancellationType.BENEFIT_ADMIN,
	];
	const filteredProductKeys = useMemo(
		() => productKeys.filter((key) => allowedProductKeys.includes(key)),
		[productKeys, allowedProductKeys],
	);

	const requiredTextBoxEnabled = isEnabled(
		'CANCELLATION_DETAILS_REQUIRED_TEXT_BOX',
	);

	const getHeaderContent = (productKeys) => {
		if (productKeys.includes(CancellationType.ACCOUNT)) {
			return $.__(
				"We'll be sad to see you go! Please fill out the following and we will be in touch to process your cancellation.",
			);
		}
		if (isEnabled('GT_CLOCKS_INTEGRATION_BETA') && productKeys.length === 1) {
			return $.__(
				"We're bummed you won't be using %1 anymore! Please fill out the following and we will process your cancellation.",
				CancellationItemsMap[productKeys[0]].productName,
			);
		}
		return $.__(
			"We're bummed you won't be using those products anymore! Please fill out the following and we will be in touch to process your cancellation.",
		);
	};
	const headerContent = getHeaderContent(productKeys);

	const areRequiredFieldsFilled = (): boolean => {
		if (
			showDiscountCheckbox &&
			!acknowledge &&
			!filteredProductKeys.includes(CancellationType.ACCOUNT)
		) {
			return false;
		}

		const isDateFilled = !!cancellationFormsData.cancellation_date;
		const areReasonsFilled = filteredProductKeys.every((key) => {
			return !!cancellationFormsData[key]?.reason;
		});
		// Details are only required if the reason is Other or Missing Functionality
		const areDetailsFilled = filteredProductKeys.every((key) => {
			const reason = cancellationFormsData[key]?.reason;
			if (!reason) {
				return false;
			}
			if (
				ProductsFormConstants[key].valuesThatTriggerDetails.includes(
					reason?.toLowerCase(),
				)
			) {
				return !!cancellationFormsData[key].reason_details;
			}
			return true;
		});

		const freeTextBoxFilled = filteredProductKeys.every((key) => {
			const reason = cancellationFormsData[key]?.reason;
			if (!reason) {
				return false;
			}
			return !!cancellationFormsData[key].reason_details;
		});

		return (
			isDateFilled &&
			areReasonsFilled &&
			areDetailsFilled &&
			(requiredTextBoxEnabled ? freeTextBoxFilled : true)
		);
	};

	useEffect(() => {
		const fetchHasPackage = async () => {
			// Are we on the discount package?
			const hasDiscountPackage = await getHasDiscountPackage();

			setShowDiscountCheckbox(hasDiscountPackage);
		};

		const discountedProducts: CancellationType[] = [
			CancellationType.BENEFIT_ADMIN,
			CancellationType.PAYROLL,
		];

		// Check if discounted product is cancelled
		const productsThatAreDiscounted = filteredProductKeys.filter((product) =>
			discountedProducts.includes(product),
		);

		if (
			productsThatAreDiscounted.length > 0 &&
			!filteredProductKeys.includes(CancellationType.ACCOUNT)
		) {
			fetchHasPackage();
		} else {
			setShowDiscountCheckbox(false);
		}
	}, [filteredProductKeys]);

	return (
		<ModalContentWrapper
			biId={biId}
			disablePrimaryButton={!areRequiredFieldsFilled()}
			isLastStep={isLastStep}
			onClose={onClose}
			onNext={() => onNext(cancellationFormsData)}
			onPrevious={onPrevious}
			renderHeader={renderHeader}
		>
			<form>
				<Flex flexDirection="column" gap={2}>
					<BodyText>{headerContent}</BodyText>

					<SelectField
						id="cancel-date"
						items={nextBillingDates}
						isClearable={false}
						label={`${$.__('Desired Cancellation Date')}*`}
						onChange={(e) => {
							const cancellationFormsDataCopy = { ...cancellationFormsData };
							// casting into unknown first because the definition of e.target.value is wrong
							cancellationFormsDataCopy.cancellation_date = e.target
								.value as unknown as string;
							setCancellationFormsData(cancellationFormsDataCopy);
						}}
						renderOptionContent={(item) =>
							item.value === 'note' && <BodyText>{item.text}</BodyText>
						}
						value={cancellationFormsData.cancellation_date}
						width={6}
					/>

					{filteredProductKeys.map((productKey) => {
						const productReasonsItems = reasons[productKey];
						// todo: remove after requiredTextBoxEnabled toggle cleanup
						let shouldShowDetailField =
							cancellationFormsData[productKey]?.reason &&
							ProductsFormConstants[
								productKey
							].valuesThatTriggerDetails.includes(
								cancellationFormsData[productKey]?.reason?.toLowerCase(),
							);

						if (requiredTextBoxEnabled) {
							shouldShowDetailField = true;
						}

						return (
							<Flex
								flexDirection="column"
								gap={2}
								key={productKey}
								marginBottom={2}
							>
								<Flex flexDirection="column" gap={0.5}>
									<Label htmlFor={`${productKey}-reason`}>
										{ProductsFormConstants[productKey].label}
									</Label>
									<Select
										items={productReasonsItems}
										isClearable={false}
										name={`${productKey}-reason`}
										onChange={(selectedValues) => {
											const reason = selectedValues[0] as string;
											const cancellationFormsDataCopy = {
												...cancellationFormsData,
											};
											if (productKey in cancellationFormsDataCopy) {
												cancellationFormsDataCopy[productKey].reason = reason;
											} else {
												cancellationFormsDataCopy[productKey] = {
													feature: productKey,
													reason,
												};
											}
											setCancellationFormsData(cancellationFormsDataCopy);
										}}
										selectedValues={[cancellationFormsData[productKey]?.reason]}
										width={100}
									/>
								</Flex>

								{/* todo: remove shouldShowDetailField after requiredTextBoxEnabled toggle cleanup */}
								{shouldShowDetailField && (
									<TextArea
										minRows={5}
										maxRows={5}
										id={`${productKey}-cancel-reason-details`}
										label={
											requiredTextBoxEnabled
												? $.__(
														'What additional details can you provide us with?*',
												  )
												: ProductsFormConstants[productKey].detailsLabel
										}
										onChange={(e) => {
											const reasonDetails = e.target.value;
											const cancellationFormsDataCopy = {
												...cancellationFormsData,
											};
											if (productKey in cancellationFormsDataCopy) {
												cancellationFormsDataCopy[productKey].reason_details =
													reasonDetails;
											} else {
												cancellationFormsDataCopy[productKey] = {
													reason_details: reasonDetails,
												};
											}
											setCancellationFormsData(cancellationFormsDataCopy);
										}}
										placeholder={$.__(
											'What additional details can you provide us with?',
										)}
										value={cancellationFormsData[productKey]?.reason_details}
									/>
								)}
							</Flex>
						);
					})}

					{showDiscountCheckbox && (
						<Flex flexDirection="column" marginTop={5}>
							<Checkbox
								value="cancellation-discount-checkbox"
								onChange={() => {
									setAcknowledge(!acknowledge);
								}}
								checked={acknowledge}
								biId="cancellation-discount-checkbox"
								id="cancellation-discount-checkbox"
								name="cancellation-discount-checkbox"
								label={$.__(
									'I acknowledge that cancelling this feature will remove my 15%% bundle discount.',
								)}
							/>
						</Flex>
					)}
				</Flex>
			</form>
		</ModalContentWrapper>
	);
}
