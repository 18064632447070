import './styles.styl';
import { classNameFromObject } from '@utils/dom';
import moment from 'moment.lib';
import { IconV2, Flex } from '@bamboohr/fabric';
import { isEnabled } from 'FeatureToggle.util';
import { TIME_FORMAT } from '../../constants';
import { formatTime } from '../../utils';
import { EntryProjectTaskNote } from 'time-tracking/components/entry-project-task-note.react';

const geolocationEnabled = isEnabled('timeTrackingGeolocation');

export default function TimeEntries(props) {
	const {
		clockEntries,
		renderProjectAndTask,
		canEditEntries,
		currentEntryClick,
		elapsedTimeToday,
		isForgotClockDay,
		isViewOnly,
		onEditClick,
		hasLightBackground,
		type
	} = props;

	return clockEntries.map((clockEntry) => {
		const {
			start,
			end,
			timezone,
			hours,
			id,
			note,
			projectName,
			taskName,
		} = clockEntry;

		// Geolocation data
		const {
			clockInLatitude,
			clockInLongitude,
			clockOutLatitude,
			clockOutLongitude,
		} = clockEntry;

		const locationExists = !!((clockInLatitude && clockInLongitude) || (clockOutLatitude && clockOutLongitude));
		const onEditClicked = (typeof onEditClick === 'function') ? onEditClick : Function.prototype;
        
		let handleEntryClick = end && id ? () => onEditClicked(id, 'view', timezone) : currentEntryClick;
		if (canEditEntries && !isForgotClockDay) {
			handleEntryClick = end && id ? () => onEditClicked(id, 'edit', timezone) : currentEntryClick;
		}

		// placeholder entries on days other than the first day of multi-day clocked in spans don't need start time
		const startString = (start ? moment.tz(start, timezone).format(TIME_FORMAT) : '12:00 AM');
		const endString = (end ? moment.tz(end, timezone).format(TIME_FORMAT) : $.__('Now'));
		const totalString = (end ? formatTime(hours) : formatTime(elapsedTimeToday));
		const showGeolocationIcon = geolocationEnabled && locationExists;
		const showEditIcon = canEditEntries && !isForgotClockDay;

		return (
			<div
				className={classNameFromObject({
					TimeEntry: true,
					'TimeEntry--active': !end,
					'TimeEntry--showEditIcon': canEditEntries && !isForgotClockDay,
					'TimeEntry--viewable': true,
					'TimeEntry--light': hasLightBackground,
					'TimeEntry--forgotClockOut': isForgotClockDay,
				})}
				data-bi-id='time-tracking-time-entry'
				key={id}
				onClick={handleEntryClick}
			>
				<span className='TimeEntry__start'>{startString}</span>-<span className='TimeEntry__end'>{endString}</span>
				›
				<span className='TimeEntry__total'>{totalString}</span>
				<EntryProjectTaskNote
					endString={endString}
					isViewOnly={isViewOnly}
					note={note}
					projectName={projectName}
					renderProjectAndTask={renderProjectAndTask}
					startString={startString}
					taskName={taskName}
					type={type}
				/>
				{showGeolocationIcon || showEditIcon ? (
					<Flex alignItems='center' gap={1} marginLeft='auto' paddingLeft={1}>
						{showGeolocationIcon && (
							<span className='TimeEntry__locationIcon'>
								<IconV2 color='neutral-extra-strong' name='location-dot-solid' size={12} />
							</span>
						)}
						{showEditIcon && (
							<span className='TimeEntry__editIcon'>
								<IconV2 color='neutral-extra-strong' name='pen-solid' size={12} />
							</span>
						)}
					</Flex>
				) : null}
			</div>
		);
	});
}
