import { consume } from '../../store/context';
import { Select } from '@fabric/select';
import { isEnabled } from '@bamboohr/utils/lib/feature';

export function TimesheetDropdown(props) {
	const {
		employeeTimesheets,
		focusedTimesheet: { id: focusedTimesheetId },
		latestTimesheetId,
		latestPreviousTimesheetId,
	} = props;

	const timesheetList = [];

	if( isEnabled('HISTORICAL_TIMESHEETS') ){
		let previousTimesheet;
		let currentTimesheet;

		Object.keys(employeeTimesheets).reverse().forEach(year => {
			const timesheets = [];

			employeeTimesheets[year].forEach((ts, index) => {
				if (Number(latestTimesheetId) === Number(ts.id)) {
					currentTimesheet = {
						key: `${year}-${ts.id}-${index}`,
						text: $.__('This Pay Period'),
						value: Number(ts.id)
					};
					return false;
				}
				if (Number(latestPreviousTimesheetId) === Number(ts.id)) {
					previousTimesheet = {
						key: `${year}-${ts.id}-${index}`,
						text: $.__('Previous Pay Period'),
						value: Number(ts.id)
					};
					return false;
				}
				timesheets.push({
					key: `${year}-${ts.id}-${index}`,
					text: `${ moment(ts.start).format() } – ${ moment(ts.end).format() }`,
					value: Number(ts.id)
				});
			});

			if( timesheets.length > 0 ){
				timesheetList.push({
					text: year,
					value: year,
					items: timesheets
				});
			}
		});

		if( previousTimesheet ){ timesheetList.unshift(previousTimesheet); }
		if( currentTimesheet ){ timesheetList.unshift(currentTimesheet); }
	} else {
		employeeTimesheets.forEach((ts) => {
			const intTimesheetId = Number(ts.id);
			let text = `${ moment(ts.start).format() } – ${ moment(ts.end).format() }`;

			if (latestTimesheetId === intTimesheetId) {
				text = $.__('This Pay Period');
			} else if (latestPreviousTimesheetId === intTimesheetId) {
				text = $.__('Previous Pay Period');
			}

			timesheetList.push({
				text,
				value: intTimesheetId,
			});
		});
	}

	if( timesheetList.length === 0 ){
		return null;
	}

	function handleJadeSelectChange(param) {
		window.location = makeUrl(latestTimesheetId, param);
	}

	return (
		<div className="TimesheetHeader__dropdown">
			<div className="TimesheetHeader__period">
				<Select
					variant="single"
					isClearable={ false }
					items={ timesheetList }
					onChange={ handleJadeSelectChange }
					selectedValues={ [focusedTimesheetId] }
					showSearch="never"
					size="small"
					width="7"
				/>
			</div>
		</div>
	);
}

export default consume([
	'employeeTimesheets',
	'focusedTimesheet',
	'latestTimesheetId',
	'latestPreviousTimesheetId',
], TimesheetDropdown);

export function makeUrl(latestTimesheetId, param) {
	const timesheetIdParam = latestTimesheetId === param[0] ? '' : `&et_id=${ param[0] }`;
	return `/employees/timesheet/?id=${ window.Employee.id }${ timesheetIdParam }`;
}
