import { FC } from 'react';
import { BlankState, Button, makeStyles } from '@bamboohr/fabric';
import { DEPENDENT_BLANK_TITLE, ADD_DEPENDENT, DependentBlankStateProps } from './constants';

const useBlankStateStyles = makeStyles((theme) => ({
	icon: {
		fill: theme.palette.gray[600],
	},
	root: {
		marginTop: '72px',
	},
	subtitle: {
		color: theme.palette.gray[600],
	},
	title: {
		color: theme.palette.gray[600],
	},
}));

export const DependentBlankDefault: FC<React.PropsWithChildren<DependentBlankStateProps>> = ({ onClick, canAdd }) => {
	const blankStateClasses = useBlankStateStyles();
	return (
        <BlankState
			classes={blankStateClasses}
			icon='bs-doc-missing-field'
			title={DEPENDENT_BLANK_TITLE}
			actions={canAdd && [
                <Button
                    color='secondary'
                    data-bi-id='employees-dependents-add-dependent'
                    onClick={onClick}
                    startIcon='plus-regular'
                    type='button'
                >
                    {ADD_DEPENDENT}
                </Button>,
            ]}
		>
        </BlankState>
    );
};
