import { FunctionComponent, useState } from 'react';
import { cloneDeep } from 'lodash';
import { DirectDeposit } from 'payroll/direct-deposit.react';

type DirectDepositItemUnsaved = {
    account_id?: string;
    amount: string;
    flat_or_percent: 'FLAT' | 'PCT';
    image_url: string;
    name: string;
    number: string;
    order: number;
    routing_number: string;
    type: string;
}

type DirectDepositItem = DirectDepositItemUnsaved & {
    accountId: string;
    calculationType: 'Dollar' | 'Balance'| 'Percent';
    imageUrl: string;
    isBankImageUrl: boolean;
    numberRaw: string;
    rawAmount: string;
    postItem: DirectDepositItemUnsaved;
}

const _convertBankData = (data: DirectDepositItemUnsaved[]): DirectDepositItem[] => {
	return data.map((item) => {
		// eslint-disable-next-line no-nested-ternary
		const calculationType = item.flat_or_percent === 'FLAT' ? 'Dollar' : item.amount === '100' ? 'Balance' : 'Percent';
		// Create deep clone of the item so it can be mutated to visual object
		// but maintain original for hidden inputs to save.
		const postItem = cloneDeep(item);

		return {
			postItem,
			...item,
			accountId: item.order.toString(),
			amount: item.flat_or_percent === 'FLAT' ? `$${ item.amount }` : `${ item.amount }%`,
			calculationType,
			imageUrl: item.image_url,
			isBankImageUrl: !!item.image_url,
			numberRaw: item.number,
			number: `X-${ item.number.substr(item.number.length - 4) }`,
			rawAmount: item.amount,
			routingNumber: item.routing_number,
		};
	});
};

const TablesDirectDepositElement: FunctionComponent<React.PropsWithChildren<DynamicForm.CustomElement>> = ({context}) => {
	const [depositItems, setDepositItems] = useState<DirectDepositItem[]>([]);

	const {
		formOptions: {isNHPPacket},
	} = context;

	const _handleDirectDepositChange = (accounts, callback): void => {
		const convertedAccounts = _convertBankData(accounts || []);
		setDepositItems(convertedAccounts);
		const response = {
			data: {
				direct_deposits: {
					BANK: convertedAccounts,
				},
			},
		};
		if (callback && typeof callback === 'function') {
			callback(response);
		}
	};

	const _renderHiddenInputs = (): React.ReactElement[] => {
		return depositItems.map((item) => {
			return (
				<>
					{ Object.keys(item.postItem).map((key) => {
						const { postItem } = item;
						return (
							<input
								key={ `${ postItem.order }${ key }${ postItem.routing_number }${ postItem.number }` }
								hidden={ true }
								name={ `accounts[${ postItem.order }][${ key }]` }
								type="text"
								value={ postItem[key] }
							/>
						);
					}) }
				</>
			);
		});
	};

	return (
		<>
			<DirectDeposit
				customSave={ _handleDirectDepositChange }
				deposits={ [] }
				employeeId={ 0 }
				hasTrax={ true }
				isDisabled={ isNHPPacket }
				isNewEmployeeForm={ true }
				permissionLevel={ 3 }
			/>
			{ _renderHiddenInputs() }
		</>
	);
};

export default TablesDirectDepositElement;
