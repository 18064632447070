import { FunctionComponent } from 'react';
import { getCompensationStates, Fields } from 'dynamic-form';

const CurrencyOvertimeRateField: FunctionComponent<React.PropsWithChildren<DynamicForm.TextElementProps>> = (currencyProps) => {
	const { props, settings, context } = currencyProps;
	const {
		controls: { CurrencyField },
	} = context;
	const {
		[Fields.overtimeRate]: {
			note,
			show,
		},
	} = getCompensationStates(context);

	return show && (
		<CurrencyField
			context={ context }
			props={ props }
			settings={ { ...settings, note: { text: note, type: 'base' } } }
		/>
	);
};

export default CurrencyOvertimeRateField;
