import { FunctionComponent } from 'react';

const SelectPayGroupField: FunctionComponent<React.PropsWithChildren<DynamicForm.SelectElementProps>> = (selectProps) => {
	const { props, settings, context } = selectProps;
	const {
		controls: { SelectField },
	} = context;

	return (
		<>
			<SelectField
				context={ context }
				props={ props }
				settings={ settings }
			/>
		</>
	);
};

export default SelectPayGroupField;
