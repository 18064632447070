import {
	BodyText,
	Divider,
	Flex,
	Headline,
	IconV2,
	LayoutBox,
	Link,
	Section
} from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';
import moment from 'moment.lib';
import { ReactElement } from 'react';
import { EmployeeInfo } from '../EmployeeInfo';

import { Employee } from 'NHPTemplates.mod/Previewer/Components/EmployeeInfo/employeeInterface';

interface Props {
	withHeader: boolean;
	companyName: string;
	employeeName: string;
	firstDayDate: string;
	contact: Employee;
	arriveByTime: string;
	location: string;
	otherInstructions: string;
	asSection?: boolean;
}

function formatTime(time: string): string {
	const formattedTime = moment(time, [moment.ISO_8601, 'hh:mm:ss']).format(
		'h:mm A',
	);
	return formattedTime === 'Invalid date' ? null : formattedTime;
}

const dateFormat = 'dddd, MMMM D, YYYY';
function formatDate(date: string): string {
	let formattedDate;
	if (date) {
		formattedDate = moment(date).format(dateFormat);
	} else {
		return undefined;
	}
	return formattedDate === 'Invalid date' ? undefined : formattedDate;
}

export function WhenAndWhere(props: Props): ReactElement {
	const {
		withHeader,
		firstDayDate,
		employeeName,
		companyName,
		contact,
		arriveByTime,
		location,
		otherInstructions,
		asSection = false,
	} = props;

	const parsedFirstDayDate: string = formatDate(firstDayDate);

	const headerTitle = (
		<Message params={[employeeName]} text={$._('Welcome Aboard, {1}!')} />
	);
	const headerSubtitle = (
		<Message
			params={[companyName]}
			text={$._(
				`We are excited that you are joining us here at {1}. We can't wait for you to start. We've included some helpful information and ask that you complete this new employee packet so you can hit the ground running on your first day. If you have any questions, please don't hesitate to ask.`,
			)}
		/>
	);

	const Container = asSection ? Section : LayoutBox;

	return (
		<Container height="100%">
			<Flex flexDirection="column" flexGrow={1}>
				{withHeader && (
					<Flex flexDirection="column">
						<LayoutBox marginBottom="4px">
							<Headline color="primary" size="medium">
								{headerTitle}
							</Headline>
						</LayoutBox>
						<LayoutBox marginBottom={2}>
							<BodyText size="extra-small">{headerSubtitle}</BodyText>
						</LayoutBox>
						<Divider
							color="neutral-extra-weak"
							marginBottom="24px"
							marginTop="16px"
						/>
					</Flex>
				)}

				<Flex flexDirection="row" justifyContent="space-between">
					<Flex flex="50%" flexDirection="column" gap="20px">
						<Headline size="small">{$._('Your First Day')}</Headline>
						<Flex flexDirection="column" gap={2}>
							<Flex alignItems="center" gap="12px">
								<IconV2
									color="primary-strong"
									name="calendar-star-regular"
									size={20}
								/>
								<Headline color="neutral-strong" size="extra-small">
									{parsedFirstDayDate ||
										moment(Date.now()).add(14, 'days').format(dateFormat)}
								</Headline>
							</Flex>
							{arriveByTime && (
								<Flex alignItems="center" gap="12px">
									<IconV2
										color="primary-strong"
										name="clock-regular"
										size={20}
									/>
									<Headline color="neutral-strong" size="extra-small">
										{formatTime(arriveByTime)}
									</Headline>
								</Flex>
							)}
							{location && (
								<Flex flexDirection="column" gap="4px">
									<Flex alignItems="center" gap="12px">
										<IconV2
											color="primary-strong"
											name="location-dot-regular"
											size={20}
										/>
										<Headline color="neutral-strong" size="extra-small">
											{location}
										</Headline>
									</Flex>
									<LayoutBox marginLeft={4}>
										<Link
											href={`https://maps.google.com/?q=${location}`}
											rel="noopener noreferrer"
											target="_blank"
										>
											<Flex alignItems="center" gap="8px">
												<IconV2 name="compass-regular" size={16} />
												<BodyText size="extra-small">Map & Directions</BodyText>
											</Flex>
										</Link>
									</LayoutBox>
								</Flex>
							)}
						</Flex>
					</Flex>

					<Flex flex="50%" flexDirection="column" gap={4}>
						{contact && (
							<Flex flexDirection="column" gap={2}>
								<Headline color="primary" size="small">
									{$._('Who to Contact')}
								</Headline>
								<EmployeeInfo avatarSize={64} employee={contact} />
							</Flex>
						)}
						{otherInstructions && (
							<Flex flexDirection="column" gap={2}>
								<Headline color="primary" size="small">
									{$._('Other Instructions')}
								</Headline>
								{otherInstructions.split('\n').map((i, key) => {
									return <BodyText key={key}>{i}</BodyText>;
								})}
							</Flex>
						)}
					</Flex>
				</Flex>
			</Flex>
		</Container>
	);
}
