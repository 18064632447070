import { FunctionComponent } from 'react';

const ObfuscatedPayrollField: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
	return (
		<div className="FormInputObfuscateWrapper FormInputObfuscateWrapper--insideFieldset">
			<ba-icon className="FormInputObfuscateIcon" name="lock-8x11" />
			{ $.__(`You don't have access to view the information in this section.`)}
		</div>
	);
};

export default ObfuscatedPayrollField;
