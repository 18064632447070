import { useState } from 'react';
import { LayoutBox, SelectField, TextArea } from '@bamboohr/fabric';
import { CancellationContentProps, PayrollFormData } from '../../../../types';
import { ModalContentWrapper } from '../../../modal-content-wrapper';
interface Step2bFormValues {
	reason_not_using_federal_id: PayrollFormData['reason_not_using_federal_id'];
	reason_not_using_federal_id_details: PayrollFormData['reason_not_using_federal_id_details'];
}

export const FormB = ({
	onNext,
	reasons,
	...rest
}: CancellationContentProps): JSX.Element => {
	const [formValues, setFormValues] = useState<Step2bFormValues>({
		reason_not_using_federal_id: null,
		reason_not_using_federal_id_details: null,
	});

	const mergeFormValues = (values: Partial<Step2bFormValues>) => {
		setFormValues({ ...formValues, ...values });
	};

	const handleOnNext = () => {
		onNext({ payroll_data: formValues });
	};

	const detailsRequired =
		formValues.reason_not_using_federal_id?.toLowerCase() === 'other';
	const disalbeNextButton =
		!formValues.reason_not_using_federal_id ||
		(detailsRequired && !formValues.reason_not_using_federal_id_details);

	return (
		<ModalContentWrapper
			{...rest}
			disablePrimaryButton={disalbeNextButton}
			onNext={handleOnNext}
		>
			<LayoutBox marginBottom={2}>
				<SelectField
					id="reason_not_using_federal_id"
					isClearable={false}
					items={reasons.payroll_no_use_fed_id_reasons}
					label={$.__('Reason for no longer processing with this Federal ID')}
					onChange={(e) =>
						mergeFormValues({ reason_not_using_federal_id: e.target.value[0] })
					}
					required={true}
					showSearch="never"
					value={[formValues.reason_not_using_federal_id || '']}
					width={100}
				/>
			</LayoutBox>
			<LayoutBox marginBottom={2}>
				<TextArea
					id="reason_not_using_federal_id_details"
					label={$.__('Please provide any additional details here')}
					minRows={3}
					onChange={(e) =>
						mergeFormValues({
							reason_not_using_federal_id_details: e.target.value,
						})
					}
					required={detailsRequired}
					value={formValues.reason_not_using_federal_id_details || ''}
					width={100}
				/>
			</LayoutBox>
		</ModalContentWrapper>
	);
};
