import { Button, Stack, StandardModal, TextButton } from '@bamboohr/fabric';
import { noop } from 'lodash';
import { FunctionComponent } from 'react';

type Props = {
	handleClose: Function;
	handlePrimaryAction: Function;
	data: {
		contactName: string;
		contactRelationship?: string;
	};
};

const DeleteDependentModal: FunctionComponent<React.PropsWithChildren<Props>> = ({ handleClose = noop, handlePrimaryAction = noop, data }) => {

	return (
        <StandardModal isOpen={true} onRequestClose={() => handleClose()}>
            <StandardModal.Body
				renderFooter={() => (
					<StandardModal.Footer
						actions={[
                            <TextButton key='cancel' onClick={() => handleClose()}>
                                {$.__('Cancel')}
                            </TextButton>,
                            <Button key='confirm' onClick={() => handlePrimaryAction()}>
                                {$.__('Yes, Delete Dependent')}
                            </Button>,
                        ]}
					/>
				)}
				renderHeader={() => <StandardModal.Header title={$.__('Delete Dependent')} />}
			>
				<StandardModal.Constraint>
					<Stack spacing={2}>
						<StandardModal.HeroHeadline
							icon='trash-can-regular'
							iconColor='error-strong'
							text={$.__('Are you sure you want to delete this dependent?')}
						/>
						<div className='Contact__modalContent'>
							<div className='Contact__modalName'>{data.contactName}</div>
							<div className='Contact__modalRelationship'>{data.contactRelationship}</div>
						</div>
					</Stack>
				</StandardModal.Constraint>
			</StandardModal.Body>
        </StandardModal>
    );
};

export default DeleteDependentModal;
