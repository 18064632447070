import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { hasTrax } from 'BambooHR.util';
import {
	getField,
	getSelectedItem,
	isSelectedPSSyncing,
	Fields,
	newEmployeeForm,
	currentEditingEmployeeId,
} from 'dynamic-form';

const SelectPayScheduleField: FunctionComponent<React.PropsWithChildren<DynamicForm.SelectElementProps>> = (selectProps) => {
	const { props, settings, context } = selectProps;
	const { items, selectedValues = [] } = props;
	const { validation, validation: { requiredTrax } } = settings;
	const {
		controls: { SelectField },
		validation: { trigger },
	} = context;

	const note = useRef<DynamicForm.Note>(null);

	const getItems = (): DynamicForm.SelectElementItems => {
		if (items.length === 0) {
			// there are no PS setup, add disabled option
			return [
				{
					text: $.__(`You don't have any pay schedules set up. Add them in settings.`),
					value: '',
					isDisabled: true,
				},
			];
		}
		return items;
	};

	const payrollCheckbox = getField(context, newEmployeeForm.payrollCheckbox) as DynamicForm.CheckboxElement;
	const isPayrollCheckboxChecked = payrollCheckbox?.props?.checked;
	const paidPerField = getField(context, Fields.paidPer) as DynamicForm.SelectElement;
	const payTypeField = getField(context, Fields.payType) as DynamicForm.SelectElement;
	// Pay Schedule needs to be required with trax companies on new employee if the payroll checkbox is checked - fallback to setting
	const isPayrollRequired = (hasTrax() && currentEditingEmployeeId() === 0 && payrollCheckbox?.props?.checked === true) || requiredTrax;

	const [selectItems, setSelectItems] = useState<DynamicForm.SelectElementItems>(getItems());
	useEffect(() => {
		if (hasTrax() && payrollCheckbox && items.length > 0) {
			// use checkbox, not isSelectedPSSyncing because we want to allow user to select non-syncing PS if checkbox is unchecked
			const isChecked = !!payrollCheckbox.props.checked;
			if (isChecked) {
				// hide non-syncing pay schedules
				const filteredItems = items.filter((item) => {
					// if there are no props -> We will just add it to the list
					if (!item.props) {
						return true;
					}

					return item.props['data-syncing-pay-schedule'];
				});
				setSelectItems(filteredItems);
			} else {
				// show all items
				setSelectItems(items);
			}
		}
	}, [isPayrollCheckboxChecked]);

	useEffect(() => {
		if (hasTrax() && isSelectedPSSyncing(context) && paidPerField && payTypeField) {
			const paidPerItem = getSelectedItem(paidPerField);
			const payTypeItem = getSelectedItem(payTypeField);
			if (paidPerItem?.props?.['data-invalid-payroll-option'] || payTypeItem?.props?.['data-invalid-payroll-option']) {
				note.current = {
					text: ' ',
					type: 'PayScheduleWarning',
				};
				trigger([paidPerField?.props?.name, payTypeField?.props?.name]);
			}
		} else {
			note.current = null;
			trigger([paidPerField?.props?.name, payTypeField?.props?.name]);
		}
	}, [isPayrollCheckboxChecked, selectedValues]);

	const selectFieldSettings = {
		...settings,
		note: note.current,
		validation: {
			...validation,
			requiredTrax: isPayrollRequired,
		},
	};

	return (
		<SelectField
			context={ context }
			props={ { ...props, items: selectItems } }
			settings={ selectFieldSettings }
		/>
	);
};

export default SelectPayScheduleField;
