import { useState } from 'react';
import ClockFieldBox from 'time-tracking/modals/shared/components/clock-field-box.react';
import {
	calculateDistanceBetweenPoints,
	formatTime,
} from 'time-tracking/utils';
import { classNameFromObject as co } from '@utils/dom';
import { TextButton, IconV2 } from '@bamboohr/fabric';
import { isEnabled } from 'FeatureToggle.util';
import { Map } from 'map.react';
import { EntryProjectSelector } from '../entry-project-selector.react';

const geolocationEnabled = isEnabled('timeTrackingGeolocation');

export default (props) => {
	const {
		canEdit,
		endTime,
		showRemoveButton,
		id,
		geolocationData: {
			clockInAddress,
			clockInLatitude,
			clockInLongitude,
			clockInLocationAccuracy,
			clockOutAddress,
			clockOutLatitude,
			clockOutLongitude,
			clockOutLocationAccuracy
		},
		handleRemoveButtonClick,
		note,
		onNoteChange,
		overlappingError,
		showNoteInput,
		total,
		type,
	} = props;

	const [showMap, setShowMap] = useState(false);

	const entryClasses = co({
		'AddEditEntry__clocks': true,
		'AddEditEntry__clocks--add': type === 'add'
	});

	const addressExists = !!(clockInAddress || clockOutAddress);
	const clockInLocationErrorExists = clockInLocationAccuracy === -1;
	const clockOutLocationErrorExists = clockOutLocationAccuracy === -1;

	const entryError = (endTime !== '1' && total < 0);
	let slatNote = null;

	if (entryError) {
		slatNote = $.__('Oops, try again');
	} else if (overlappingError) {
		slatNote = $.__('Overlapping entry');
	} else if (total) {
		slatNote = formatTime(total);
	}

	let clockInOutMarkers = [];
	let mapZoom = null;
	if (geolocationEnabled) {
		clockInOutMarkers = [
			{ accuracy: clockInLocationAccuracy, coordinates: { lat: clockInLatitude, lng: clockInLongitude }, label: 'Clock In' },
			{ accuracy: clockOutLocationAccuracy, coordinates: { lat: clockOutLatitude || null, lng: clockOutLongitude || null }, label: 'Clock Out' }
		];

		// If the distance between points is less than 50 meters then we merge the points into one
		const distanceBetweenPoints = calculateDistanceBetweenPoints([clockInLatitude, clockInLongitude], [clockOutLatitude, clockOutLongitude]);
		if ((clockInLatitude && clockInLongitude && clockOutLatitude && clockOutLongitude) && distanceBetweenPoints <= 50) {
			clockInOutMarkers = [
				{ accuracy: clockInLocationAccuracy, coordinates: { lat: clockInLatitude, lng: clockInLongitude }, label: 'Clock In/Out' }
			];
		}

		if (distanceBetweenPoints < 100 && distanceBetweenPoints > 50) {
			mapZoom = 17;
		}
	}

	return (
		<div className={ entryClasses }>
			{ showRemoveButton && (
				<span className="AddEditEntry__removeEntry" onClick={ () => { handleRemoveButtonClick(id); } }>
					<IconV2 name="xmark-solid" size={12} />
				</span>
			) }
			<div className="fieldRow AddEditEntry__fieldRow">
				{ renderClockField(true, props) }
				{ renderClockField(false, props, true) }

				<EntryProjectSelector { ...props } />

				<p className="fab-FormNote AddEditEntry__formNote">{ slatNote }</p>
			</div>

			{ geolocationEnabled && (addressExists || (clockInLocationErrorExists || clockOutLocationErrorExists)) && (
				<div className="AddEditEntry__location">
					<span className="fab-Label">{ $.__('Location') }</span>

					{ geolocationEnabled && (clockInAddress || clockInLocationErrorExists) && (
						<div
							className={ co({
								'AddEditEntry__address': true,
								'AddEditEntry__address--withWarning': clockInLocationErrorExists
							}) }
						>
							<span className="AddEditEntry__address__label">{ $.__('Clocked In Near') }</span>
							{ clockInLocationErrorExists ? getLocationWarningMessage('clock-in') : clockInAddress }
						</div>
					) }

					{ geolocationEnabled && (clockOutAddress || clockOutLocationErrorExists) && (
						<div
							className={ co({
								'AddEditEntry__address': true,
								'AddEditEntry__address--withBorder': true,
								'AddEditEntry__address--withWarning': clockOutLocationErrorExists
							}) }
						>
							<span className="AddEditEntry__address__label">{ $.__('Clocked Out Near') }</span>
							{ clockOutLocationErrorExists ? getLocationWarningMessage('clock-out') : clockOutAddress }
						</div>
					) }

					{ !showMap && (!clockInLocationErrorExists || !clockOutLocationErrorExists) && (
						<TextButton
							clickAction={ () => setShowMap(true) }
							iconAfter="chevrons-down-solid"
							iconBefore="location-dot-solid"
							text={ $.__('Show Map') }
							type="button"
						/>
					) }

					{ showMap && (!clockInLocationErrorExists || !clockOutLocationErrorExists) && (
						<Map
							markers={ clockInOutMarkers }
							height="291px"
							width="396px"
							zoom={ mapZoom }
						/>
					) }
				</div>
			) }

			{ showNoteInput && (
				<textarea
					autoFocus={ showNoteInput }
					className="AddEditEntry__noteInput fab-Textarea"
					cols="55"
					defaultValue={ note }
					disabled={ !canEdit }
					maxLength="200"
					onChange={ (event) => { onNoteChange(event, id); } }
					placeholder={ $.__('Add note...') }
					rows="2"
					type="text"
				>
				</textarea>
			) }
		</div>
	);
}

function renderClockField(isStart, props, hasNote) {
	const {
		canEdit,
		startTime,
		startMeridiem,
		endTime,
		endMeridiem,
		id,
		total,
		handleInputBlur,
		handleInputChange,
		handleSelectChange,
		overlappingError,
	} = props;

	const label = isStart ? $.__('Start Time') : $.__('End Time');
	const timeVal = isStart ? startTime : endTime;
	const meridiem = isStart ? startMeridiem : endMeridiem;
	const selectProps = {
		isDisabled: !canEdit,
		isClearable: false,
		items: [
			{
				text: 'AM',
				value: 'AM'
			},
			{
				text: 'PM',
				value: 'PM'
			}
		],
		selectedValues: [
			meridiem
		],
		onSelect: (value) => {
			handleSelectChange(value, isStart, id);
		}
	};
	const entryError = (endTime !== '1' && total < 0);
	// let displayText = null;

	// if (entryError) {
	// 	displayText = $.__('Oops, try again');
	// } else if (overlappingError) {
	// 	displayText = $.__('Overlapping entry');
	// } else if (total) {
	// 	displayText = formatTime(total);
	// }

	const clockFieldBoxProps = {
		disabled: !canEdit,
		label,
		timeVal,
		selectProps,
		error: entryError || overlappingError,
		// formNote: {
		// 	show: !isStart,
		// 	displayText
		// },
		hasNote,
		autoFocus: isStart,
		handleInputChange: (event) => {
			handleInputChange(event.target.value, isStart, id);
		},
		handleInputBlur: (event) => {
			handleInputBlur(event.target.value, isStart, id);
			event.target.setAttribute('placeholder', '');
		},
		handleInputFocus: (event) => {
			event.target.setAttribute('placeholder', 'hh:mm');
			event.target.select();
		}
	};

	return <ClockFieldBox { ...clockFieldBoxProps } />;
}

function getLocationWarningMessage(type) {
	const clockInOrOut = type === 'clock-in' ? 'in' : 'out';
	return (
		<div className="AddEditEntry__location__warningMessage">
			<span className="AddEditEntry__location__warningMessage__icon">
				<IconV2 name="triangle-exclamation-solid" size={16} />
			</span>
			<p className="AddEditEntry__location__warningMessage__text">{ $.__(`There wasn’t a strong signal where they clocked %1, so we can’t show it on the map.`, clockInOrOut) }</p>
		</div>
	);
}
