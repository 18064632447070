import { FunctionComponent } from 'react';
import './note-pay-schedule.styl';

const PayScheduleNote: FunctionComponent<React.PropsWithChildren<DynamicForm.NoteElementProps>> = () => {

	return (
		<div className="DynamicForm--PayScheduleNote">
			<div className="fab-FormNote CompensationForm__warningNote">{ $.__('This pay schedule isn’t compatible with a couple other fields.') }</div>
			<div className="fab-FormNote">{ $.__('Make sure to also update the fields highlighted in red.') }</div>
		</div>
	);
};

export default PayScheduleNote;
