import {
	isArray,
	isEmpty,
	noop,
} from 'lodash';
import {
	useEffect,
	useState,
} from 'react';

import { ifFeature } from '@bamboohr/utils/lib/feature';
import {
	Button,
} from '@fabric/button';
import {
	BEM,
} from '@utils/dom';
import {
	showSlidedown,
} from 'Alerts.mod';
import {
	printDiv,
} from 'BambooHR.util';

import {
	doGetBackupCodes,
	doGetNewBackupCodes,
} from '../../service';
import {
	BackupCodes,
} from './backup-codes.react';
import {
	ERROR_MESSAGE,
	HEADLINE,
} from './constants';

import './styles.styl';

const bem = new BEM('BackupCodesContent');
const printWrapperClassName = bem.elem('printWrapper');

export function BackupCodesContent(props) {
	const {
		onEndGeneratingNewCodes = noop,
		onStartGeneratingNewCodes = noop,
		printRequestCount = 0,
		userId = window.SESSION_USER.id,
	} = props;

	const [backupCodes, setBackupCodes] = useState(null);
	const [date, setDate] = useState(null);
	const [isProcessing, setIsProcessing] = useState(false);

	const backupCodesRequest = () => {
		const request = doGetNewBackupCodes;
		setIsProcessing(true);
		onStartGeneratingNewCodes();

		request(userId)
			.then((resp) => {
				const {
					data,
				} = resp;

				if (isArray(data) && !isEmpty(data)) {
					setBackupCodes(data);
					setDate(data[0].dateGenerated);
					onEndGeneratingNewCodes(data);
				} else {
					onEndGeneratingNewCodes();
				}
			})
			.catch(() => {
				onEndGeneratingNewCodes();
				showSlidedown(ERROR_MESSAGE, 'error');
			})
			.finally(() => {
				setIsProcessing(false);
			})
	}

	useEffect(() => {
		backupCodesRequest();
	}, []);

	useEffect(() => {
		if (printRequestCount > 0) {
			printDiv(`.${ printWrapperClassName }`);
		}
	}, [printRequestCount]);

	return (
		<div className={ printWrapperClassName }>
			<h4 className={ bem.elem('printHeader') }>
				{ HEADLINE }
			</h4>
			<div className={ bem.elem('copy') }>
				<p>
					{
						ifFeature('encore', $.__("If you lose your phone or otherwise can't get codes via SMS or an Authenticator, you can use backup codes to sign in with 2-Step Login."), $.__("If you lose your phone or otherwise can't get codes via an Authenticator, you can use backup codes to sign in with 2-Step Login."))
					}
				</p>
				<br />
				<p>
					{
						$.__("After you use a backup code to sign in, it becomes inactive.")
					}
				</p>
				<p>
					{
						$.__("You can generate a new set of 10 codes whenever you want. After creating a new set, the old set automatically becomes inactive.")
					}
				</p>
			</div>

			{
				backupCodes && (
					<div>
						<BackupCodes
							codes={ backupCodes }
							date={ date }
						/>

						<div className={ bem.elem('buttonWrapper') }>
							<Button
								clickAction={ () => backupCodesRequest() }
								iconBefore={ifFeature('encore', 'arrow-rotate-right-regular', "fab-circle-arrow-16x16")}
								isDisabled={ isProcessing }
								outline={ true }
								secondary={ true }
								text={ $.__('Generate New Codes') }
							/>
						</div>
					</div>
				)
			}

		</div>
	);
}
