import { useEffect, useState } from 'react';
import { BodyText, Checkbox, Flex, LayoutBox } from '@bamboohr/fabric';
import {
	CancellationContentProps,
	CancellationType,
	PayrollFormData,
} from '../../types';
import { ModalContentWrapper } from '../modal-content-wrapper';
import getHasDiscountPackage from '../../../../util/getHasDiscountPackage';

interface Step3FormValues {
	ack_1_response: PayrollFormData['ack_1_response'];
}

export const PayrollCancellationFormStep3 = ({
	formValues: currentFormState,
	reasons,
	onNext,
	types,
	...rest
}: CancellationContentProps): JSX.Element => {
	const [ack2Checked, setAck2Checked] = useState(false);
	const [showDiscountCheckbox, setShowDiscountCheckbox] = useState(false);
	const [acknowledge, setAcknowledge] = useState(false);
	const [nextIsDisabled, setNextIsDisabled] = useState(true);

	const [formValues, setFormValues] = useState<Step3FormValues>({
		ack_1_response: false,
	});

	const handleOnChange = (values: Partial<Step3FormValues>) => {
		setFormValues({ ...formValues, ...values });
	};

	const handleAck2Change = (value: boolean) => {
		setAck2Checked(value);
	};

	const quarterSelection = currentFormState?.payroll_data?.pay_through_quarter;
	const currentYear = new Date().getFullYear();
	const yearSelection = currentFormState?.payroll_data?.pay_through_year;
	const usingFederalId =
		currentFormState?.payroll_data?.use_federal_id_after_final_date;

	const secondAckOptions = {
		quarterYes: $.__(
			'I understand that taxes will be filed and paid through quarter %s %s. If any payrolls have been processed after quarter %s %s, unemployment funds will be returned to your bank account once the account reconciliation is complete. Year %s W2s and annual filings WILL NOT be done by BambooHR.',
			quarterSelection,
			currentYear,
			quarterSelection,
			currentYear,
			currentYear,
		),
		quarterNo: $.__(
			'If you are no longer planning on processing payroll using this Federal ID, you should select quarter 4 on step 2 of this form unless you are planning to file W2s and annual fiIings yourself. Taxes will be filed and paid through quarter %s %s. If any payrolls have been processed after quarter %s %s, unemployment funds will be returned to your bank account once the account reconciliation is complete. Year %s W2s and annual filings WILL NOT be done by BambooHR.',
			quarterSelection,
			currentYear,
			quarterSelection,
			currentYear,
			currentYear,
		),
		year: $.__(
			'I understand that taxes will be filed and paid through quarter 4 %s. Year %s W2s and annual filings WILL be done by BambooHR.',
			yearSelection,
			yearSelection,
		),
	};

	const handleOnNext = () => {
		onNext({ payroll_data: formValues });
	};

	useEffect(() => {
		if (!types || types.includes(CancellationType.ACCOUNT)) {
			setShowDiscountCheckbox(false);
			return;
		}

		const fetchHasPackage = async () => {
			// Are we on the discount package?
			const hasDiscountPackage = await getHasDiscountPackage();
			setShowDiscountCheckbox(hasDiscountPackage);
		};

		fetchHasPackage();
	}, []);

	useEffect(() => {
		if (showDiscountCheckbox) {
			setNextIsDisabled(
				!acknowledge || !formValues.ack_1_response || !ack2Checked,
			);
		} else {
			setNextIsDisabled(!formValues.ack_1_response || !ack2Checked);
		}
	}, [showDiscountCheckbox, acknowledge, formValues, ack2Checked]);

	return (
		<ModalContentWrapper
			{...rest}
			disablePrimaryButton={nextIsDisabled}
			onNext={handleOnNext}
		>
			<LayoutBox marginBottom={3}>
				<BodyText>
					{$.__('Please read through and check the following statements:')}
				</BodyText>
			</LayoutBox>
			<Flex rowGap={3} flexDirection={'column'}>
				<Checkbox
					label={reasons.payroll_ack_1_option?.[0]?.text || ''}
					onChange={(e) => handleOnChange({ ack_1_response: e.checked })}
					value={reasons.payroll_ack_1_option?.[0]?.value || ''}
					checked={formValues.ack_1_response}
				/>
				{quarterSelection == '4' && (
					<Checkbox
						label={secondAckOptions.year}
						onChange={(e) => handleAck2Change(e.checked)}
						value={''}
						checked={ack2Checked}
					/>
				)}
				{usingFederalId == 'yes' && quarterSelection != '4' && (
					<Checkbox
						label={secondAckOptions.quarterYes}
						onChange={(e) => handleAck2Change(e.checked)}
						value={''}
						checked={ack2Checked}
					/>
				)}
				{usingFederalId == 'no' && quarterSelection != '4' && (
					<Checkbox
						label={secondAckOptions.quarterNo}
						onChange={(e) => handleAck2Change(e.checked)}
						value={''}
						checked={ack2Checked}
					/>
				)}
				{showDiscountCheckbox && (
					<Checkbox
						value="cancellation-discount-checkbox"
						onChange={() => {
							setAcknowledge(!acknowledge);
						}}
						checked={acknowledge}
						biId="cancellation-discount-checkbox"
						id="cancellation-discount-checkbox"
						name="cancellation-discount-checkbox"
						label={$.__(
							'I acknowledge that cancelling this feature will remove my 15%% bundle discount.',
						)}
					/>
				)}
			</Flex>
		</ModalContentWrapper>
	);
};
