import { FunctionComponent } from 'react';

import ObfuscatedPayrollField from '../fields/payroll/shared-obfuscated';
import TaxDocumentDelivery from '../fields/payroll/tax-document-delivery';


const TaxDocumentDeliveryFieldSet: FunctionComponent<React.PropsWithChildren<DynamicForm.FieldSetElementProps>> = (fieldSetProps) => {
	const {
		props,
		children,
		settings,
	} = fieldSetProps;
	const { className = '' } = props;

	return (
		<fieldset { ...props } className={ `fab-FormSection ${ className }` }>
			{ children }
			{ settings.isObfuscated === true && (
				<ObfuscatedPayrollField />
			) }
			{ settings.isObfuscated !== true && (
				<TaxDocumentDelivery />
			) }
		</fieldset>
	);
};

export default TaxDocumentDeliveryFieldSet;
