import { Dropdown } from '@fabric/dropdown';

const dropdownOptions = [
	{
		key: 'showWeekends',
		text: $.__('Weekends'),
	},
	{
		key: 'showDailyGraph',
		text: $.__('Daily Graph'),
	},
	{
		key: 'showPayPeriodTotal',
		text: $.__('Pay Period Total'),
	},
];

export default function OptionDropdown(props) {
	const { timesheetOptions, changeViewOption } = props;
	if (!timesheetOptions || !changeViewOption) {
		return null;
	}

	const items = [{
		text: $.__('Show'),
		type: 'group',
		items: [{
			isDisabled: true, // disabled so that the brand color hover state is removed and just the checkboxes are interactive
			text: '',
			value: '',
			render: () => {
				return (
					<div className="fab-CheckboxGroup">
						{ dropdownOptions.map((option) => {
							const id = `timesheetOptionDropdown-${ option.key }`;

							return (
								<div key={ option.key } className="fab-Checkbox">
									<input
										checked={ timesheetOptions[option.key] }
										className="fab-Checkbox__input"
										id={ id }
										onChange={ () => changeViewOption(option.key, !timesheetOptions[option.key]) }
										readOnly={ true }
										type="checkbox"
									/>
									<label className="fab-Checkbox__label" htmlFor={ id }>{ option.text }</label>
								</div>
							);
						}) }
					</div>
				);
			},
		}],
	}];
	return (
		<div className="TimesheetOptionDropdown">
			<Dropdown
				ButtonProps={ { icon: 'gear-regular', secondary: true, outline: true, size: 'small' } }
				items={ items }
				renderOptionContent={ item => (item.render ? item.render() : item.text) }
			/>
		</div>
	);
}
