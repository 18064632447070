import { Button, CardSize, makeStyles, SelectField, StandardModal, TextButton, Typography } from '@bamboohr/fabric';
import {
	ExternalLink,
	Message
} from '@bamboohr/utils/lib/message';
import { ReactElement } from 'react';
import { StatusMismatchUpdateStatusStepProps } from '../types';

const useStyles = makeStyles(({ spacing }) => ({
	heading: {
		marginTop: spacing(3.5),
	}
}));

export function UpdateStatusStep({ employee, onCancel, onNext, onPrevious, renderHeader }: StatusMismatchUpdateStatusStepProps): ReactElement {
	const classes = useStyles();

	const top = StandardModal.Constraint.Spacing.LARGE;
	const bottom = StandardModal.Constraint.Spacing.LARGE;

	return (
        <StandardModal.Body
			renderFooter={
				<StandardModal.Footer actions={
					[
                        <TextButton key="cancel" onClick={ onCancel }>{ $.__('Cancel') }</TextButton>,
                        <Button color="secondary" key="previous" onClick={ () => onPrevious() } variant="outlined">{ $.__('Previous') }</Button>,
                        <Button key="next" onClick={ onNext }>{ $.__('Save Status') }</Button>,
                    ]
				} />
			}
			renderHeader={ renderHeader }
			size={ CardSize.MEDIUM }
		>
            <StandardModal.Constraint spacingOverrides={ { top, bottom } }>
				<StandardModal.HeroHeadline
					hasSeparator={ true }
					icon='person-circle-question-regular'
					subtext={
						<Message
							link={ ExternalLink('mailto: support@bamboohr.com') }
							text={
								$._(`Soon, inactive employees who are employed will be limited in what they can do in BambooHR. We're hoping to help you get ahead of that. Please reach out to [support@bamboohr.com] with any questions.`)
							}
						/>
					}
					text={ $.__('Great! In that case %1 should be set to Active.', employee.preferredName)}
				/>
				<Typography className={ classes.heading } color="primary" variant="h5">{ $.__('Update Status') }</Typography>
				<SelectField
					disabled={ true }
					id="statusMismatchStatus"
					items={ [{ text: $.__('Active'), value: 'active' }] }
					label={ $.__('Status') }
					value={['active']}
					width={ 7 }
				/>
			</StandardModal.Constraint>
        </StandardModal.Body>
    );
}
