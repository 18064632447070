import { Component } from 'react';
import moment from 'moment.lib';
import { classNameFromObject } from '@utils/dom';
import WrapIf from 'wrap-if.react';
import { BodyText, Button, ButtonGroup, Dropdown, Flex, Headline, Icon, IconButton, IconV2, LayoutBox, Tooltip } from '@bamboohr/fabric';

import { consume } from '../../store/context';
import { CLOCK_DISABLED_FUTURE_ENTRY } from 'time-tracking/constants';
import {
	lastActionText,
	clockButtonText,
	formatTime,
	getPaidHolidayText,
	forgotToClockOut,
	getHasFutureEntry,
	convertNormalizedProjectsToDropdownItems,
	getProjectAndTaskId,
} from 'time-tracking/utils';
import TimeSpinner from 'time-tracking/components/time-spinner.react';
import ChooseClockOutDateModal from 'time-tracking/modals/choose-clock-out-date';
import ForgotClockOutWarningModal from 'time-tracking/modals/forgot-clock-out-warning';
import AddNoteModal from 'time-tracking/modals/add-note';
import { isEnabled } from 'FeatureToggle.util';

const isAnybodyClockEnabled = isEnabled('anybodyClock');
const geolocationEnabled = isEnabled('timeTrackingGeolocation');

export class ClockInOut extends Component {
	constructor(props) {
		super(props);
		this.userCanEdit = props.workflow.userCanEdit || false;

		this.state = {
			requesting: false,
			preventingDoubleClick: false,
		};
	}

	_toggleClocking = (projectId, taskId) => {
		const { preventingDoubleClick } = this.state;
		const { clock, clockIn, clockOut, employee, refreshTimesheet } = this.props;
		const forgotClockOut = clock ? forgotToClockOut(clock.latest) : false;

		if (this.userCanEdit && forgotClockOut && clock.latest) {
			ChooseClockOutDateModal(employee.id, clock.latest)
				.then(refreshTimesheet);
			return;
		} if (!this.userCanEdit && forgotClockOut) {
			ForgotClockOutWarningModal(clockOut, employee.id, () => { this._toggleRequesting(); })
				.then(() => {
					this._toggleRequesting();
				});
			return;
		}

		if (preventingDoubleClick) {
			return;
		}

		if (window.ASSUMED_USER) {
			window.disabledForPermsTest();
			return;
		}

		this._preventDoubleClick();
		this._toggleRequesting();

		const clockInOrOut = (clock.clockedIn ? clockOut : clockIn);
		clockInOrOut(employee.id, projectId, taskId)
			.finally(this._toggleRequesting);
	};

	_toggleRequesting = () => {
		this.setState({
			requesting: !this.state.requesting,
		});
	};

	_preventDoubleClick = () => {
		this.setState({ preventingDoubleClick: true });
		setTimeout(() => {
			this.setState({ preventingDoubleClick: false });
		}, 400);
	};

	_handleAddNoteClick = () => {
		const { clock, employee, refreshTimesheet, workflow, isViewOnly, projectsWithTasks } = this.props;

		const projectData = {
			projectId: clock.latest.projectId,
			projectName: clock.latest.projectName,
			taskId: clock.latest.taskId,
			taskName: clock.latest.taskName,
		};

		const noteModalData = {
			canEdit: workflow.userCanEdit,
			clock,
			employeeId: employee.id,
			note: clock.latest.note,
			projectData,
			projectsWithTasks,
			today: moment.tz(clock.latest.start, clock.latest.timezone),
			timezone: clock.latest.timezone,
			type: 'clock',
			isViewOnly,
		};
		AddNoteModal(noteModalData)
			.then(refreshTimesheet);
	};

	_handleProjectDropdownClick = (value) => {
		const [projectId, taskId] = getProjectAndTaskId(value);
		this._toggleClocking(projectId, taskId);
	};

	_hasFutureEntry() {
		const {
			clock: { latest },
			serverTime,
		} = this.props;
		return getHasFutureEntry(latest, serverTime);
	}

	_renderClockButton() {
		const {
			clock,
			clock: { clockedIn, latest },
		} = this.props;
		const { requesting } = this.state;

		const clockIcon = clockedIn ? 'circle-stop-solid' : 'stopwatch-regular';
		const hasFutureEntry = this._hasFutureEntry();
		const forgotClockOut = clock ? forgotToClockOut(latest) : false;
		let biIdClockState = clockedIn ? 'clock-out' : 'clock-in';

		if (forgotClockOut) {
			biIdClockState = 'verify-and-clock-out';
		}

		return(
			<Button
				data-bi-id={`my-info-timesheet-${biIdClockState}-button`}
				startIcon={clockIcon}
				disabled={requesting || hasFutureEntry}
				onClick={() => this._toggleClocking()}
				processing={requesting}
				type='button'
				width={100}
			>
				{clockButtonText(clock.clockedIn, requesting, forgotClockOut, this.userCanEdit)}
			</Button>
		);
	}

	_renderProjectTrackingButton() {
		const {
			allowClockActions,
			clock,
			isOwnTimesheet,
			projectsWithTasks,
		} = this.props;
		const { requesting } = this.state;

		const dropdownItems = convertNormalizedProjectsToDropdownItems(projectsWithTasks);
		const hasFutureEntry = this._hasFutureEntry();
		
		if( isEnabled('TIME_TRACKING_WEB_CLOCK_IN') && allowClockActions === false ){
			return null;
		}

		return (
			<WrapIf
				condition={ isOwnTimesheet && hasFutureEntry }
				wrap={(content) => <Tooltip content={CLOCK_DISABLED_FUTURE_ENTRY}>{content}</Tooltip>}
			>
				<Flex alignItems='center' alignSelf='stretch' gap={1} marginTop={1.75}>
					{this._renderClockButton()}
					{clock.clockedIn && (
						<IconButton
							ariaLabel={$.__('Add note')}
							color='secondary'
							icon='file-plus-regular'
							onClick={this._handleAddNoteClick}
							type='button'
						/>
					)}
					{!clock.clockedIn && projectsWithTasks.allIds.length > 0 ? (
						<Dropdown
							ButtonProps={{
								ariaLabel: $.__('Clock in to project or task'),
								color: 'secondary',
								processing: requesting,
							}}
							isDisabled={requesting || hasFutureEntry}
							items={dropdownItems}
							onSelect={this._handleProjectDropdownClick}
							showSearch='auto'
							type='icon'
						/>
					) : null}
				</Flex>
			</WrapIf>
		);
	}

	render() {
		const {
			clock,
			focusedTimesheet,
			getToday,
			serverTime,
			isOwnTimesheet,
		} = this.props;

		// Geolocation data
		const {
			clockInLatitude,
			clockInLongitude,
			clockOutLatitude,
			clockOutLongitude,
		} = clock.latest || {};

		const locationExists = !!((clockInLatitude && clockInLongitude) || (clockOutLatitude && clockOutLongitude));
		const today = getToday();
		const { holidayPayType } = focusedTimesheet;
		const { showEditActions } = focusedTimesheet;
		const forgotClockOut = clock ? forgotToClockOut(clock.latest) : false;
		const spinnerFillColor = forgotClockOut ? 'orange' : null;

		const holidays = today.holidays.map((holiday) => {
			if (holiday.paidHours !== null) {
				const holidayMessage = getPaidHolidayText(holiday);
				return <BodyText key={holiday.name}>{holidayMessage}</BodyText>;
			}
		});
		const hasHolidays = holidays.length > 0;

		const todayClockTotalClasses = classNameFromObject({
			TimesheetSummary__todayClockTotal: true,
			'TimesheetSummary__todayClockTotal--withHoliday': holidayPayType === 'normal' && hasHolidays,
		});

		const todayClockActiveClasses = classNameFromObject({
			TimesheetSummary__todayClockActive: true,
			'TimesheetSummary__todayClockActive--withHoliday': holidayPayType === 'normal' && hasHolidays,
			'TimesheetSummary__todayClockActive--forgotClockOut': forgotClockOut,
		});

		const lastActionTextClasses = classNameFromObject({
			TimesheetSummary__text: true,
			'TimesheetSummary__text--clock': true,
			'TimesheetSummary__text--forgotClockOut': forgotClockOut,
		});

		const noteClasses = classNameFromObject({
			TimesheetSummary__note: true,
			'TimesheetSummary__note--clockedIn': clock.clockedIn,
		});

		return(
			<Flex alignItems='center' flexDirection='column' gap={1.25} textAlign='center'>
				<BodyText size='large' weight='bold'>
					{clock.clockedIn ? $.__('Clocked In') : $.__('Not Clocked In')}
				</BodyText>
				{clock.clockedIn ? (
					<Headline size='medium'>
						<Flex alignItems='center'>
							<BodyText color={forgotClockOut ? 'warning' : 'success'}>
								{formatTime(clock.earned + clock.midnightTotals)}
							</BodyText>
							<TimeSpinner fillColor={spinnerFillColor} />
						</Flex>
					</Headline>
				) : (
					<Headline color='primary' size='medium'>
						{$.__('%1$s Today', formatTime(today.hours || 0))}
					</Headline>
				)}
				{clock.latest || clock.clockedIn ? (
					<Flex alignItems='center' gap={0.75} justifyContent='center'>
						<BodyText color='neutral-strong' size='small'>
							{clock.latest && lastActionText(clock.latest, serverTime)}
						</BodyText>
						{clock.clockedIn && clock.latest.note && (
							<IconV2 color='neutral-medium' name='file-solid' size={12} />
						)}
						{geolocationEnabled && clock.clockedIn && locationExists && (
							<IconV2 color='neutral-medium' name='location-dot-solid' size={12} />
						)}
					</Flex>
				) : null}
				{holidayPayType === 'normal' && holidays.length > 0 && holidays}
				{(isOwnTimesheet || (isAnybodyClockEnabled && showEditActions)) && this._renderProjectTrackingButton()}
			</Flex>
		);
	}
}


export default consume([
	// Properties
	'allowClockActions',
	'employee',
	'isOwnTimesheet',
	'clock',
	'serverTime',
	'focusedTimesheet.holidayPayType',
	'focusedTimesheet.showEditActions',
	'workflow.userCanEdit',
	'isViewOnly',
	'projectsWithTasks',
	// Actions
	'clockIn',
	'clockOut',
	'refreshTimesheet',
	// Queries
	'getToday',
], ClockInOut);
