import {
	ChangeEvent,
	createRef,
	DragEvent,
	PureComponent,
	ReactElement,
} from 'react';

import {
	Box,
	Flex,
	IconButton,
	IconV2,
	LayoutBox,
	RequiredToggle,
	TextField
} from '@bamboohr/fabric';

import { Question } from '../../../Interfaces';

import './styles.styl';

interface Props {
	canDelete: boolean;
	focusInput: boolean;
	onDelete: (id: number | string) => void;
	position: number;
	onDragEnd: (e: DragEvent<HTMLDivElement>, id: number | string) => void;
	onDragOver: (e: DragEvent<HTMLDivElement>, id: number | string) => void;
	onDragStart: (e: DragEvent<HTMLDivElement>, id: number | string) => void;
	onUpdate: (
		index: number,
		question: string,
		is_required: '' | 'no' | 'yes',
	) => void;
	questionData: Question;
}

export class GtkyQuestion extends PureComponent<Props, Question> {
	private inputRef = createRef<HTMLInputElement>();

	constructor(props: Props) {
		super(props);

		this.state = {
			...props.questionData,
			is_required: props.questionData.required ? 'yes' : 'no',
		};

		this._handleQuestionInputChange =
			this._handleQuestionInputChange.bind(this);
		this.focus = this.focus.bind(this);
	}

	componentDidMount(): void {
		if (this.props.focusInput) {
			this.focus();
		}
	}

	_handleQuestionInputChange(event: ChangeEvent): void {
		const { position, onUpdate } = this.props;
		const target = event.target as HTMLInputElement;

		this.setState(
			{
				question: target.value,
			},
			() => {
				const { question, is_required } = this.state;
				return onUpdate?.(position, question, is_required);
			},
		);
	}

	_handleRequiredInputChange(isRequired: boolean): void {
		const { position, onUpdate } = this.props;

		this.setState(
			{
				is_required: isRequired ? 'yes' : 'no',
				required: isRequired,
			},
			() => {
				const { is_required, question } = this.state;
				return onUpdate?.(position, question, is_required);
			},
		);
	}

	focus(): void {
		const node = this.inputRef.current;

		if (node) {
			node.focus();
		}
	}

	render(): ReactElement {
		const {
			canDelete,
			onDelete,
			onDragEnd: handleOnDragEnd,
			onDragOver: handleOnDragOver,
			onDragStart: handleOnDragStart,
			position,
			questionData,
		} = this.props;

		const { id } = questionData;

		const {
			is_required: isRequired,
			sortOrder,
			question,
			required,
		} = this.state;

		return (
			<LayoutBox
				draggable={true}
				id={id ? `cqWrapper-${id}` : null}
				onDragOver={(e) => {
					handleOnDragOver(e, id);
				}}
				onDragStart={(e) => {
					handleOnDragStart(e, id);
				}}
				onDrop={(e) => {
					handleOnDragEnd(e, id);
				}}
			>
				<Flex className="fab-InputWrapper GTKYQuestion">
					<TextField
						data-order={sortOrder}
						InputProps={{
							'startAdornment': <Box className="fab-TextInputNub GTKYQuestion__dragHandle" style={{ 'position': 'initial'}}>
								<IconV2 name="grip-dots-vertical-regular" size={16} />
							</Box>
						}}
						name={`employeePersonalQuestions[${id}]`}
						onChange={this._handleQuestionInputChange}
						placeholder={$.__('Enter Question')}
						ref={this.inputRef}
						value={question}
						width={10}
					/>
					<LayoutBox marginX={'10px'} marginTop={'3px'}>
						<RequiredToggle
							ariaLabel={$.__('Required Employee Personal Question Toggle - %s', id)}
							id={id ? `q${id}Req` : null}
							isChecked={isRequired === 'yes' || required}
							name={`employeePersonalQuestionsRequired[${id}]`}
							onChange={(event) => {
								const { checked } = event.target as unknown as HTMLInputElement;

								this._handleRequiredInputChange(checked);
							}}
						/>
					</LayoutBox>
					{canDelete && (
						<IconButton
							floatingIcon={true}
							icon="circle-xmark-regular"
							onClick={() => {
								return onDelete?.(position);
							}}
							size="small"
							type="button"
						/>
					)}
				</Flex>
			</LayoutBox>
		);
	}
}
