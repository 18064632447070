import React, { useRef } from 'react';
import {
	Tooltip,
	BodyText,
	LayoutBox,
	TextButton,
	IconV2,
} from '@bamboohr/fabric';

export const EmployeeTeamsInfo = ({ teams }: { teams: string[] }) => {
	const hasMoreThanOneTeam = teams.length > 1;
	const tooltipRef = useRef<HTMLButtonElement>(null);

	return (
		<LayoutBox paddingBottom="10px">
			<BodyText
				color="neutral-weak"
				icon={<IconV2 color="neutral-strong" name="users-regular" size={16} />}
			>
				{`${teams[0]}, `}
				{hasMoreThanOneTeam && (
					<>
						<TextButton ref={tooltipRef} inline>
							{$.__('  +%s more', teams.length - 1)}
						</TextButton>
						<Tooltip
							anchorRef={tooltipRef}
							content={teams.slice(1).join(', ')}
							placement="bottom"
						/>
					</>
				)}
			</BodyText>
		</LayoutBox>
	);
};
