import { Button, CardSize, makeStyles, SelectableBox, SelectableBoxGroup, StandardModal, TextButton } from '@bamboohr/fabric';
import { ReactElement } from 'react';
import { StatusMismatchEmployedStepProps } from '../types';

const useStyles = makeStyles(({ spacing }) => ({
	boxes: {
		alignItems: 'flex-start',
		display: 'flex',
		gap: spacing(2),
		justifyContent: 'center',
		marginTop: spacing(2),
	},
	boxIcon: {
		marginTop: -1,
	}
}));

export function EmployedStep({ companyName, employed, employee, onCancel, onChange, onNext, renderHeader }: StatusMismatchEmployedStepProps): ReactElement {
	const classes = useStyles();

	return (
        <StandardModal.Body
			renderFooter={
				<StandardModal.Footer actions={[
					<TextButton key="cancel" onClick={ onCancel }>{ $.__('Cancel') }</TextButton>,
					<Button key="next" onClick={ () => onNext() }>{ $.__('Next') }</Button>,
				]} />
			}
			renderHeader={ renderHeader }
			size={ CardSize.MEDIUM }
		>
            <StandardModal.Constraint spacingOverrides={ { top: StandardModal.Constraint.Spacing.LARGE } }>
				<StandardModal.HeroHeadline
					icon='briefcase-regular'
					subtext={ $.__('Either way, we should update some things to make sure your data is sparkling clean.') }
					text={ $.__('Is %1 %2 still employed by %3?', employee.preferredName, employee.lastName, companyName)}
				/>
				<SelectableBoxGroup type="radio">
					<div className={ classes.boxes }>
						<SelectableBox
							boxWidth='334px'
							description={ $.__('%1 is actively working here and being paid.', employee.preferredName) }
							icon='circle-check-solid'
							inputId="statusMismatchYes"
							isChecked={ employed === true }
							name="isStillEmployed"
							onChange={ () => onChange(true) }
							title={ $.__('%1 Is Still Employed', employee.preferredName)}
							type="radio"
							value="yes"
						/>
						<SelectableBox
							boxWidth='334px'
							description={ $.__('%1 no longer works here, and is not currently on our payroll.', employee.preferredName) }
							icon='circle-x-solid'
							inputId="statusMismatchNo"
							isChecked={ employed === false }
							name="isStillEmployed"
							onChange={ () => onChange(false) }
							title={ $.__('%1 Is Not Employed', employee.preferredName)}
							type="radio"
							value="no"
						/>
					</div>
				</SelectableBoxGroup>
			</StandardModal.Constraint>
        </StandardModal.Body>
    );
}
