import {
	Flex,
	LayoutBox,
	SelectableBox,
	SelectableBoxGroup,
} from '@bamboohr/fabric';
import { useEffect, useState } from 'react';

import { isToday } from '@bamboohr/utils/lib/datetime';

export const UpdateOrCorrectHistoryTableSection = ({
	updateFormData,
	rowDisplayType,
	selectedBoxDefault = '',
}: {
	updateFormData: (isNewEntry: boolean) => void;
	rowDisplayType: string;
	selectedBoxDefault: string;
}) => {
	const [selectedBox, setSelectedBox] = useState<string>(selectedBoxDefault);
	const compensationForm = document.querySelector('#historyEdit');
	const effectiveDateValue = (
		compensationForm.querySelector('input[name=ymd]') as HTMLInputElement
	)?.value;
	const employeeFirstName = window.Employee?.preferredName;
	const biId = 'employee-addEntryModal_';

	function onSelectableBoxChange(newSelectedBoxValue: string) {
		setSelectedBox(newSelectedBoxValue);

		if (newSelectedBoxValue === 'update') {
			updateFormData(true);
		} else {
			updateFormData(false);
		}
	}

	useEffect(() => {
		// Update the selected box value on first render based on rowDisplayType and date
		if (rowDisplayType && !selectedBox) {
			const effectiveDate = new Date(effectiveDateValue);
			const isStartDateToday = effectiveDate ? isToday(effectiveDate) : false;

			if (
				rowDisplayType === 'past' ||
				rowDisplayType === 'future' ||
				(rowDisplayType === 'current' && isStartDateToday)
			) {
				setSelectedBox('correction');
				updateFormData(false);
			} else {
				setSelectedBox('update');
				updateFormData(true);
			}
		}
	}, []);

	return (
        <LayoutBox marginBottom={3}>
            <SelectableBoxGroup type="radio">
				<Flex
					columnGap={'15px'}
					flexDirection={'row'}
				>
					<SelectableBox
						biId={biId + 'new_Compensation-button'}
						description={$.__(
							'You’re adding a new entry in %1$s’s Compensation history.',
							employeeFirstName,
							{
								note: 'You’re adding a new entry in Julie’s Compensation history.',
							},
						)}
						icon='rectangle-history-circle-plus-solid'
						inputId={'update'}
						isChecked={selectedBox === 'update'}
						key={'update'}
						name={'update'}
						onChange={(value) => onSelectableBoxChange(value)}
						title={$.__('This is an update')}
						value={'update'}
					/>

					<SelectableBox
						biId={biId + 'correction_Compensation-button'}
						description={$.__(
							'You’re fixing a mistaken entry in %1$s’s Compensation history.',
							employeeFirstName,
							{
								note: 'You’re fixing a mistaken entry in Julie’s Compensation history.',
							},
						)}
						icon='broom-wide-solid'
						inputId={'correction'}
						isChecked={selectedBox === 'correction'}
						key={'correction'}
						name={'correction'}
						onChange={(value) => onSelectableBoxChange(value)}
						title={$.__('This is a correction')}
						value={'correction'}
					/>
				</Flex>
			</SelectableBoxGroup>
        </LayoutBox>
    );
};
