import { Component } from 'react';
import moment from 'moment.lib';
import { round, startsWith, uniqueId } from 'lodash';
import { IconV2, StandardModal, TextButton} from '@bamboohr/fabric';
import { classNameFromObject as co } from '@utils/dom';
import {
	formatTime,
	parseTime,
	getTwentyFourHourTime,
	getTimeDuration,
	getEndDate,
	padZerosOnTime,
	validateHourEntries,
	validateHourFormat,
	validateTimeEntries,
	checkEntriesForOverlap,
	getMeridiem,
	getProjectDataFromId
} from 'time-tracking/utils';
import { addEdit, deleteEntry } from 'time-tracking/http';
import { OVER_24_TOTAL_MSG, SAVE_FAIL_MSG, TIME_FORMAT } from 'time-tracking/constants';
import { Modal } from 'modal-legacy';
import ClockEntrySlat from './clock-entry-slat.react';
import { HourEntrySlat } from './hour-entry-slat.react';
import './styles.styl';

export default class AddEditEntry extends Component {
	constructor(props) {
		super(props);

		const { canEdit, type, clockEntries, currentEntryId, hourEntries, timesheetType } = props;
		this.hourTimesheetType = timesheetType === 'hour';

		let startTime = '';
		let startMeridiem = 'AM';
		let startMoment = null;
		let endTime = '';
		let endMeridiem = 'AM';
		let endMoment = null;
		let entryId = 1;
		let hours = '';
		const hoursError = false;
		let total = 0;
		let dayTotal = props.dayTotal;
		let showNoteInput = false;
		let note = '';
		let projectData = {
			projectId: null,
			projectName: null,
			taskId: null,
			taskName: null
		};
		let geolocationData = {
			clockInAddress: null,
			clockInLatitude: null,
			clockInLongitude: null,
			clockInLocationAccuracy: null,
			clockOutAddress: null,
			clockOutLatitude: null,
			clockOutLongitude: null,
			clockOutLocationAccuracy: null,
		};

		if (!this.hourTimesheetType && ['edit','view'].includes(type) && Array.isArray(clockEntries)) {
			const clockEntry = clockEntries.find(entry => entry.id === currentEntryId);

			if (clockEntry && clockEntry.start && clockEntry.end) {
				startMoment = moment.tz(clockEntry.start, clockEntry.timezone);
				endMoment = moment.tz(clockEntry.end, clockEntry.timezone);
				startTime = startMoment.format('h:mm');
				startMeridiem = getMeridiem(startMoment);
				endTime = endMoment.format('h:mm');
				endMeridiem = getMeridiem(endMoment);
				total = getTimeDuration(startMoment, endMoment, 'hours');
				dayTotal = props.dayTotal - total;
				note = clockEntry.note;
				showNoteInput = clockEntry.note !== null;
				projectData = {
					projectId: clockEntry.projectId,
					projectName: clockEntry.projectName,
					taskId: clockEntry.taskId,
					taskName: clockEntry.taskName
				};
				geolocationData = {
					clockInAddress: clockEntry.clockInAddress,
					clockInLatitude: clockEntry.clockInLatitude,
					clockInLongitude: clockEntry.clockInLongitude,
					clockInLocationAccuracy: clockEntry.clockInLocationAccuracy,
					clockOutAddress: clockEntry.clockOutAddress,
					clockOutLatitude: clockEntry.clockOutLatitude,
					clockOutLongitude: clockEntry.clockOutLongitude,
					clockOutLocationAccuracy: clockEntry.clockOutLocationAccuracy
				};
			}

			entryId = currentEntryId;
		}

		if (this.hourTimesheetType && type === 'edit' && Array.isArray(hourEntries)) {
			const hourEntry = hourEntries.find(entry => entry.id === currentEntryId);

			if (hourEntry) {
				entryId = currentEntryId;
				projectData = {
					projectId: hourEntry.projectId,
					projectName: hourEntry.projectName,
					taskId: hourEntry.taskId,
					taskName: hourEntry.taskName
				};
				note = hourEntry.note;
				hours = hourEntry.hours;
				total = hours;
				showNoteInput = hourEntry.note !== null;
				dayTotal -= hours;
			}
		}

		this.state = {
			entries: [
				{
					startTime,
					startMeridiem,
					startMoment,
					endTime,
					endMeridiem,
					endMoment,
					hours,
					hoursError,
					total,
					showNoteInput,
					note,
					projectData,
					geolocationData,
					timezone: this.props.timezone,
					id: entryId,
					key: entryId || uniqueId(),
					showRemoveButton: false,
					isEdit: type === 'edit'
				}
			],
			idIncrementer: entryId,
			dayTotal,
			isModalOpen: false,
			isModalProcessing: false,
			isSheetOpen: false,
			isSheetProcessing: false,
			canEdit: canEdit,
			canSave: type === 'edit' && canEdit
		};
	}

	componentDidMount() {
		this._handleValidation();

		setTimeout(() => {
			this.setState({ isModalOpen: true });
		});
	}

	componentDidUpdate() {
		const { canEdit, canSave } = this.state;
		const validInput = this._handleValidation();
		if (canEdit && canSave !== validInput) {
			this.setState({ canSave: validInput });
		}
	}

	_checkForOverlappingError() {
		const newEntries = this.state.entries.slice();
		const existingEntries = this._getExistingEntriesWithMoments();
		let overlappingErrorPresent = false;

		newEntries.forEach((newEntry) => {

			// Compare each new entry to existing entries
			existingEntries.forEach((entryComparison) => {

				// If editing entry, new entry can overlap itself but not others
				if (this.props.type === 'edit' && newEntry.id !== entryComparison.id) {
					if (checkEntriesForOverlap(newEntry, entryComparison)) {
						newEntry.overlappingError = true;
						overlappingErrorPresent = true;
					}
				} else if (this.props.type === 'add') {
					if (checkEntriesForOverlap(newEntry, entryComparison)) {
						newEntry.overlappingError = true;
						overlappingErrorPresent = true;
					}
				}

			});

			// Compare each new entry to other new entries
			newEntries.forEach((entryComparison) => {

				// Do not compare entry against itself
				if (newEntry.id !== entryComparison.id) {
					if (checkEntriesForOverlap(newEntry, entryComparison)) {
						newEntry.overlappingError = true;
						overlappingErrorPresent = true;
					}
				}
			})
		});

		return overlappingErrorPresent ? newEntries : overlappingErrorPresent;
	}

	_getExistingEntriesWithMoments() {
		return this.props.clockEntries.map((entry) => {
			entry.startMoment = moment.tz(entry.start, entry.timezone);
			entry.endMoment = moment.tz(entry.end, entry.timezone);
			return entry;
		});
	}

	_getRequestData() {
		const { entries } = this.state;
		return entries.map((entry) => {
			const { hours, id, note, projectData, startMoment, endMoment } = entry;
			const { type, employeeId, date } = this.props;
			if (this.hourTimesheetType) {
				return {
					id: type === 'edit' ? id : null,
					dailyEntryId: type === 'edit' ? null : id,
					employeeId,
					date,
					hours: parseTime(hours),
					note,
					projectId: projectData.projectId,
					taskId: projectData.taskId
				};
			}

			return {
				id: type === 'edit' ? id : null,
				trackingId: type === 'edit' ? null : id,
				employeeId,
				date,
				start: startMoment.format('HH:mm'),
				end: (endMoment.isSame(startMoment, 'day')) ? endMoment.format('HH:mm') : '24:00',
				note,
				projectId: projectData.projectId,
				taskId: projectData.taskId
			};
		});
	}

	_getIndividualEntryTotal(entry, isStartTime) {
		const {startTime, startMeridiem, endTime, endMeridiem} = entry;
		const {date} = this.props;
		const endDate = getEndDate(endTime, endMeridiem, date);
		const paddedValue = isStartTime ? padZerosOnTime(startTime) : padZerosOnTime(endTime);
		const formattedValue = paddedValue ? moment(paddedValue, 'h:mm').format('h:mm') : null;

		let startMoment;
		let endMoment;

		if (isStartTime && endTime && formattedValue) {
			startMoment = moment(`${ date } ${ getTwentyFourHourTime(formattedValue, startMeridiem) }`);
			endMoment = moment(`${ endDate } ${ getTwentyFourHourTime(endTime, endMeridiem) }`);
		} else if (!isStartTime && startTime && formattedValue) {
			startMoment = moment(`${ date } ${ getTwentyFourHourTime(startTime, startMeridiem) }`);
			endMoment = moment(`${ endDate } ${ getTwentyFourHourTime(formattedValue, endMeridiem) }`);
		} else {
			return 0;
		}

		return getTimeDuration(startMoment, endMoment, 'hours');
	}

	_getDayTotal() {
		return this.state.entries.reduce((runningTotal, entry) => runningTotal + entry.total, this.state.dayTotal);
	}

	_handleValidation() {
		const { entries } = this.state;
		let valid;

		if (this.hourTimesheetType) {
			valid = validateHourEntries(entries);
		} else {
			valid = validateTimeEntries(entries);
		}

		return valid;
	}

	_handleAddEntryClick = () => {
		const entries = this.state.entries.slice();
		const idCopy = ++this.state.idIncrementer;

		const newEntry = {
			startTime: '',
			startMeridiem: 'AM',
			endTime: '',
			endMeridiem: 'AM',
			hours: '',
			hoursError: false,
			total: 0,
			id: idCopy,
			isEdit: false,
			key: idCopy || uniqueId(),
			showRemoveButton: true,
			timezone: this.props.timezone,
			projectData: {
				projectId: null,
				projectName: null,
				taskId: null,
				taskName: null
			},
			geolocationData: {
				clockInAddress: null,
				clockInLatitude: null,
				clockInLongitude: null,
				clockInLocationAccuracy: null,
				clockOutAddress: null,
				clockOutLatitude: null,
				clockOutLongitude: null,
				clockOutLocationAccuracy: null,
			}
		};

		entries.push(newEntry);

		this.setState({
			entries,
			idIncrementer: idCopy
		});
	};

	_handleEntryRemoveClick = (id) => {
		this.setState({ entries: this.state.entries.filter(entry => entry.id !== id) });
	};

	_checkForHourEntriesError = () => {
		const { hourEntries, type } = this.props;
		const { entries } = this.state;
		const newEntries = entries.slice();

		const newEntriesHours = newEntries.reduce((total, entry) => {
			return total + parseTime(entry.hours);
		}, 0);


		const existingEntriesHours = hourEntries.reduce((total, entry) => {
			const entryIsBeingUpdated = newEntries.find(newEntry => newEntry.id === entry.id);
			if (entryIsBeingUpdated) {
				return total;
			}

			return total + entry.hours;
		}, 0);

		return (newEntriesHours + existingEntriesHours) > 24;
	};

	_handleFormSave() {
		let entries;
		if (this.hourTimesheetType) {
			const over24Hours = this._checkForHourEntriesError();
			if (over24Hours) {
				this.setState({ isModalProcessing: false });
				window.setMessage(OVER_24_TOTAL_MSG, 'error');
				return false;
			}
		} else {
			entries = this._checkForOverlappingError();
		}

		if (entries) {
			this.setState({ entries, isModalProcessing: false });
			window.setMessage(SAVE_FAIL_MSG, 'error');
			return false;
		}

		return this._getRequestData();
	}

	_handleSaveError(trackingIds) {
		const entries = this.state.entries.slice();

		trackingIds.forEach((id) => {
			entries.forEach((entry) => {
				if (entry.id === id) {
					entry.overlappingError = true;
				}
			});
		});

		this.setState({ entries });
	}

	_handleInputChange = (value, isStartTime, id) => {
		const { date } = this.props;
		const entries = this.state.entries.slice();
		const entry = entries.find(entry => entry.id === id);
		entry.overlappingError = false;

		if (isStartTime) {
			entry.startTime = value;
			entry.startMoment = value ? moment.tz(`${ date } ${ getTwentyFourHourTime(value, entry.startMeridiem) }`, entry.timezone) : null;
			entry.total = this._getIndividualEntryTotal(entry, true);

			this.setState({entries});
		} else {
			entry.endTime = value;
			if (entry.startTime) {
				const paddedEndTime = padZerosOnTime(value);
				const endHourNum = Number(paddedEndTime.split(':')[0]);

				if (
					entry.startMeridiem === 'AM' &&
					value !== '1' &&
					endHourNum &&
					endHourNum < entry.startMoment.hour()
				) {
					entry.endMeridiem = 'PM';
				} else if (entry.startMeridiem === 'AM' && startsWith(paddedEndTime, '12')) {
					entry.endMeridiem = 'PM';
				} else if (entry.startMeridiem === 'PM' && paddedEndTime === '12:00') {
					entry.endMeridiem = 'AM';
				}
			}
			const endDate = getEndDate(entry.endTime, entry.endMeridiem, date);
			entry.endMoment = value ? moment.tz(`${ endDate } ${ getTwentyFourHourTime(value, entry.endMeridiem) }`, entry.timezone) : null;
			entry.total = this._getIndividualEntryTotal(entry, false);

			this.setState({ entries });
		}
	};

	_handleInputBlur = (value, isStartTime, id) => {
		if (value) {
			const formattedValue = padZerosOnTime(value);

			this._handleInputChange(formattedValue, isStartTime, id);
		}
	};

	_handleSelectChange = (value, isStartMeridiem, id) => {
		const { date } = this.props;
		const entries = this.state.entries.slice();
		const entry = entries.find(entry => entry.id === id);

		entry.overlappingError = false;

		if (isStartMeridiem) {
			if (value === 'PM' && entry.endMeridiem !== 'PM') {

				entry.startMeridiem = value;
				entry.startMoment = moment.tz(`${ date } ${ getTwentyFourHourTime(entry.startTime, value) }`, entry.timezone);
				entry.endMeridiem = (entry.endTime === '12:00') ? entry.endMeridiem : 'PM';
				const endDate = getEndDate(entry.endTime, entry.endMeridiem, date);
				entry.endMoment = moment.tz(`${ endDate } ${ getTwentyFourHourTime(entry.endTime, value) }`, entry.timezone);
				entry.total = this._getIndividualEntryTotal(entry, true);

				this.setState({ entries });
			} else {
				entry.startMeridiem = value;
				entry.startMoment = moment.tz(`${ date } ${ getTwentyFourHourTime(entry.startTime, value) }`, entry.timezone);
				entry.total = this._getIndividualEntryTotal(entry, true);

				this.setState({ entries });
			}
		} else {
			entry.endMeridiem = value;
			const endDate = getEndDate(entry.endTime, entry.endMeridiem, date);
			entry.endMoment = moment.tz(`${ endDate } ${ getTwentyFourHourTime(entry.endTime, value) }`, entry.timezone);
			entry.total = this._getIndividualEntryTotal(entry, false);

			this.setState({ entries });
		}
	};

	_handleAddNote = (id) => {
		const { entries } = this.state;
		entries.map((entry) => {
			if (entry.id === id) {
				entry.showNoteInput = !entry.showNoteInput;
			}
		});

		this.setState({ entries });
	}

	_handleNoteChange = (event, id) => {
		const value = event.target.value;
		const { entries } = this.state;
		entries.map((entry) => {
			if (entry.id === id) {
				entry.note = value;
			}
		});

		this.setState({ entries });
	}

	_handleProjectChange = (id, value) => {
		const { projectsWithTasks } = this.props;
		const { entries } = this.state;
		const updatedEntries = entries.map((entry) => {
			if (entry.id === id) {
				entry.projectData = getProjectDataFromId(value, projectsWithTasks);
			}

			return entry;
		});

		this.setState({ entries: updatedEntries });
	}

	_handleHourInputFocus = (id) => {
		const { entries } = this.state;
		const entriesCopy = entries.slice();
		const entry = entriesCopy.find(entry => entry.id === id);
		if (entry.hours !== null && entry.hours !== '') {
			entry.hours = round(entry.hours, 2);
			if (window.GLOBAL_DECIMAL_CHAR === ',') {
				entry.hours = String(entry.hours).replace('.', ',');
			}
			this.setState({ entries: entriesCopy });
		}
	}

	_handleHourInputChange = (e, id) => {
		const { value } = e.target;
		const { entries } = this.state;
		const entriesCopy = entries.slice();
		const entry = entriesCopy.find(entry => entry.id === id);
		const isValid = validateHourFormat(value);
		entry.hours = value;

		if (isValid) {
			entry.hoursError = false;
			entry.total = parseTime(value);
		} else {
			entry.hoursError = true;
		}

		this.setState({ entries: entriesCopy });
	};

	_saveEntry = () => {
		const { afterAction } = this.props;
		const type = this.hourTimesheetType ? 'hour' : 'clock';

		this.setState({ isModalProcessing: true });
		const formData = this._handleFormSave();
		if (formData) {
			addEdit(type, formData)
				.then(() => {
					this.setState({ isModalOpen: false });
					if (afterAction && typeof afterAction === 'function') {
						afterAction();
					}
				})
				.catch((response) => {
					this.setState({ isModalProcessing: false });
					if (response.status === 409) {
						this._handleSaveError(JSON.parse(response.responseText).trackingIds);
					}
				});
		}
	}

	_deleteEntry = () => {
		const { afterAction, currentEntryId } = this.props;
		const type = this.hourTimesheetType ? 'hour' : 'clock';

		this.setState({ isSheetProcessing: true });
		deleteEntry(type, currentEntryId)
			.then(() => {
				this.setState({ isModalOpen: false, isSheetOpen: false });
				if (afterAction && typeof afterAction === 'function') {
					afterAction();
				}
			})
			.catch(() => {
				this.setState({ isSheetProcessing: false });
			});
	}

	_renderAddEntryLink() {
		return(
			<TextButton type="button" onClick={this._handleAddEntryClick} startIcon="circle-plus-regular">
				{ $.__('Add Entry') }
			</TextButton>
		);
	}

	_renderDayTotal() {
		const dayTotal = this._getDayTotal();
		return (
			<div className={ co('AddEditEntry__dayTotal', { 'AddEditEntry__dayTotal--error': dayTotal > 24 }) }>
				{ $.__('Day Total: %1', formatTime(dayTotal)) }
			</div>
		);
	}

	_renderClockEntrySlats() {
		const { canEdit, projectsWithTasks, type } = this.props;
		return (
			<form className="BhrForms">
				{ this.state.entries.map((entry) => (
					<ClockEntrySlat
						{ ...entry }
						canEdit={ canEdit }
						date={this.props.date}
						onAddNote={ this._handleAddNote }
						onNoteChange={ this._handleNoteChange }
						onProjectChange={ this._handleProjectChange }
						handleRemoveButtonClick={this._handleEntryRemoveClick}
						handleInputBlur={this._handleInputBlur}
						handleInputChange={this._handleInputChange}
						handleSelectChange={this._handleSelectChange}
						key={entry.key}
						projectsWithTasks={ projectsWithTasks }
						type={ type }
					/>
				)) }
			</form>
		);
	}

	_renderHourEntrySlats() {
		const { canEdit, onHintDismiss, projectsWithTasks, showTimeEntryHint, timesheetType, type } = this.props;
		const { entries } = this.state;
		return (
			<form>
				{ entries.map(entry => (
					<HourEntrySlat
						key={ entry.id }
						{ ...entry }
						canEdit={ canEdit }
						onAddNote={ this._handleAddNote }
						onHintDismiss={ () => onHintDismiss('showTimeEntryHint', false) }
						onInputBlur={ this._handleHourInputBlur }
						onInputChange={ this._handleHourInputChange }
						onInputFocus={ this._handleHourInputFocus }
						onNoteChange={ this._handleNoteChange }
						onProjectChange={ this._handleProjectChange }
						onRemoveButtonClick={ this._handleEntryRemoveClick }
						projectsWithTasks={ projectsWithTasks }
						showTimeEntryHint={ showTimeEntryHint }
						type={ type }
					/>
				)) }
			</form>
		);
	}

	render() {
		const { clockEntries, openAsSheet, currentEntryId, date, hourEntries, modalTitle, timezone, timesheetType, type, canEdit } = this.props;
		const { canSave, isModalOpen, isModalProcessing, isSheetOpen, isSheetProcessing } = this.state;
		const today = moment.tz(date, timezone);
		const content = (
			<div className="AddEditEntry">
				{ (timesheetType === 'multiple' || timesheetType === 'clock') && this._renderClockEntrySlats() }
				{ timesheetType === 'hour' && this._renderHourEntrySlats() }
				<div className="AddEditEntry__totalRow">
					{ type === 'add' && this._renderAddEntryLink() }
					{ this._renderDayTotal() }
				</div>
			</div>
		);

		let deleteSheetProps = null;

		if (type === 'edit') {
			let messageContent;
			if (this.hourTimesheetType) {
				const hourEntry = hourEntries.find(entry => entry.id === currentEntryId);
				messageContent = $.__('Are you sure you want to delete this %1 entry?', formatTime(hourEntry.hours));
			} else {
				const clockEntry = clockEntries.find(entry => entry.id === currentEntryId);
				const start = moment.tz(clockEntry.start, clockEntry.timezone).format(TIME_FORMAT);
				const end = moment.tz(clockEntry.end, clockEntry.timezone).format(TIME_FORMAT);
				messageContent = $.__('Are you sure you want to delete the %1 to %2 entry?', start, end);
			}

			const sheetContent = (
				<div className="AddEditEntry__deleteSheet">
					<span className="AddEditEntry__deleteSheetIcon">
						<IconV2 name="trash-can-regular" size={48} />
					</span>
					<h5>{ messageContent }</h5>
				</div>
			);

			deleteSheetProps = {
				content: sheetContent,
				isOpen: isSheetOpen,
				isProcessing: isSheetProcessing,
				onClose: () => this.setState({ isSheetOpen: false }),
				primaryAction: this._deleteEntry,
				primaryActionText: $.__('Yes, Delete Entry'),
				title: $.__('Just Checking...')
			};
		}

		const handleOnClose = () => this.setState({isModalOpen: false});
		let alternativeActionFunction = null;

		let primaryActionFunction = handleOnClose;
		if (canEdit) {
			alternativeActionFunction = handleOnClose;
			primaryActionFunction = canSave ? this._saveEntry : null
		}

		return (
			<Modal
				additionalAction={ type === 'edit' && canEdit ? () => this.setState({isSheetOpen: true}) : null }
				additionalActionText={ $.__('Delete Time Entry') }
				alternativeAction={ alternativeActionFunction }
				isOpen={ isModalOpen }
				isProcessing={ isModalProcessing }
				onClose={ handleOnClose }
				primaryAction={ primaryActionFunction }
				primaryActionText={ canEdit ? $.__('Save') : $.__('Done') }
				sheetProps={ deleteSheetProps }
				title={ modalTitle }
				header={ <StandardModal.StandardHeadline icon="calendar-days-regular" text={today.format('dddd, MMM D')} /> }
				headerType={ 'text' }
                type={openAsSheet ? 'largeSheet' : 'medium'}
			>
				{ content }
			</Modal>
		);
	}
}
