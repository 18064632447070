import Ajax from '@utils/ajax';
import Tooltip from 'Tooltip.mod';
import normalizeData from 'time-tracking/components/timesheet.react/store/store-bootstrap';
import { TimesheetDetailsModalContent } from './timesheet-details-modal-content';
import './styles.styl';

export default function (timesheetId) {
	const title = $.__('Timesheet Detail');
	const url = `/timesheet/${ timesheetId }`;

	Ajax.get(url)
		.then((response) => {
			const initialState = normalizeData(response.data);
			window.BambooHR.Modal.setState({
				isOpen: true,
				content: <TimesheetDetailsModalContent initialState={initialState} />,
				headline: title,
				primaryActionText: $.__('Close'),
				primaryAction() {
					window.BambooHR.Modal.setState({isOpen: false});
				},
				alternativeAction: null,
				contentHasPadding: false,
				type: 'fullScreen',
				onOpen() {
					// Doing this in prepareFragment() didn't work for Jade, I think because of the way Modal strips out stuff from the content, so we are doing tooltips again here.
					const workWeekWarningIcons = Array.from(document.querySelectorAll('.js-time-details-work-week-warning-icon'));
					workWeekWarningIcons.forEach((icon) => {
						const content = $.__('Overtime cannot be calculated for this day.');
						Tooltip.createStandard(icon, {
							content,
							position: 'right'
						});
					});
				}
			});
		});
}
