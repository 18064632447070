import { IconButton, Tooltip, Headline, IconV2, LayoutBox, Button, BodyText } from '@bamboohr/fabric';
import { Fragment } from 'react';
import openChangeHistoryModal from 'time-tracking/modals/change-history';
import { consume } from '../../store/context';
import TimesheetDropdown from './timesheet-list-dropdown.react';
import OptionDropdown from './timesheet-option-dropdown.react';
import './styles.styl';

export function Header(props) {
	const {
		focusedTimesheet,
		showHeader,
		isOwnTimesheet,
		isApprovalShowing,
		isLatestTimesheet,
		timesheetOptions,
		changeViewOption,
		employeeTimesheets,
		getFocusedDays,
		getToday,
	} = props;
	const showOptions = isOwnTimesheet && !isApprovalShowing;
	const dropdownProps = {
		timesheetOptions,
		changeViewOption,
	};
	const initialDate = isLatestTimesheet ? getToday().date : undefined;

	return (
		<Fragment>
			{showHeader && (
				<div className="TimesheetHeader">
					<div className="TimesheetHeader__content">
						<IconV2
							color="primary-strong"
							name="stopwatch-regular"
							size={24}
						/>
						<LayoutBox marginLeft={2}>
							<Headline size="medium">{$.__('Timesheet')}</Headline>
						</LayoutBox>
						<div className="TimesheetHeader__controls">
							{employeeTimesheets && <TimesheetDropdown />}
							{showOptions && <OptionDropdown {...dropdownProps} />}
							<Button
								color="secondary"
								onClick={() => openChangeHistoryModal(getFocusedDays(), initialDate)}
								size="small"
								startIcon="clock-rotate-left-regular"
								type="button"
								variant="outlined"
							>
								{$.__('History')}
							</Button>
							<Tooltip content={$.__('Download PDF')}>
								<IconButton
									color="secondary"
									data-bi-id="time-tracking-print-timesheet-button"
									icon="down-to-line-regular"
									onClick={() => window.open(`/timesheet/${focusedTimesheet.id}/print`, '_blank')}
									size="small"
									target="_blank"
									type="button"
									variant="outlined"
								/>
							</Tooltip>
						</div>
					</div>
				</div>
			)}

			{focusedTimesheet.workWeekStartsOn?.changed && (
				<LayoutBox data-testid="workWeekWarning" marginBottom={2}>
					<BodyText color="warning-strong" icon="triangle-exclamation-regular">
						{$.__n(
							'The work week start day has changed. There is %1$s day where overtime cannot be calculated.',
							'The work week start day has changed. There are %1$s days where overtime cannot be calculated.',
							focusedTimesheet.workWeekStartsOn.count,
						)}
					</BodyText>
				</LayoutBox>
			)}
		</Fragment>
	);
}

export default consume([
	// Properties
	'isOwnTimesheet',
	'isApprovalShowing',
	'isLatestTimesheet',
	'employeeTimesheets',
	'focusedTimesheet',
	'timesheetOptions',
	'changeViewOption',
	'showHeader',
	// Actions
	// Queries
	'getFocusedDays',
	'getToday',
], Header);
