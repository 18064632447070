import { ReactNode } from 'react';
import {
	Button,
	CardContentSpacing,
	CardSize,
	StandardModal,
	TextButton,
} from '@bamboohr/fabric';
import { CancellationContentProps } from '../../types';

interface ModalContentWrapperProps
	extends Omit<
		CancellationContentProps,
		'formValues' | 'nextBillingDates' | 'onNext' | 'setIsLoading' | 'reasons'
	> {
	children: ReactNode;
	disablePrimaryButton?: boolean;
	onNext: (data: any) => void;
}

export const ModalContentWrapper = ({
	biId,
	children,
	disablePrimaryButton,
	isLastStep,
	onClose,
	onNext,
	onPrevious,
	renderHeader,
}: ModalContentWrapperProps): JSX.Element => {
	return (
		<StandardModal.Body
			renderFooter={
				<StandardModal.Footer
					actions={[
						<TextButton
							data-bi-id={`${biId}-cancel-button`}
							key={`${biId}-cancel-button`}
							onClick={onClose}
							type="button"
						>
							{$.__('Cancel')}
						</TextButton>,
						...(onPrevious
							? [
									<Button
										data-bi-id={`${biId}-back-button`}
										color="secondary"
										key={`${biId}-back-button`}
										onClick={onPrevious}
										type="button"
									>
										{$.__('Back')}
									</Button>,
							  ]
							: []),
						<Button
							data-bi-id={`${biId}-next-button`}
							disabled={disablePrimaryButton}
							key={`${biId}-next-button`}
							onClick={onNext}
							type="button"
						>
							{isLastStep ? $.__('Submit') : $.__('Next')}
						</Button>,
					]}
				/>
			}
			renderHeader={renderHeader}
			size={CardSize.SMALL}
		>
			<StandardModal.Constraint>{children}</StandardModal.Constraint>
		</StandardModal.Body>
	);
};
