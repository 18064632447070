import * as TimeOffService from './service';
import Ajax from '@utils/ajax';
import { commentExistsInADenialModal } from 'Pto.mod'; // Importing for now until it is visited for refactor

/**
 * Moved from Pto.mod/index.js. Will need to refactor in the future using React
 *
 * Manages the flow of bypassing a request, whether "approve" or "deny". Shows the bypass modal. This function uses
 * an endpoint from inbox.
 * @param {number}      requestId          | The time off request id
 * @param {number}      workflowInstanceId | The workflowInstanceId (not the time off request id)
 * @param {string}      status             | "approve" or "deny"
 * @param {string}      name               | The inbox item type ie, 'information_update', or 'time_off_request', etc.
 * @param {string}      type               | The inbox type, when in doubt, pass 'inbox'
 * @param {string}      assigned           | A parameter from a query string in the inbox... when in doubt, pass 'all'
 * @param {function}    callback           | When you want to happen after the full 'approval' or 'denial'. Default is a noop
 */
export function byPassRequest(
	requestId,
	workflowInstanceId,
	status,
	name,
	type,
	assigned,
	showSuccessMessage,
	callback = Function.prototype,
) {
	/**
	 * Get the content for the bypass modal and open it.
	 * On approval, save the time off request
	 */
	window.BambooHR.Modal.setState({ isLoading: true });
	Ajax.get(`/time_off/requests/${requestId}/bypass`, { status })
		.then((response) => {
			if (response && response.data) {
				window.BambooHR.Modal.setState({
					alternativeAction: () => {
						window.BambooHR.Modal.setState({
							isOpen: false,
						});
					},
					content: (
						<div dangerouslySetInnerHTML={{ __html: response.data.html }} />
					),
					headerType: 'stacked',
					headline: $.__("You'll be bypassing some approvers."),
					icon:
						status === 'approve' ? 'thumbs-up-regular' : 'thumbs-down-regular',
					iconV2Color: 'primary-strong',
					isLoading: false,
					isOpen: true,
					onOpen: () => {
						$('textarea.autosize').autosize();
						$('#requestByPassForm span.truncate').each((i, e) => {
							// Awesomeness that counts visible names and calculates more
							if (e.offsetWidth < e.scrollWidth) {
								const invisibleItems = [];
								for (let j = 0; j < e.childElementCount; j++) {
									// Adding width of ellipsis
									if (
										e.children[j].offsetLeft + 12 >
										e.offsetLeft + e.offsetWidth
									) {
										invisibleItems.push(e.children[j]);
									}
								}

								if (invisibleItems.length > 0) {
									$(this)
										.next('.MsgApproverSmall__more')
										.html(`+${invisibleItems.length} more`);
								}
							}
						});
					},
					primaryAction: () => {
						const params = {
							id: workflowInstanceId,
							status,
							action: status,
							name,
							type,
							assigned,
							ajax: true,
							bypass: true,
						};
						const commentExistsInADenialModalResult =
							commentExistsInADenialModal();
						const serializedRequestBypassForm =
							$('#requestByPassForm').serialize();

						window.BambooHR.Modal.setState({ isProcessing: true }, true);
						TimeOffService.saveTimeOffRequest(params)
							.then((saveResponse) => {
								if (saveResponse.data.success || saveResponse.data.result) {
									if (saveResponse.data.message && showSuccessMessage) {
										window.setMessage(saveResponse.data.message, 'success');
									}
									if (status === 'deny' && commentExistsInADenialModalResult) {
										TimeOffService.postDeniedRequestComment(
											serializedRequestBypassForm,
										);
									}
									callback(saveResponse.data);
								} else if (saveResponse.data.error) {
									window.setMessage(saveResponse.data.error, 'error');
								} else {
									window.errorFallBack();
								}

								window.BambooHR.Modal.setState({ isOpen: false });
							})
							.catch(() => {
								window.errorFallBack();
								window.BambooHR.Modal.setState({ isProcessing: false }, true);
							});
					},
					primaryActionText:
						status === 'approve' ? $.__('Approve Anyway') : $.__('Deny Anyway'),
					title: $.__('Just Checking...'),
				});
			}
		})
		.catch((response) => {
			setMessage(response.error, 'error');
		});
}

/**
 * Moved from Pto.mod/index.js. Will need to refactor in the future using React
 *
 * @param {number} workflowInstanceId
 * @param {string} name
 * @param {function} saveCallback
 * @param {function} noThanksCallback
 */
export function deniedTimeOffRequestCommentModal(
	workflowInstanceId,
	name,
	saveCallback = Function.prototype,
	noThanksCallback = Function.prototype,
) {
	const params = {
		ajax: true,
		denial: true,
		id: workflowInstanceId,
		name,
	};

	const primaryAction = () => {
		window.BambooHR.Modal.setState({ isProcessing: true }, true);
		const commentExistsInADenialModalResult = commentExistsInADenialModal();
		const serializedDenialModalForm = $('.denialModal form').serialize();

		if (commentExistsInADenialModalResult) {
			TimeOffService.postDeniedRequestComment(serializedDenialModalForm)
				.then(() => {
					window.BambooHR.Modal.setState({ isOpen: false });
					saveCallback();
				})
				.catch(() => {
					window.BambooHR.Modal.setState({ isProcessing: false }, true);
				});
		}
	};

	TimeOffService.getDeniedTimeOffRequestCommentModalData(params)
		.then((response) => {
			if (response.data.success) {
				window.BambooHR.Modal.setState({
					alternativeActionText: $.__("No Thanks, I'm Done"),
					dangerousContent: response.data.html,
					icon: 'thumbs-down-regular',
					iconV2Color: 'primary-strong',
					onOpen: () => {
						$('.ui-dialog-titlebar-close').hide();
						$('textarea.autosize').autosize();
						const $textarea = $('textarea');
						$textarea.on('keyup', () => {
							if ($($textarea).val().length > 0) {
								window.BambooHR.Modal.setState({ primaryAction }, true);
							} else {
								window.BambooHR.Modal.setState({ primaryAction: null }, true);
							}
						});
						$('#js-denialAction').attr('name', 'action');
					},
					isOpen: true,
					onClose: () => {
						window.BambooHR.Modal.setState({
							isOpen: false,
						});
						noThanksCallback();
					},
					primaryActionText: response.data.save,
					primaryAction: null,
					title: response.data.title,
				});
			} else {
				window.setMessage(response.error, 'error');
			}
		})
		.catch(window.errorFallBack);
}
