import { formatTime } from 'time-tracking/utils';
import { BodyText, Flex } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export default function SummaryBreakdown(props) {
	const { periodOvertime, periodTimeOff, periodHoliday, shiftDifferentials, holidaySummary, hourSummary } = props;
	const showMultiplier = periodOvertime.length > 1;

	return ifFeature('PAYROLL_HOURS_DETAIL_REPORT',
		<Flex flexDirection="column" gap={2}>
			{hourSummary.map(summary => {
				let title= '';

				if( summary.hoursType === 'REG' && !summary.projectName && !summary.taskName && !summary.shiftDifferentialName && !summary.holidayName ){
					title = summary.hoursType;
				}
				if( summary.hoursType !== 'REG' ){
					title = summary.hoursType;
				}
				if( summary.projectName ){
					title += title ? ' | ' : '';
					title += summary.projectName;
				}
				if( summary.taskName ){
					title += ` » ${summary.taskName}`;
				}
				if( summary.shiftDifferentialName ){
					title += title ? ' | ' : '';
					title += summary.shiftDifferentialName;
				}
				if( summary.holidayName && summary.holidayMultiplier ){
					title += title ? ' | ' : '';
					title += `${summary.holidayName} (${summary.holidayMultiplier}x)`;
				}

				if( summary.hoursType === 'HOL' ){
					return(
						<>
							{!!periodTimeOff && (
								<Flex flexDirection="column" justifyItems="start">
									<BodyText size="small" weight="regular">{$.__('Time Off')}</BodyText>
									<BodyText size="medium" weight="semibold">{formatTime(periodTimeOff, true)} </BodyText>
								</Flex>
							)}
							<Flex flexDirection="column" justifyItems="start" key={title}>
								<BodyText size="small" weight="regular">{$.__('Holiday')}</BodyText>
								<BodyText size="medium" weight="semibold">{formatTime(summary.totalHours, true)}</BodyText>
							</Flex>
						</>
					);
				}

				return(
					<Flex flexDirection="column" justifyItems="start" key={title}>
						<BodyText size="small" weight="regular">{title}</BodyText>
						<BodyText size="medium" weight="semibold">{formatTime(summary.totalHours, true)}</BodyText>
					</Flex>
				);
			})}
		</Flex>,
		<Flex alignItems="center" flexDirection="column" gap={0.5}>
			{!!periodOvertime.length &&
				periodOvertime.map((item) => (
					<BodyText color="warning-strong" key={item.multiplier}>
						{formatTime(item.hours, true)} {$.__('Overtime')}{' '}
						{showMultiplier && `(${item.multiplier}x)`}
					</BodyText>
				))}

			{!!shiftDifferentials.length &&
				shiftDifferentials.map((item) => (
					<BodyText color="neutral-medium" key={item.id}>
						{formatTime(item.hours, true)} {item.name}
					</BodyText>
				))}

			{!!periodTimeOff && (
				<BodyText color="neutral-medium">
					{formatTime(periodTimeOff, true)} {$.__('Time Off')}
				</BodyText>
			)}

			{!!periodHoliday && ifFeature('HOLIDAY_PAY', null,
				<BodyText color="neutral-medium">
					{formatTime(periodHoliday, true)} {$.__('Holiday')}
				</BodyText>
			)}

			{ifFeature(
				'HOLIDAY_PAY',
				!!holidaySummary?.length && holidaySummary?.sort((a, b) => {
					// sort the array of holidays so that the "worked" holidays are at the bottom
					if (a.type < b.type) return -1;
					if (a.type > b.type) return 1;
					return 0;
				})?.map((holiday, key) => {
					if( holiday.type === 'paid' ){
						return (
							<BodyText color="neutral-medium" key={key}>
								{formatTime(holiday.hours, true)}
								{$.__(' Holiday')}
							</BodyText>
						);
					}
					if( holiday.type === 'worked' ){
						return (
							<BodyText color="neutral-medium" key={key}>
								{formatTime(holiday.hours, true)}
								{$.__(' Worked Holiday (pays %1x)', holiday.multiplier)}
							</BodyText>
						);
					}
					return null;
				}),
			)}
		</Flex>
	);
}
