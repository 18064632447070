import { FunctionComponent } from 'react';
import classnames from 'classnames';
import './base.styl';

const HoverBase: FunctionComponent<React.PropsWithChildren<DynamicForm.HoverBase>> = ({
	children,
	isEditable,
	hoverChild,
}) => {
	const classes = classnames('HoverHint', 'DynamicForm--HoverBase__hint', {
		'DynamicForm--HoverBase__Editable': isEditable,
	});

	return (
		<span className={ classes }>
			{ hoverChild }
			<span className="HoverHint__content">{ children }</span>
		</span>
	);
};

export default HoverBase;
