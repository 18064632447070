import { FC } from 'react';
import {
	JobOpeningsCancellationForm,
	PayrollCancellationFormStep1,
	PayrollCancellationFormStep2,
	PayrollCancellationFormStep3,
} from '../components';
import { CancellationType, CancellationContentProps } from '../types';

type CancellationItems = {
	[key in CancellationType]?: {
		content?: FC<React.PropsWithChildren<CancellationContentProps>>[];
		title: string;
		productName?: string;
	};
};

export const CancellationItemsMap: CancellationItems = {
	[CancellationType.ACCOUNT]: {
		title: $.__('Cancel BambooHR Account'),
		productName: $.__('Full BambooHR Account'),
	},
	[CancellationType.PAYROLL]: {
		content: [PayrollCancellationFormStep1, PayrollCancellationFormStep2, PayrollCancellationFormStep3],
		title: $.__('Cancel Payroll'),
		productName: $.__('Payroll'),
	},
	[CancellationType.JOB_OPENINGS]: {
		content: [JobOpeningsCancellationForm],
		title: $.__('Manage Job Openings'),
		productName: $.__('Job Openings'),
	},
	[CancellationType.PERFORMANCE]: {
		title: $.__('Cancel Performance Management'),
		productName: $.__('Performance Management'),
	},
	[CancellationType.TIME_TRACKING]: {
		title: $.__('Cancel Time Tracking'),
		productName: $.__('Time Tracking'),
	},

	[CancellationType.BENEFIT_ADMIN]: {
		title: $.__('Cancel Benefits Administration'),
		productName: $.__('Benefits Administration'),
	},
};
