import moment from 'moment.lib';

import { BaseFormData, TemplateData } from './baseFormData';

import {
	EmployeeData,
	NewHirePacket,
	NewHirePacketSaveData,
	PacketData,
	PacketSaveData,
	PacketTask,
} from './Interfaces';
import { markFormAsChanged } from 'Form.util';
import { Task } from '../providers/packet-tasks/Definitions';

export class NewHirePacketFormData extends BaseFormData {

	nhpTemplateId: number | string;

	employeeId: number | string;

	files: Record<string, unknown>;

	homeEmail: string;

	otherTasks: Array<PacketTask>;

	packetTasks: Array<PacketTask>;

	tasks: Task[];

	hireDate: string;

	selectedManagerOptions: Array<string>;

	showPayrollState: boolean;

	showPayrollFederal: boolean;

	showPayrollDirectDeposit: boolean;

	constructor(initialFormData: PacketData) {
		super(initialFormData);
		const {
			employeeId,
			files,
			homeEmail,
			managerId,
			nhpTemplateId,
			otherTasks,
			packetTasks,
			showPayrollState,
			showPayrollFederal,
			showPayrollDirectDeposit,
		} = initialFormData;

		this.employeeId = employeeId;
		this.files = files;
		this.homeEmail = homeEmail;
		this.selectedManagerOptions = [managerId as string];
		this.nhpTemplateId = nhpTemplateId;
		this.otherTasks = otherTasks;
		this.packetTasks = packetTasks;
		this.showPayrollState = showPayrollState || false;
		this.showPayrollFederal = showPayrollFederal || false;
		this.showPayrollDirectDeposit = showPayrollDirectDeposit || false;
	}

	_getEmployeeData(): EmployeeData {
		const hireDate = (this.hireDate) ? moment(this.hireDate).format(moment.defaultFormat) as string : '';
		return {
			id: this.employeeId,
			hireDate,
			email: this.homeEmail,
			managerId: (this.selectedManagerOptions[0] === '') ? null : this.selectedManagerOptions[0],
		};
	}

	_getNewHirePacketData(): NewHirePacket {
		return {
			arriveByTime: this.selectedArrivalTime[0],
			contactEmployeeId: this.selectedContactOptions[0],
			employeeId: this.employeeId,
			nhpTemplateId: this.nhpTemplateId,
			location: this.location,
			otherInstructions: this.otherInstructions,
			sendGetToKnowYouEmail: this.sendGetToKnowYouEmail,
			showPayrollState: this.showPayrollState,
			showPayrollFederal: this.showPayrollFederal,
			showPayrollDirectDeposit: this.showPayrollDirectDeposit,
		};
	}

	_getNewHirePacketSaveData(): NewHirePacketSaveData {
		const employee = this._getEmployeeData();
		
		const sortedGtkyQuestions = this.assignQuestionSortOrderByIndex();

		const formattedQtkyQuestions = sortedGtkyQuestions.map((question) => ({
			...question,
			archived: false,
			employeeId: employee.id,
			hidden: false,
		}));

		const newHirePacket = this._getNewHirePacketData();

		return {
			employee,
			newHirePacket,
			questionsAndAnswers: formattedQtkyQuestions,
			recipientTypes: this.gtkyRecipients,
		};
	}

	getFormData(): PacketSaveData {
		return {
			// files: this.files,
			newHirePacketData: this._getNewHirePacketSaveData(),
			newHirePacketTasks: this.packetTasks,
			tasks: this.tasks,
			notApplicableToEmployee: this.otherTasks,
		};
	}

	updateData(updatedData: TemplateData, isInitialData = false): void {
		super.updateData(updatedData, isInitialData);
		// @ts-ignore
		const updatedHireDate = updatedData.hireDate && (updatedData.hireDate != this.hireDate);
		// @ts-ignore
		const updatedManagerId = updatedData.selectedManagerOptions && updatedData.selectedManagerOptions[0] != this?.selectedManagerOptions[0];
		if (!isInitialData) {
			markFormAsChanged();
		}

		if (updatedHireDate || updatedManagerId) {
			document.dispatchEvent(new Event('nhpForm:formUpdate'));
		}
	}
}
