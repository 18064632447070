export default function AccessLevelSelectorWrapper({
	_bem,
	icon,
	isButton,
	isDropdown,
	isOption,
	isInput,
	$elem,
}) {
	if (isOption) {
		return (
            (<ba-option>
                {<span className="ba-icon-inline">
                    <span className='ba-icon-inline'>
                            <ba-icon
                                encore-color="neutral-extra-strong"
                                encore-name="lock-regular"
                                encore-size="16"
                            ></ba-icon>
                    </span>
                    {$.__('BambooHR Access Level')}
            </span>}
                <div className={ `${_bem('label')} fab-marginLeft--24` } />
                <ba-option-group class={ _bem('down-list') } />
                <div className={ _bem('inputWrapper') } style={ { display: 'none !important' } } />
            </ba-option>)
        );
	}

	let width;
	let name;
	let type;
	let size;

	if (
		$elem &&
		$elem[0]
	) {
		if ($elem[0].hasAttribute('width')) {
			width = parseInt($elem.attr('width'));
		}

		if ($elem[0].hasAttribute('name')) {
			name = $elem.attr('name');
		}

		if ($elem[0].hasAttribute('type')) {
			type = $elem.attr('type');
		}

		if ($elem[0].hasAttribute('size')) {
			size = $elem.attr('size');
		}
	}

	if (isButton) {
		return (
            (<ba-dropdown
				button-icon={ icon }
				button-outline={ true }
				button-secondary={ true }
				button-size={size || "teenie"}
				type={type}
				class={ _bem('^') }
				width={ width }
			/>)
        );
	}

	if (isInput) {
		return (
			<ba-select
				button-width={ 7 }
				name={ name }
				not-clearable={ true }
				width={ width }
			/>
		);
	}

	return (
		<div className={ _bem('^') }>
			<div className={ _bem('menu') }>
				<div className={ _bem('btn') }>
					{ isDropdown && (
						<a>
							{ $.__('BambooHR Access Level') }
							<br />
							<i className={ _bem('label') } />
						</a>
					) }
				</div>
				<ul className={ _bem('down-list') } style={ { 'display': 'none' } } />
			</div>
			<div className={ _bem('inputWrapper') } style={ { 'display': 'none !important' } } />
		</div>
	);
}
