import { classNameFromObject } from '@utils/dom';
import {
	BodyText,
	Flex,
	Headline,
	LayoutBox,
} from '@bamboohr/fabric';
import DailyGraph from './daily-graph.react';

export default function SummaryWeek(props) {
	const {
		fadeGraph,
		hideWeekends,
		lastWeekDays,
		thisWeekDays,
		serverTime,
		weekTitleText,
		weekRangeText,
		weekTotal,
	} = props;

	const dailyGraphClasses = classNameFromObject({
		'js-timesheet-showDailyGraph TimesheetSummary__dailyGraph': true,
		'TimesheetSummary__dailyGraph--fade': fadeGraph,
	});

	return(
		<Flex flexDirection='column' gap={0.75} textAlign='center'>
			<BodyText size='large' justify='center' weight='bold'>
				{weekTitleText}
			</BodyText>
			<LayoutBox marginBottom={0.75}>
				<Headline color='primary' size='medium'>
					{weekTotal}
				</Headline>
			</LayoutBox>
			<LayoutBox marginBottom={2.25}>
				<BodyText justify='center' color='neutral-strong' size='small'>
					{weekRangeText}
				</BodyText>
			</LayoutBox>
			<div className={dailyGraphClasses}>
				<DailyGraph lastWeek={lastWeekDays} thisWeek={thisWeekDays} serverTime={serverTime} hideWeekends={hideWeekends} />
			</div>
		</Flex>
	);
}
