import { BEM as Bem } from '@utils/dom';

export const BEM = new Bem('SecureDownloadManager');

export const MAX_VISIBLE_RECIPIENTS = 5;

export const FILE_INFO_ENDPOINT = '/file-info';
export const SFS_ENDPOINT = '/secure-file-share';

export const SHEET_PROPS = {
	content: (
		<div className={BEM.elem('sfsDisableConfirmation')}>
			{$.__("The file won't be accessible to anyone with the link.")}
		</div>
	),
	headline: $.__('Are you sure you want to disable this link?'),
	icon: 'triangle-exclamation-regular',
	iconColor: 'attention',
	iconV2Color: 'warning-strong',
	isOpen: true,
	primaryActionText: $.__('Yes, Disable Link'),
};
