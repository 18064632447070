import './eeo-1-header.styl';

import { BodyText, Headline } from '@bamboohr/fabric';
import { ExternalLink, Message } from '@bamboohr/utils/lib/message';

export function EEO1Header(props) {
	const { isSettingsPage, settingsPageId } = props;

	return (
		<header className='EEO1Header'>
				<Headline size='small' color='neutral-strong'>
					{$.__('Categorize Your Job Titles')}
				</Headline>
				<BodyText size='small' color='neutral-weak'>
					{_getHeaderInfo(isSettingsPage, settingsPageId)}
				</BodyText>
		</header>
	);
}

function _getHeaderInfo(isSettingsPage, settingsPageId) {
	if (isSettingsPage) {
		return $.__('To give you the data you need, we need to know the right EEO categories for your job titles.');
	}

	return (
		<Message
			link={ExternalLink(`/settings/list.php?id=${settingsPageId}`)}
			text={$.__(
				'To give you the data you need in this report, we need to know the right EEO categories for your job titles. If necessary, you can edit your job titles in [settings].'
			)}
		/>
	);
}
