export function formatDirectDepositAmount(value: string, flatOrPercent: string): string {
	// It appears existing behavior for both '$' and '%' direct deposit amounts is to only work properly with a number format of '123,456.78'.
	// To keep with this existing functionality, we will maintain that paradigm for now.
	// In the future, we might want to look at fixing the '%' case so that it works with the user's general settings 'Number format'.
	const decimalPointChar = '.';
	const thousandsSeparatorChar = ',';

	// Remove all negative signs
	value = value.replaceAll('-', '');

	// Remove any decimal points beyond the first one encountered
	let isFirstDecimal = true;
	value = value.replaceAll(decimalPointChar, (match) => {
		if (!isFirstDecimal) {
			return '';
		}

		isFirstDecimal = false;
		return match;
	});

	const amount = Number(value.replaceAll('$', '').replaceAll('%', '').replaceAll(thousandsSeparatorChar, '').replaceAll(decimalPointChar, '.'));

	if (flatOrPercent === 'PCT') {
		value = String(amount);

		if (!value.includes('.')) {
			value += '.00';
		} else if (value.split('.')[1].length > 2) {
			value = value.substring(0, value.indexOf('.') + 3);
		}

		value += '%';

		// Cap percentages at 100%
		if (amount > 100) {
			value = '100%';
		}
	} else if (flatOrPercent === 'FLAT') {
		value = String(amount);
		if (!value.includes('.')) {
			value += '.00';
		} else {
			if (value.split('.')[1].length < 1) {
				value += "00";
			}
			if (value.split('.')[1].length < 2) {
				value += "0";
			}
			if (value.split('.')[1].length > 2) {
				value = value.substring(0, value.indexOf('.') + 3);
			}
		}
		// If value has more than 3 digits left of the decimal (eg 1000.00, which has 7 chars), add thousands separators
		if (value.length > 6) {
			let thousandsSeparatorIndex = value.indexOf('.')
			while (thousandsSeparatorIndex > 3) {
				thousandsSeparatorIndex -= 3;
				value = value.slice(0, thousandsSeparatorIndex) + thousandsSeparatorChar + value.slice(thousandsSeparatorIndex);
			}
		}
		value = '$' + value;
	}

	// Empty out zero amounts
	if (amount === 0) {
		value = '';
	}

	return value;
}

export function getPercentOrFlat(symbol) {
	return symbol === '%' ? 'PCT' : 'FLAT';
}
