import { ReactElement } from 'react';
import { Wizard } from '@bamboohr/fabric';

interface Props {
	pageNames: Array<string>;
	currentPage: number;
}

export function HeaderNavigation(props: Props): ReactElement {
	const { pageNames, currentPage } = props;

	const wizardSteps = {};

	pageNames.forEach((pageName, ind) => {
		const stepNumber = ind + 1;
		const step = { name: pageName };
		if (!wizardSteps[stepNumber] && ind < pageNames.length - 1) {
			wizardSteps[stepNumber] = step;
		}
	});

	return <Wizard currentStep={currentPage + 1} steps={wizardSteps} />;
}
