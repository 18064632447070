import { useEffect, useState } from 'react';
import { Button, SheetModal, TextButton } from '@bamboohr/fabric';
import getCompanyInfo from 'getCompanyInfo.service';

interface ConfirmationSheetProps {
	isOpen: boolean;
	isProcessing?: boolean;
	onRequestClose: () => void;
	onSubmit: () => void;
	productName?: string;
}

export const ConfirmationSheet = ({
	isOpen,
	isProcessing,
	onRequestClose,
	onSubmit,
	productName,
}: ConfirmationSheetProps) => {
	const [companyName, setCompanyName] = useState('');

	useEffect(() => {
		getCompanyInfo().then((info) => {
			setCompanyName(info?.name || '');
		});
	}, []);

	const biId = 'in-app-cancellation-confirmation-sheet';
	const headline = productName
		? $.__(
				'Are you sure you want to cancel %s for %s?',
				productName,
				companyName,
		  )
		: $.__(
				'Are you sure you want to submit this cancellation request for %s?',
				companyName,
		  );
	return (
		<SheetModal
			isOpen={isOpen}
			isProcessing={isProcessing}
			onRequestClose={onRequestClose}
		>
			<SheetModal.Body
				renderFooter={
					<SheetModal.Footer
						actions={[
							<TextButton
								data-bi-id={`${biId}-cancel-button`}
								key={`${biId}-cancel-button`}
								onClick={onRequestClose}
								type="button"
							>
								{$.__('Cancel')}
							</TextButton>,
							<Button
								data-bi-id={`${biId}-submit-button`}
								key={`${biId}-sheet-submit-button`}
								onClick={onSubmit}
								type="button"
							>
								{$.__('Submit Cancellation Request')}
							</Button>,
						]}
					/>
				}
				renderHeader={<SheetModal.Header title={$.__('Just Checking...')} />}
			>
				<SheetModal.Constraint>
					<SheetModal.HeroHeadline
						icon="triangle-exclamation-solid"
						text={headline}
						subtext={$.__(
							'After submitting you will receive an email with instructions for exporting your data.',
						)}
					/>
				</SheetModal.Constraint>
			</SheetModal.Body>
		</SheetModal>
	);
};
