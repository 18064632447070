import { BodyText, LayoutBox } from '@bamboohr/fabric';
import { useState } from 'react';
import { Modal } from 'modal-legacy';
import { showModal, closeModal } from 'show-modal.mod';
import { doResetEmployeeMFALogin } from '../../service';
import { hideManageNavMfaOptions } from '../../manage-nav-mfa-options';

export function MfaResetModal(props) {
	const {
		userId,
		name,
		onReset = () => {},
		isResettingOwnMfa,
	} = props;

	const [isProcessing, setIsProcessing] = useState(false);

	return (
		<Modal
			content={
				<LayoutBox marginX={3}>
					<BodyText justify="center">
						{ name
							? $.__('The next time %s logs in, they will be directed to set up an authentication app and be able to scan the QR code.', name)
							: $.__('The next time you log in, you will be directed to set up an authentication app and be able to scan the QR code.')
						}
					</BodyText>
				</LayoutBox>
			}
			headline={ name ? $.__(`Reset %s's 2-Step Login?`, name) : $.__('Reset Your 2-Step Login?') }
			icon="lock-regular"
			iconV2Color="primary-strong"
			isOpen={ true }
			isProcessing={ isProcessing }
			onClose={ () => closeModal() }
			primaryAction={ () => {
				setIsProcessing(true);
				doResetEmployeeMFALogin(userId)
					.then(() => {
						onReset();
						setIsProcessing(false);
						const successMsg = name ?
							$.__('2-Step Login has been reset for %1$s', name) :
							$.__('2-Step Login has been reset.');
						window.setMessage(successMsg, 'success');
						// If user is resetting their own MFA login, we should hide the MFA related options in the manage-nav.
						if (userId === window.SESSION_USER?.id || isResettingOwnMfa) {
							hideManageNavMfaOptions();
						}
						closeModal();
					})
					.catch(() => {
						setIsProcessing(false);
						window.setMessage($.__('Uh-oh. Something went wrong, please try again!'), 'error');
					});
			} }
			primaryActionText={ $.__('Yes, Reset') }
			title={ $.__('Just making sure...') }
		/>
	);
}

export const showMfaResetModal = props => showModal(
	<MfaResetModal {...props} />
);
