import { FunctionComponent } from 'react';

type Props = {
	isObfuscated: boolean;
	type: string;
	customClasses?: string;
} | {
	isObfuscated?: boolean;
	type?: string;
	customClasses?: string;
};

const FieldDiv: FunctionComponent<React.PropsWithChildren<Props>> = ({
	customClasses = '',
	isObfuscated = false,
	type,
	children,
}) => {

	if (isObfuscated) {
		customClasses += `js-input-obfuscated FormInputObfuscated FormInputObfustcated--${ type }`;
	}
	return <div className={ `fieldDiv ${ customClasses }` }>{ children }</div>;
};

export default FieldDiv;
