import { IconV2 } from '@bamboohr/fabric';

import { BEM, MAX_VISIBLE_RECIPIENTS } from './constants';
import { Recipients } from './components/recipients.react';

const formatDate = (date) => {
	const momentDate = moment(date);
	return (
		<span>
			{momentDate.format('MM/DD/YYYY')}
			<span className={BEM.elem('time')}>{momentDate.format('h:mm A')}</span>
		</span>
	);
};

export const getColumnsData = (openDisableLinkConfirmation) => {
	return [
		{
			cell: (row) => row.sentDate,
			header: $.__('Link Sent'),
			key: 'sentDate',
		},
		{
			cell: (row) => row.expireDate,
			header: $.__('Link Expires'),
			key: 'expireDate',
		},
		{
			cell: (row) => row.timesDownloaded,
			header: $.__('Downloads'),
			key: 'timesDownloaded',
			width: 120,
		},
		{
			cell: (row) => row.emails,
			header: $.__('Recipients'),
			key: 'emails',
			width: 186,
		},
		{
			cell: {
				type: 'actions',
				actions: (row) => [
					{
						action: () => openDisableLinkConfirmation(row.sfsId),
						icon: 'trash-regular',
						tooltipContent: $.__('Disable Link'),
					},
				],
			},
		},
	];
};

export const getRowsData = (data, expandedIndices, updateExpandedIndices) => {
	return data.map((row, i) => {
		const {
			emails: recipients,
			expireDate,
			sentDate,
			sfsId,
			timesDownloaded,
		} = row;
		const key = `recipient-row-${sfsId}-${i}`;
		const isExpandable = recipients.length > MAX_VISIBLE_RECIPIENTS;
		const isExpanded = !isExpandable || expandedIndices.indexOf(i) >= 0;

		const recipientProps = {
			isExpandable,
			isExpanded,
			rowKey: key,
			recipients: [...recipients],
			onButtonClick: () => {
				let list = expandedIndices;
				if (isExpanded) {
					list = list.filter((expandedIndex) => expandedIndex !== i);
				} else {
					list = list.concat(i);
				}
				updateExpandedIndices(list);
			},
		};

		const sentDateComponent = sentDate ? (
			formatDate(sentDate)
		) : (
			<div className={BEM.elem('sendingStatus')}>
				<IconV2 name="hourglass-regular" size={12} />
				<span>{$.__('Sending...')}</span>
			</div>
		);

		return {
			key,
			emails: <Recipients {...recipientProps} />,
			expireDate: formatDate(expireDate),
			sentDate: sentDateComponent,
			sfsId,
			timesDownloaded,
		};
	});
};

export const getDetailButtonCopy = (count) => {
	return count > 1
		? $.__('Secure Download Links')
		: $.__('Secure Download Link');
};
