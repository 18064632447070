import { Dropdown, DropdownTypes } from '@bamboohr/fabric';

import { getItems } from './utils';
import { CancellationDropdownProps } from './types';

export const CancellationDropdown = ({
	cancellationData,
	handleOnSelect,
	title,
	biId,
}: CancellationDropdownProps) => {
	return (
		<Dropdown
			ButtonProps={{
				ariaLabel: $.__('Cancellation'),
				color: 'secondary',
				icon: 'gear-regular',
				size: 'small',
				variant: 'outlined',
			}}
			items={getItems(cancellationData, title)}
			onSelect={handleOnSelect}
			type={DropdownTypes.ICON}
		/>
	);
};
