
import { getJsonData } from 'Data.util';
import { formatStringFromNumber, getNumberFromString } from 'string-helpers';

const DEFAULT_PAY_SCHEDULE_HOURS_PER_WEEK = 40;
const SELECTOR_HOURS_PER_WEEK_CONTAINER = '.HoursPerWeekField__container';
const SELECTOR_HOURS_PER_WEEK_DEFAULT_CONTAINER = '.HoursPerWeekField__defaultContainer';
const SELECTOR_HOURS_PER_WEEK_DEFAULT_TEXT = '.HoursPerWeekField__defaultText';
const SELECTOR_HOURS_PER_WEEK_FIELD_DATA = '#hoursPerWeekField';
const SELECTOR_HOURS_PER_WEEK_HELP_BUTTON = '.HoursPerWeekField__helpButton';
const SELECTOR_HOURS_PER_WEEK_INPUT = 'input[name="hoursPerWeek"]';
const SELECTOR_HOURS_PER_WEEK_INPUT_CONTAINER = '.HoursPerWeekField__inputContainer';
const SELECTOR_HOURS_PER_WEEK_POPOVER_BUTTON = `.HoursPerWeekField__popoverButton`;
const SELECTOR_PAY_SCHEDULE_HOURS_MAP_DATA = '#payScheduleHoursPerWeekMap';
const SELECTOR_PAY_SCHEDULE_SELECT = '.js-paySchedule';
const SELECTOR_PAY_SCHEDULE_SELECTED_OPTION = `${SELECTOR_PAY_SCHEDULE_SELECT} ba-option[selected]`;
const SELECTOR_PAY_TYPE_SELECT = '.js-payType';
const SELECTOR_PAY_TYPE_SELECTED_OPTION = `${SELECTOR_PAY_TYPE_SELECT} ba-option[selected]`;

function createHoursPerWeekHelpPopover() {
	document.body.style.top = ''; // Remove negative body top style added by the modal so that the popover positions correctly.
	
	const payScheduleHoursPerWeek = getPayScheduleHoursPerWeek();
	const payScheduleHoursPerWeekFormatted = getPayScheduleHoursPerWeekFormatted();
	let helpText = $.__n(
			`This employee's pay schedule has a default of %2$s salaried hour per week.`,
			`This employee's pay schedule has a default of %2$s salaried hours per week.`,
			payScheduleHoursPerWeek,
			payScheduleHoursPerWeekFormatted,
		);
	let buttonText = $.__(`Edit This Employee's Salaried Hours`);
	let biId = 'update-compensation-modal-hours-per-week-edit-button';
	if (isShowingHoursPerWeekField()) {
		helpText = $.__('This employee is using custom salary hours. We will use this value instead of the pay schedule default.');
		buttonText = $.__(`Use Pay Schedule Default Instead`);
		biId = 'update-compensation-modal-hours-per-week-use-default-button';
	}

	const popover = BambooHR.Components.Popover.create(SELECTOR_HOURS_PER_WEEK_HELP_BUTTON, {
		content: `
			<div class="hoursPerWeekPopoverText">${helpText}</div>
			<button class="fab-TextButton fab-Link HoursPerWeekField__popoverButton" data-bi-id="${biId}" type="button">${buttonText}</button>
		`,
		title: $.__('Salaried Hours Per Week'),
		click: true,
		persistent: false,
		contentCssOverride: {
			maxWidth: '260px',
		},
	});

	popover.onShow(() => {
		const elementHoursPerWeekPopoverButton = document.querySelector(SELECTOR_HOURS_PER_WEEK_POPOVER_BUTTON);
		elementHoursPerWeekPopoverButton.removeEventListener('click', handleHoursPerWeekPopoverButtonClick);
		elementHoursPerWeekPopoverButton.addEventListener('click', handleHoursPerWeekPopoverButtonClick);
		elementHoursPerWeekPopoverButton.focus();
	});

	return popover;
}

function destroyHoursPerWeekHelpPopover() {
	const popover = getHoursPerWeekHelpPopover();
	if (popover) {
		popover.destroy()
	}
}

function formatHoursPerWeekInputValue(maxDecimalPlaces) {
	const hoursPerWeekValue = getHoursPerWeekInputValue();

	const hoursPerWeekNumber = getNumberFromString(hoursPerWeekValue, undefined, maxDecimalPlaces);
	const hoursPerWeekInputValue = formatStringFromNumber(hoursPerWeekNumber);

	setHoursPerWeekInputValue(hoursPerWeekInputValue);
}

function getHoursPerWeekHelpPopover() {
	return BambooHR.Components.Popover.selectPopovers(SELECTOR_HOURS_PER_WEEK_HELP_BUTTON)[0];
}

function getHoursPerWeekInputValue() {
	return document.querySelector(SELECTOR_HOURS_PER_WEEK_INPUT).value || '';
}

function getPayScheduleHoursPerWeek() {
	const payScheduleHoursPerWeekMap = getPayScheduleHoursPerWeekMap();
	const payScheduleId = getSelectedPayScheduleId();
	return payScheduleHoursPerWeekMap?.[payScheduleId] || DEFAULT_PAY_SCHEDULE_HOURS_PER_WEEK;
}

function getPayScheduleHoursPerWeekFormatted() {
	return formatStringFromNumber(getPayScheduleHoursPerWeek());
}

function getPayScheduleHoursPerWeekMap() {
	return getJsonData(SELECTOR_PAY_SCHEDULE_HOURS_MAP_DATA, true);
}

function getSelectedPayScheduleId() {
	return document.querySelector(SELECTOR_PAY_SCHEDULE_SELECTED_OPTION)?.getAttribute('value');
}

function getSelectedPayTypeValue() {
	return document.querySelector(SELECTOR_PAY_TYPE_SELECTED_OPTION)?.getAttribute('value');
}

function getSelectedPayScheduleIsSyncing() {
	return document.querySelector(SELECTOR_PAY_SCHEDULE_SELECTED_OPTION)?.getAttribute('data-syncing-pay-schedule') === 'true';
}

function handleHoursPerWeekBlur() {
	formatHoursPerWeekInputValue(2);
}

function handleHoursPerWeekHelpButtonClick() {
	destroyHoursPerWeekHelpPopover();
	const popover = createHoursPerWeekHelpPopover();

	setTimeout(() => {
		if (!popover.showing) {
			popover.show();
		}
	}, 0);
}

function handleHoursPerWeekPopoverButtonClick() {
	destroyHoursPerWeekHelpPopover();
	if (isShowingHoursPerWeekField()) {
		showHoursPerWeekDefaultContainer();
		hideHoursPerWeekInputContainer();
	} else {
		if (!getHoursPerWeekInputValue()) {
			setHoursPerWeekInputValue(getPayScheduleHoursPerWeekFormatted());
		}
		showHoursPerWeekInputContainer();
		hideHoursPerWeekDefaultContainer();
		document.querySelector(SELECTOR_HOURS_PER_WEEK_INPUT).focus();
	}
}

function handlePayScheduleChange() {
	setHoursPerWeekInputValue('');
	syncHoursPerWeekField();
}

function handlePayTypeChange() {
	setHoursPerWeekInputValue('');
	syncHoursPerWeekField();
}

function hasHoursPerWeekField() {
	return !!getJsonData(SELECTOR_HOURS_PER_WEEK_FIELD_DATA, true);
}

function hideElement(selector) {
	const element = document.querySelector(selector);
	if (element) {
		element.classList.add('hidden');
	}
}

function hideHoursPerWeekContainer() {
	hideElement(SELECTOR_HOURS_PER_WEEK_CONTAINER);
	hideHoursPerWeekInputContainer();
}

function hideHoursPerWeekDefaultContainer() {
	hideElement(SELECTOR_HOURS_PER_WEEK_DEFAULT_CONTAINER);
}

function hideHoursPerWeekInputContainer() {
	setHoursPerWeekInputValue('');
	document.querySelector(SELECTOR_HOURS_PER_WEEK_INPUT).dataset.validationAllowEmpty = true;
	hideElement(SELECTOR_HOURS_PER_WEEK_INPUT_CONTAINER);
}

export function initializeHoursPerWeekField() {
	if (hasHoursPerWeekField()) {
		const elementPayScheduleSelect = document.querySelector(SELECTOR_PAY_SCHEDULE_SELECT);
		if (elementPayScheduleSelect) {
			elementPayScheduleSelect.addEventListener('change', handlePayScheduleChange);
		}

		const elementPayTypeSelect = document.querySelector(SELECTOR_PAY_TYPE_SELECT);
		if (elementPayTypeSelect) {
			elementPayTypeSelect.addEventListener('change', handlePayTypeChange);
		}

		const elementHoursPerWeekInput = document.querySelector(SELECTOR_HOURS_PER_WEEK_INPUT);
		if (elementHoursPerWeekInput) {
			elementHoursPerWeekInput.addEventListener('change', handleHoursPerWeekBlur);
		}

		document.querySelector(SELECTOR_HOURS_PER_WEEK_HELP_BUTTON).addEventListener('click', handleHoursPerWeekHelpButtonClick);

		syncHoursPerWeekField();
	}
}

function isShowingHoursPerWeekField() {
	const elementHoursPerWeekInputContainer = document.querySelector(SELECTOR_HOURS_PER_WEEK_INPUT_CONTAINER);
	return elementHoursPerWeekInputContainer && !elementHoursPerWeekInputContainer.classList.contains('hidden');
}

function setHoursPerWeekInputValue(value) {
	document.querySelector(SELECTOR_HOURS_PER_WEEK_INPUT).value = value;
}

function showElement(selector) {
	const element = document.querySelector(selector);
	if (element) {
		element.classList.remove('hidden');
	}
}

function showHoursPerWeekContainer() {
	showElement(SELECTOR_HOURS_PER_WEEK_CONTAINER);

	if (getHoursPerWeekInputValue()) {
		showHoursPerWeekInputContainer();
		hideHoursPerWeekDefaultContainer();
	} else {
		showHoursPerWeekDefaultContainer();
		hideHoursPerWeekInputContainer();
	}
}

function showHoursPerWeekDefaultContainer() {
	document.querySelector(SELECTOR_HOURS_PER_WEEK_DEFAULT_TEXT).innerText = $.__('%1$s hours per week', getPayScheduleHoursPerWeekFormatted());
	showElement(SELECTOR_HOURS_PER_WEEK_DEFAULT_CONTAINER);
}

function showHoursPerWeekInputContainer() {
	document.querySelector(SELECTOR_HOURS_PER_WEEK_INPUT).dataset.validationAllowEmpty = false;
	showElement(SELECTOR_HOURS_PER_WEEK_INPUT_CONTAINER);
}

function syncHoursPerWeekField() {
	const isPayScheduleSyncing = getSelectedPayScheduleIsSyncing();
	const isPayTypeSalary = getSelectedPayTypeValue() === 'Salary';

	if (isPayScheduleSyncing && isPayTypeSalary) {
		showHoursPerWeekContainer();
	} else {
		hideHoursPerWeekContainer();
	}
}
