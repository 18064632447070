import {
	BlankState,
	Divider,
	Flex,
	Headline,
	LayoutBox,
	Section,
	TaskProgressBar
} from '@bamboohr/fabric';
import { PacketTask } from 'NHPTemplates.mod/Form/store/Interfaces';
import { ReactElement, useMemo } from 'react';
import { TaskItem } from './tasks-components/task-item/task-item';

interface Props {
	isNHPT: boolean;
	tasks: Array<PacketTask>;
}

export function Tasks(props: Props): ReactElement {
	const { isNHPT, tasks = [] } = props;

	const [completed, numberOfTasks] = useMemo(() => {
		let completedTasks = 0;
		tasks.forEach((task) => {
			if (task.completed) {
				completedTasks++
			}
		});
		return [completedTasks, tasks.length];
	}, [tasks]);

	const sortedTasks = useMemo(
		() => [...tasks].sort((a, b) => +a.nhpSortOrder - +b.nhpSortOrder),
		[tasks],
	);

	return (
		<Section>
			<Flex flexDirection="column">
				<Section.Header
					description={$._(
						`We want to make sure that you are ready to go on your first day. Please complete the tasks below so we can get you up and running quickly.`,
					)}
					title={<Headline size="medium">{$._('Tasks to Complete')}</Headline>}
				/>
				<Divider
					color="neutral-extra-weak"
					marginBottom="24px"
					marginTop="16px"
				/>
				{isNHPT && (
					<LayoutBox paddingTop="40px">
						<BlankState
							icon="bs-tumbleweed"
							subtitle={$.__(
								'You’ll be able to preview this step on the actual New Hire Packet after tasks are added.',
							)}
							title={$.__(
								`Because this is just the template, there aren’t any tasks to preview.`,
							)}
						/>
					</LayoutBox>
				)}
				{!isNHPT && (
					<LayoutBox>
						<TaskProgressBar
							current={completed}
							note={$._('Completed')}
							total={numberOfTasks}
							width={160}
						/>
						<Divider marginTop={3} />
						<Flex flexDirection="column" flexGrow={1}>
							{sortedTasks.map((task, index) => {
								return (
									<TaskItem
										index={index}
										key={`task-${task.name}`}
										task={task}
									/>
								);
							})}
						</Flex>
					</LayoutBox>
				)}
			</Flex>
		</Section>
	);
}
