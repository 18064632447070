import './eeo-1-categories-modal.styl';

/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';

import { EEO1Category } from './eeo-1-category';
import { EEO1Header } from './eeo-1-header';
import { LayoutBox } from '@bamboohr/fabric';
import { Modal } from 'modal-legacy';
import { submitCategories } from './eeo-1-categories-modal.service';

export function EEO1CategoriesModal(props) {
	const { canAutoOpen = true, data: dataProp, isOpen: isOpenProp, isSettingsPage, onClose, onCloseAfterSave } = props;

	if (!dataProp) {
		return null;
	}

	const [data, setData] = useState(dataProp);
	const { categories, jobTitles, settingsPageId } = data;

	const [mappedJobTitlesToCategories, setMappedJobTitlesToCategories] = useState(_getMappedJobTitlesToCategories(categories));

	const [isAutoOpen, setIsAutoOpen] = useState(false);

	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (canAutoOpen) {
			setIsOpen(_shouldAutoOpen(jobTitles, isAutoOpen, setIsAutoOpen));
		}
	}, []);

	useEffect(() => {
		if (isOpenProp) {
			setIsOpen(isOpenProp);
		}
	}, [isOpenProp]);

	const modalContent = (
		<LayoutBox padding={2}>
			<EEO1Header isSettingsPage={isSettingsPage} settingsPageId={settingsPageId} />
			<div className='EEO1CategoriesModal__body'>
				{categories.allIds.map((categoryId) => (
					<EEO1Category
						key={categoryId}
						jobTitles={jobTitles}
						onChange={(item) => _handleChange(item, categoryId, data, setData, setMappedJobTitlesToCategories)}
						{...categories.byId[categoryId]}
					/>
				))}
			</div>
		</LayoutBox>
	);

	return (
		<Modal
			{..._getModalConfig(
				isAutoOpen,
				setIsAutoOpen,
				isOpen,
				setIsOpen,
				onClose,
				mappedJobTitlesToCategories,
				jobTitles,
				() => setData(dataProp),
				onCloseAfterSave
			)}
		>
			{modalContent}
		</Modal>
	);
}

function _shouldAutoOpen(jobTitles, isAutoOpen, setIsAutoOpen) {
	const hasNotMappedJobTitles = _hasNotMappedJobTitles(jobTitles);
	if (!isAutoOpen && hasNotMappedJobTitles) {
		setIsAutoOpen(true);
	}

	return hasNotMappedJobTitles;
}

function _hasNotMappedJobTitles(jobTitles) {
	return jobTitles.allIds.every((id) => !jobTitles.byId[id] || !jobTitles.byId[id].selected);
}

function _hasSomeMappedJobTitles(jobTitles) {
	return jobTitles.allIds.some((id) => !jobTitles.byId[id] || !jobTitles.byId[id].selected);
}

function _getMappedJobTitlesToCategories(categories) {
	const mappedJobTitlesToCategories = {};

	categories.allIds.forEach((id) => {
		mappedJobTitlesToCategories[id] = {
			categoryId: categories.byId[id].id,
			jobTitleIds: categories.byId[id].jobTitleIds,
		};
	});

	return mappedJobTitlesToCategories;
}

function _getModalConfig(
	isAutoOpen,
	setIsAutoOpen,
	isOpen,
	setIsOpen,
	onClose,
	mappedJobTitlesToCategories,
	jobTitles,
	resetData,
	onCloseAfterSave
) {
	const handleClose = _handleClose(setIsAutoOpen, setIsOpen, onClose, resetData);

	const handleSave = _handleSave(mappedJobTitlesToCategories, jobTitles, () => {
		setIsOpen(false);
		onClose(false);
		if (isAutoOpen) {
			setIsAutoOpen(false);
		}
		if (onCloseAfterSave) {
			onCloseAfterSave();
		}
	});

	const title = $.__('Set up EEO-1 Categories');

	return {
		contentHasMaxHeight: false,
		contentHasPadding: false,
		isOpen,
		onClose: handleClose,
		primaryAction: handleSave,
		title,
	};
}

function _getUpdatedData(categoryId, data, item) {
	const updatedData = data;

	// If the item is undefined then clear out
	// all selections for the given category
	if (!item) {
		const category = updatedData.categories.byId[categoryId];

		category.jobTitleIds.forEach((id) => {
			updatedData.jobTitles.byId[id].selected = false;
		});

		category.jobTitleIds = [];

		return updatedData;
	}

	// Update the jobTitles that were selected and the
	// category jobTitleIds array with those selections
	updatedData.jobTitles.allIds.forEach((id) => {
		const category = updatedData.categories.byId[categoryId];
		const jobTitle = updatedData.jobTitles.byId[id];

		if (item && item.value === jobTitle.id) {
			const selected = !jobTitle.selected;
			jobTitle.selected = selected;

			if (selected) {
				category.jobTitleIds.push(item.value);
			} else {
				category.jobTitleIds = category.jobTitleIds.filter((jobTitleId) => jobTitleId !== item.value);
			}
		}
	});

	return updatedData;
}

function _getUnmappedJobTitleCountMessage(unmappedJobTitleCount) {
	if (unmappedJobTitleCount === 1) {
		return $.__('1 job title still needs an EEO category.');
	}

	return $.__('%1 job titles still need EEO categories.', unmappedJobTitleCount, {
		note: 'The "%1" placeholder is the number of job titles.',
	});
}

function _getUnmappedJobTitleCount(jobTitles) {
	return jobTitles.allIds.filter((id) => !jobTitles.byId[id] || !jobTitles.byId[id].selected).length;
}

function _setMappedJobTitleSuccessMessage(jobTitles) {
	const unmappedJobTitleCount = _getUnmappedJobTitleCount(jobTitles);

	if (_hasSomeMappedJobTitles(jobTitles)) {
		window.setMessage(
			`
				${$.__('EEO Categories assigned but jobs left behind:')}
				${_getUnmappedJobTitleCountMessage(unmappedJobTitleCount)}
			`,
			'error'
		);
	} else {
		window.setMessage($.__('EEO Categories Assigned'), 'success');
	}
}

function _handleChange(item, categoryId, data, setData, setMappedJobTitlesToCategories) {
	const updatedData = _getUpdatedData(categoryId, data, item);
	const updatedMappedJobTitlesToCategoriesData = _getMappedJobTitlesToCategories(updatedData.categories);

	setData(updatedData);
	setMappedJobTitlesToCategories(updatedMappedJobTitlesToCategoriesData);
}

function _handleClose(setIsAutoOpen, setIsOpen, onClose, resetData) {
	return () => {
		setIsAutoOpen(false);
		setIsOpen(false);
		onClose(false);
		resetData();
	};
}

function _handleSave(data, jobTitles, onClose) {
	return () => _submitData(data, jobTitles, onClose);
}

function _submitData(data, jobTitles, onClose) {
	const payload = { categories: data };

	return submitCategories(payload).then(
		() => {
			onClose();
			_setMappedJobTitleSuccessMessage(jobTitles);
		},
		(error) => {
			window.setMessage(error, 'info');
			console.error(error);

			return Promise.reject('Failed to submit');
		}
	);
}
