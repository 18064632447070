import { FunctionComponent } from 'react';
import MaskedInput from 'react-text-mask';
import { commonInputMasks } from './input-masks';
import { stringContainsAllWords } from 'String.util';
import { Props } from './text';
import {
	getClassBasedValidation,
	isRequired,
	handleControlledInput,
	fixValidationRegisterName,
} from 'dynamic-form';

export const getInputMask = (className = '', isEditable = true) => {
	let mask = null;
	Object.keys(commonInputMasks).forEach((maskClass) => {
		if (
			className.includes(maskClass) ||
			stringContainsAllWords(className, maskClass.split(' '))
		) {
			const maskType = isEditable
				? commonInputMasks[maskClass]
				: commonInputMasks[`${ maskClass }-sensitive`] || commonInputMasks[maskClass];

			mask = maskType;
		}
	});

	return mask;
};

const TextMaskedField: FunctionComponent<React.PropsWithChildren<Props>> = (textMaskedProps) => {
	const { props, context } = textMaskedProps;
	const { className = '', id, name, value = '' } = props;

	const {
		form: { isEditable = true },
		validation: { register, setValue },
	} = context;

	// re-register every time this component re-renders so the dynamic isRequired will be updated
	const validationName = fixValidationRegisterName(name);
	register(validationName, { ...getClassBasedValidation(context, textMaskedProps), required: isRequired(context, textMaskedProps) });

	const handleOnChange = (e): void => {
		handleControlledInput('text', id, e.target.value, context);
		setValue(validationName, e.target.value, {
			shouldValidate: true,
			shouldDirty: true,
		});
	};

	const maskType = getInputMask(className, isEditable);
	return (
		<MaskedInput
			mask={ maskType }
			{ ...props }
			onBlur={ handleOnChange }
		/>
	);
};

export default TextMaskedField;
