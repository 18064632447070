import {
    FunctionComponent,
    useEffect,
    useState
} from 'react';
import {
    IconV2,
    TextButton
} from '@fabric/button';
import DependentGroup from './dependent-group';
import {
    addRepeatableField,
    removeRepeatableField,
    findOne,
    isNewEmployeePage
} from 'dynamic-form';
import DeleteDependentModal from '../modals/delete-dependent-modal';
import { deleteEmployeeDependent } from '../api';
import {
    DependentBlankDefault,
    DependentBlankNewEmployee
} from './dependent-blank';

const DependentRepeatContainer: FunctionComponent<React.PropsWithChildren<DynamicForm.RepeatContainerElementProps>> = ({
                                                                                                         props,
                                                                                                         settings,
                                                                                                         context,
                                                                                                         children,
                                                                                                     }) => {
    const { id, disabled } = props;
    const { canAdd, displayInitialEmpty, label } = settings;
    const [deleteField, setDeleteField] = useState<false | string>(false);
    const [initialAdded, setInitialAdded] = useState(false);
    const BlankStateComponent = isNewEmployeePage() ? DependentBlankNewEmployee : DependentBlankDefault;
    let addTemplateId: string;
    let childrenWithoutProtoLength = 0;

    const {
        validation,
    } = context;

    const {
        unregister,
    } = validation;

    const getDeleteData = (groupId: string) => {
        const deleteModalData = {
            contactName: '',
            contactRelationship: '',
        };
        const dependentId = groupId.split('_').pop();
        const nameField = findOne(context, { selector: 'key', value: dependentId }, { selector: 'props.className', value: 'nameInput' });
        const relationshipField = findOne(context, { selector: 'key', value: dependentId }, { selector: 'props.className', value: 'list-field' }) as DynamicForm.SelectElement;
        deleteModalData.contactName = nameField ? nameField.props.value.toString() : '';
        deleteModalData.contactRelationship = '';
        if (relationshipField) {
            const { items } = relationshipField.props;
            const selectedItem = items.find(item => item.value === relationshipField.props.selectedValues[0]);
            deleteModalData.contactRelationship = selectedItem ? selectedItem.text : '';
        }
        return deleteModalData;
    };

    const handleAdd = (): void => {
        if (!addTemplateId) {
            console.error(`Dynamic Form: Error getting add template for repeating container with id ${ id }`);
            return;
        }
        addRepeatableField(id, addTemplateId, context);
    };

    const handleRemove = (groupId: string): void => {
        if (groupId.includes('add')) {
            removeRepeatableField(id, groupId, context);
        } else {
            setDeleteField(groupId);
        }
    };

    const handleDeleteModalClose = (): void => {
        setDeleteField(false);
    };

    const handleDeleteModalAction = (): void => {
        if (typeof deleteField === 'string') {
            const groupId = deleteField;
            const dependentId = groupId.split('_').pop();
            deleteEmployeeDependent(window.currentlyEditingEmployeeId.toString(), dependentId)
                .then(({ data = {} }) => {
                    if (!data.success) {
                        if (data.error) {
                            window.setMessage(data.error, 'error');
                        }
                        return;
                    }
                    unregister(groupId);
                    removeRepeatableField(id, groupId, context);
                })
                .catch(({ data = {} }) => {
                    window.setMessage(data.error || data.message, 'error');
                });

        }
        setDeleteField(false);
    };

    const childrenWithoutPrototype = Array.isArray(children)
        ? children.map((child: React.ReactElement) => {
            if (
                child?.props?.props?.className?.includes('repeatPrototype')
            ) {
                addTemplateId = child.props.props.id;
                return null;
            }
            childrenWithoutProtoLength++;
            return (
                <DependentGroup
                    { ...child.props }
                    handleRemove={ handleRemove }
                    key={ child.props.props.id }
                />
            );
        })
        : children;

    const hasChildren = !!childrenWithoutProtoLength;

    useEffect(() => {

        if (addTemplateId && !hasChildren && displayInitialEmpty && !initialAdded) {
            return handleAdd();
        }
        setInitialAdded(true);
    }, [addTemplateId, displayInitialEmpty, hasChildren, initialAdded]);

    return (
        <div>
            { deleteField && (
                <DeleteDependentModal
                    data={ getDeleteData(deleteField) }
                    handleClose={ handleDeleteModalClose }
                    handlePrimaryAction={ handleDeleteModalAction }
                />
            ) }
            <div className="row repeat dependents" id={ id }>
                <div>
                    <input id="qf:dependent" name="_qf__dependent" type="hidden" />
                    { childrenWithoutPrototype }
                </div>

                { !hasChildren && (
                    <BlankStateComponent canAdd={ canAdd } onClick={ handleAdd } />
                ) }
                { hasChildren && canAdd && <TextButton
                    children={ $.__('Add Dependent') }
                    disabled={ !canAdd || disabled }
                    onClick={ handleAdd }
                    type="button"
                    startIcon={ <IconV2 name="circle-plus-regular" size={16}/> }
                /> }
            </div>
        </div>
    );
};

export default DependentRepeatContainer;
