import {
	BodyText,
	Button,
	Flex,
	FullScreenModal,
	LayoutBox,
	Link,
	StyledBoxV2,
	Svg,
	TextButton,
	createStyles,
	makeStyles
} from '@bamboohr/fabric';
import { ReactElement, useEffect, useState } from 'react';
import { PREVIEWER_MODAL_ID } from '../Previewer/previewer';

interface Props {
	totalPages: number;
	currentPage: number;
	nextPageAction: (number) => void;
	previousPageAction: (number) => void;
}

const styles = makeStyles(() => {
	return createStyles({
		footer: {
			display: 'flex',
			maxWidth: '',
			alignItems: 'center',
			justifyContent: 'flex-start',
			paddingLeft: '40px',
			width: '100%',
			margin: 'auto',
		},
		buttonsWrapper: {
			display: 'flex',
			alignItems: 'center',
			gap: '15px',
		},
		brand: {
			marginLeft: 'auto',
		},
	});
});

export function PreviewerFooter(props: Props): ReactElement {
	const { totalPages, currentPage, nextPageAction, previousPageAction } = props;
	const classes = styles();

	const nextPage =
		currentPage < totalPages - 1 ? (
			<Button
				onClick={() => {
					nextPageAction(currentPage + 1);
				}}
				size="biggie"
				type="button"
			>
				{$.__('Continue')}
			</Button>
		) : null;
	const previousPage =
		currentPage > 0 ? (
			<TextButton
				onClick={() => {
					previousPageAction(currentPage - 1);
				}}
				size="biggie"
			>
				{$.__('Previous Step')}
			</TextButton>
		) : null;
	const minFooterHeight = 99;
	const offset = 8;

	const [currentTop, setCurrentTop] = useState(minFooterHeight);
	const currentContentHeight = document
		.querySelector(`#${PREVIEWER_MODAL_ID} .fab-Card`)
		?.getBoundingClientRect()?.height;

	useEffect(() => {
		const contentRect = document
			.querySelector(`#${PREVIEWER_MODAL_ID} .fab-Card`)
			?.getBoundingClientRect();
		if (contentRect) {
			const maxTop = window.innerHeight - minFooterHeight;
			const newTop =
				contentRect.bottom < maxTop
					? maxTop - contentRect.bottom + minFooterHeight + offset
					: null;

			if (currentTop !== newTop) {
				setCurrentTop(newTop);
			}
		}
	}, [currentTop, currentContentHeight, currentPage]);

	const onLastPage = currentPage === totalPages - 1;
	const privacyLink = 'https://www.bamboohr.com/privacy-policy';
	const tosLink = 'https://www.bamboohr.com/terms-of-service';
	const currentYear = new Date().getFullYear();

	return (
		<div className="js-PreviewerActionFooter">
			{!onLastPage && (
				<FullScreenModal.Footer>
					<div className={classes.footer}>
						<div className={classes.buttonsWrapper}>
							{nextPage}
							{previousPage}
						</div>
						{}
					</div>
				</FullScreenModal.Footer>
			)}

			{onLastPage && (
				<FullScreenModal.Footer>
					<StyledBoxV2 borderRadius="medium" width="100%">
						<Flex justifyContent="space-between">
							<BodyText size="extra-small">
								<Link
									color="muted"
									href={privacyLink}
									rel="noopener noreferrer"
									target="_blank"
								>
									Privacy Policy
								</Link>{' '}
								·{' '}
								<Link
									color="muted"
									href={tosLink}
									rel="noopener noreferrer"
									target="_blank"
								>
									Terms of Service
								</Link>{' '}
								· ©2008–{currentYear} BambooHR All rights reserved.
							</BodyText>
							<LayoutBox>
								<Link
									color="muted"
									href="https://www.bamboohr.com"
									rel="noopener external nofollow noreferrer"
									target="_blank"
								>
									<Svg height="17px" image="bamboohr-logo" width="115px" />
								</Link>
							</LayoutBox>
						</Flex>
					</StyledBoxV2>
				</FullScreenModal.Footer>
			)}
		</div>
	);
}
