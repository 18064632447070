import { FunctionComponent } from 'react';

const HoverLink: FunctionComponent<React.PropsWithChildren<DynamicForm.Hover>> = ({
	hover,
	isEditable,
	context,
	children,
}) => {
	const {
		controls: { HoverBase },
	} = context;

	return (
		<HoverBase hoverChild={ children } isEditable={ isEditable }>
			<a
				className="HoverHint__link"
				href={ hover.link }
				rel="noopener noreferrer"
				target="_blank"
			>
				{ $.__('View') }
			</a>
		</HoverBase>
	);
};

export default HoverLink;
