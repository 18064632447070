import { useState } from 'react';
import { LayoutBox, SelectField, TextArea } from '@bamboohr/fabric';
import {
	CancellationContentProps,
	CancellationFormData,
	PayrollFormData,
} from '../../../../types';
import { ModalContentWrapper } from '../../../modal-content-wrapper';

interface Step2aFormValues {
	new_processing_method: PayrollFormData['new_processing_method'];
	new_processing_method_details: PayrollFormData['new_processing_method_details'];
	reason: CancellationFormData['reason'];
	reason_details: CancellationFormData['reason_details'];
}

export const FormA = ({
	onNext,
	reasons,
	...rest
}: CancellationContentProps): JSX.Element => {
	const [formValues, setFormValues] = useState<Step2aFormValues>({
		new_processing_method: null,
		new_processing_method_details: null,
		reason: null,
		reason_details: null,
	});

	const mergeFormValues = (values: Partial<Step2aFormValues>) => {
		setFormValues({ ...formValues, ...values });
	};

	const handleOnNext = () => {
		const { reason, reason_details, ...restFormValues } = formValues;
		onNext({ reason, reason_details, payroll_data: restFormValues });
	};

	const processingMethodDetailsRequired =
		formValues.new_processing_method?.toLowerCase() === 'other';
	const disableNextButton =
		!formValues.new_processing_method ||
		(processingMethodDetailsRequired &&
			!formValues.new_processing_method_details) ||
		!formValues.reason ||
		!formValues.reason_details;

	return (
		<ModalContentWrapper
			{...rest}
			disablePrimaryButton={disableNextButton}
			onNext={handleOnNext}
		>
			<LayoutBox marginBottom={2}>
				<SelectField
					id="new_processing_method"
					isClearable={false}
					items={reasons.payroll_new_processing_methods || []}
					label={$.__('What is your new payroll processing method?')}
					onChange={(e) =>
						mergeFormValues({ new_processing_method: e.target.value[0] })
					}
					required={true}
					showSearch="never"
					value={[formValues.new_processing_method || '']}
					width={6}
				/>
			</LayoutBox>
			<LayoutBox marginBottom={2}>
				<TextArea
					id="new_processing_method_details"
					label={$.__('Please provide any additonal details here')}
					minRows={3}
					onChange={(e) =>
						mergeFormValues({ new_processing_method_details: e.target.value })
					}
					required={processingMethodDetailsRequired}
					value={formValues.new_processing_method_details || ''}
					width={100}
				/>
			</LayoutBox>
			<LayoutBox marginBottom={2}>
				<SelectField
					id="reason"
					isClearable={false}
					items={reasons.payroll || []}
					label={$.__('Reason for cancelling payroll account')}
					onChange={(e) => mergeFormValues({ reason: e.target.value[0] })}
					required={true}
					showSearch="never"
					value={[formValues.reason || '']}
					width={100}
				/>
			</LayoutBox>
			<LayoutBox marginBottom={2}>
				<TextArea
					id="reason_details"
					label={$.__('Please provide any additonal details here')}
					minRows={3}
					onChange={(e) => mergeFormValues({ reason_details: e.target.value })}
					required={true}
					value={formValues.reason_details || ''}
					width={100}
				/>
			</LayoutBox>
		</ModalContentWrapper>
	);
};
