import './emergency-contact.styl';

import { EmergencyBlankDefault, EmergencyBlankNewEmployee } from './emergency-blank';
import { FunctionComponent, useEffect, useState } from 'react';
import { addRepeatableField, findOne, isNewEmployeePage, removeRepeatableField } from 'dynamic-form';

import DeleteEmergencyContactModal from '../modals/delete-emergency-contact-modal';
import EmergencyContactGroup from './emergency-contact-group';
import { IconV2 } from '@bamboohr/fabric';
import { deleteEmergencyContact } from '../api';

const EmergencyContactRepeatContainer: FunctionComponent<React.PropsWithChildren<DynamicForm.RepeatContainerElementProps>> = ({
	props,
	settings,
	context,
	children,
}) => {
	const { id, disabled } = props;
	const { canAdd, displayInitialEmpty, label } = settings;
	const [deleteField, setDeleteField] = useState<false | string>(false);
	const [initialAdded, setInitialAdded] = useState(false);
	const BlankStateComponent = isNewEmployeePage() ? EmergencyBlankNewEmployee : EmergencyBlankDefault;
	let addTemplateId: string;
	let childrenWithoutProtoLength = 0;

	const { validation } = context;

	const { unregister } = validation;

	const getDeleteData = (groupId: string) => {
		const deleteModalData = {
			contactName: '',
			contactRelationship: '',
		};
		const emergencyContactId = groupId.split('_').pop();
		const nameField = findOne(
			context,
			{ selector: 'key', value: emergencyContactId },
			{ selector: 'props.className', value: 'nameInput' }
		);
		const relationshipField = findOne(
			context,
			{ selector: 'key', value: emergencyContactId },
			{ selector: 'props.className', value: 'list-field' }
		) as DynamicForm.SelectElement;
		deleteModalData.contactName = nameField ? nameField.props.value.toString() : '';
		deleteModalData.contactRelationship = '';
		if (relationshipField) {
			const { items } = relationshipField.props;
			const selectedItem = items.find((item) => item.value === relationshipField.props.selectedValues[0]);
			deleteModalData.contactRelationship = selectedItem ? selectedItem.text : '';
		}
		return deleteModalData;
	};

	const handleAdd = (): void => {
		if (!addTemplateId) {
			console.error(`Dynamic Form: Error getting add template for repeating container with id ${id}`);
			return;
		}
		addRepeatableField(id, addTemplateId, context);
	};

	const handleRemove = (groupId: string): void => {
		if (groupId.includes('add')) {
			removeRepeatableField(id, groupId, context);
		} else {
			setDeleteField(groupId);
		}
	};

	const handleDeleteModalClose = (): void => {
		setDeleteField(false);
	};

	const handleDeleteModalAction = (): void => {
		if (typeof deleteField === 'string') {
			const groupId = deleteField;
			const emergencyContactId = groupId.split('_').pop();
			deleteEmergencyContact(window.currentlyEditingEmployeeId.toString(), emergencyContactId)
				.then(({ data = {} }) => {
					if (!data.success) {
						if (data.error) {
							window.setMessage(data.error, 'error');
						}
						return;
					}
					if (data.newPrimaryContactId) {
						const newPrimaryField = findOne(
							context,
							{ selector: 'key', value: data.newPrimaryContactId },
							{ selector: 'settings.fieldBoxClasses', value: 'primaryCheck' }
						);
						if (newPrimaryField) {
							newPrimaryField.props.checked = true;
							context.setFormData((previousFormData) => ({ ...previousFormData, ...{ [newPrimaryField.props.id]: newPrimaryField } }));
						}
					}
					unregister(groupId);
					removeRepeatableField(id, groupId, context);
				})
				.catch(({ data = {} }) => {
					window.setMessage(data.error || data.message, 'error');
				});
		}
		setDeleteField(false);
	};

	const childrenWithoutPrototype = Array.isArray(children)
		? children.map((child: React.ReactElement) => {
				if (child && child.props.props && child.props.props.className && child.props.props.className.includes('repeatPrototype')) {
					addTemplateId = child.props.props.id;
					return null;
				}
				childrenWithoutProtoLength++;
				return <EmergencyContactGroup {...child.props} handleRemove={handleRemove} key={child.props.props.id} />;
		  })
		: children;

	const hasChildren = !!childrenWithoutProtoLength;

	useEffect(() => {
		if (addTemplateId && !hasChildren && displayInitialEmpty && !initialAdded) {
			return handleAdd();
		}
		setInitialAdded(true);
	}, [addTemplateId, displayInitialEmpty, hasChildren, initialAdded]);

	return (
		<>
			{deleteField && (
				<DeleteEmergencyContactModal
					data={getDeleteData(deleteField)}
					handleClose={handleDeleteModalClose}
					handlePrimaryAction={handleDeleteModalAction}
				/>
			)}
			<div className='row repeat employee_contacts' id={id}>
				<input id='qf:emergencyContact' name='_qf__emergencyContact' type='hidden' />
				{label && <p>{label}</p>}
				{childrenWithoutPrototype}

				{!hasChildren && <BlankStateComponent canAdd={canAdd} onClick={handleAdd} />}
				{hasChildren && canAdd && (
					<button
						className='Contact__addNewContact fab-TextButton fab-link repeatAdd'
						data-bi-id='employees-contacts-add-contact'
						disabled={!canAdd || disabled}
						onClick={handleAdd}
						type='button'
					>
						<span className='fab-TextButton__icon fab-TextButton__icon--left'>
							<IconV2 name="circle-plus-regular" size={16} />
						</span>
						{$.__('Add Contact')}
					</button>
				)}
			</div>
		</>
	);
};

export default EmergencyContactRepeatContainer;
