import { classNameFromObject } from '@utils/dom';
import './styles.styl';
import { useTheme } from '@bamboohr/fabric';

/**
 * props.backgroundColor is for making the inner color of the fill match the page it's on
 */
export default function TimeSpinner(props) {
	const { palette } = useTheme();
	const { backgroundColor = palette.background.default, fillColor } = props;

	const timeSpinnerClasses = classNameFromObject({
		'TimeSpinner': true,
		'TimeSpinner--orange': fillColor === 'orange'
	});

	const timeSpinnerFillClasses = classNameFromObject({
		'TimeSpinner__fill': true,
		'TimeSpinner__fill--orange': fillColor === 'orange'
	});

	return (
		<div className={ timeSpinnerClasses }>
			<div className={ timeSpinnerFillClasses } style={ {color: backgroundColor} } />
			<span className='TimeSpinner__topButtonPost' />
		</div>
	);
}
