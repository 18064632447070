import { Component } from 'react';

import { Modal } from 'modal-legacy';
import { IconV2 } from '@bamboohr/fabric';

import './styles.styl';

export default class ForgotClockOutWarning extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			isProcessing: false
		};
	}

	_clockOut = () => {
		const { afterClockOut, beforeClockOutAction, clockOutAction, employeeId } = this.props;
		this.setState({isProcessing: true});
		beforeClockOutAction();
		clockOutAction(employeeId)
			.then(() => {
				this.setState({isOpen: false});
				afterClockOut();
			});
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({isOpen: true});
		});
	}

	render() {
		const { isOpen, isProcessing } = this.state;
		return (
			<Modal
				isOpen={ isOpen }
				isProcessing={ isProcessing }
				onClose={ () => this.setState({isOpen: false}) }
				primaryAction={ this._clockOut }
				primaryActionText={ $.__('Clock Out') }
				title={ $.__('Clock Out') }
			>
				<div className="ForgotClockOutWarningModal">
					<div className="ForgotClockOutWarningModal__icon">
						<IconV2 name="triangle-exclamation-solid" size={55} />
					</div>
					<h4 className="ForgotClockOutWarningModal__heading">{ $.__('Did you forget to clock out?') }</h4>
					<p className="ForgotClockOutWarningModal__text">{ $.__('If so, let your manager know and they can correct your timesheet.') }</p>
				</div>
			</Modal>
		);
	}
}
