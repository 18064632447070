import { IconV2 } from '@bamboohr/fabric';
import ChooseDate from 'time-tracking/modals/shared/components/choose-date.react';
import moment from 'moment.lib';
import './styles.styl';

export default (props) => {
	const {
		firstName,
		lastName,
		start,
		timezone,
		id,
		today,
		handleDateChange
	} = props;

	const startMoment = moment.tz(start, timezone);
	const todayMoment = moment.tz(today, timezone);

	const formattedTimeZone = moment.tz.zone(timezone).abbr(startMoment);
	const dateRange = startMoment.isSame(todayMoment, 'day') ? todayMoment.format('MMM D') : `${ startMoment.format('MMM D') } - ${ todayMoment.format('MMM D') }`;
	const lastClockEntry = {
		start,
		timezone
	};

	const onDateChange = (value) => {
		handleDateChange(value, id);
	};

	return (
		<div className="ClockOutSlat">

			<div className="ClockOutSlat__header">
				<div className="ClockOutSlat__employee">
					<IconV2 color='primary-strong' name='circle-user-solid' size={16} />
					<span className="ClockOutSlat__name baseColor">{ firstName } { lastName }</span>
					<span className="ClockOutSlat__timezone">({ formattedTimeZone })</span>
				</div>
				<div className="ClockOutSlat__dateRange">
					{ dateRange }
				</div>
			</div>

			<ChooseDate lastClockEntry={ lastClockEntry } onDateChange={ onDateChange } showTotal="true" />
		</div>
	);
};
