import React, { useState, useEffect } from 'react';
import { EmployeeProfileProps } from './types';
import { Avatar, Divider, BodyText, IconV2, LayoutBox } from '@bamboohr/fabric';
import { useStyles } from './employee-profile-info.styles';
import { DateTime } from 'luxon';
import { EmployeeTeamsInfo } from './employee-teams-info';
import { isEnabled } from 'FeatureToggle.util';

export const EmployeeProfileInfo = (
	props: EmployeeProfileProps,
): JSX.Element => {
	const { employee } = props;
	const [dateTime, setDateTime] = useState(DateTime.local());
	const styles = useStyles();

	useEffect(() => {
		const timerID = setInterval(() => tick(), 1000);
		const tick = () => setDateTime(DateTime.local());

		return () => clearInterval(timerID);
	}, []);

	const titleDepartment = (): string => {
		if (employee.jobTitle && employee.department) {
			return $.__('%1 in %2', employee.jobTitle, employee.department);
		} else if (employee.jobTitle && !employee.department) {
			return employee.jobTitle;
		} else if (!employee.jobTitle && employee.department) {
			return employee.department;
		}
	};

	const getWorkPhone = (): string => {
		if (!employee.ext) {
			return employee.workPhone;
		}

		return `${employee.workPhone} Ext ${employee.ext}`;
	};

	return (
		<div className={styles.container}>
			<Avatar alt="profile" size={160} srcSet={employee.imgUrl} />
			<div className={styles.contentContainer}>
				<div className={styles.primaryContentContainer}>
					<div className={styles.infoContainer}>
						<div className={styles.employeeName}>
							{employee.prefName
								? `${employee.prefName} ${employee.lastName}`
								: `${employee.firstName} ${employee.lastName}`}
						</div>
						{employee.pronouns && (
							<div className={styles.pronouns}>{`(${employee.pronouns})`}</div>
						)}
					</div>
					{(employee.jobTitle || employee.department) && (
						<div className={styles.jobInfo}>{titleDepartment()}</div>
					)}
					{employee.location && (
						<div className={styles.jobInfo}>
							{employee.location}
							{employee.timezone && (
								<span>
									{' '}
									|{' '}
									{dateTime
										.setZone(employee.timezone)
										.toLocaleString(DateTime.TIME_SIMPLE)}{' '}
									local time
								</span>
							)}
						</div>
					)}
					{employee.division && (
						<div className={styles.jobInfo}>{employee.division}</div>
					)}
				</div>
				<Divider color="neutral-extra-weak" />
				<div className={styles.secondaryContentContainer}>
					{employee.email && (
						<LayoutBox paddingBottom="10px">
							<BodyText
								color="neutral-weak"
								icon={
									<IconV2
										color="neutral-strong"
										name="envelope-regular"
										size={16}
									/>
								}
							>
								{employee.email}
							</BodyText>
						</LayoutBox>
					)}
					{employee.workPhone && (
						<LayoutBox paddingBottom="10px">
							<BodyText
								color="neutral-weak"
								icon={
									<IconV2
										color="neutral-strong"
										name="buildings-regular"
										size={16}
									/>
								}
							>
								{getWorkPhone()}
							</BodyText>
						</LayoutBox>
					)}
					{employee.personalPhone && (
						<LayoutBox paddingBottom="10px">
							<BodyText
								color="neutral-weak"
								icon={
									<IconV2
										color="neutral-strong"
										name="mobile-regular"
										size={16}
									/>
								}
							>
								{employee.personalPhone}
							</BodyText>
						</LayoutBox>
					)}
					{employee.reportsTo && (
						<LayoutBox paddingBottom="10px">
							<BodyText
								color="neutral-weak"
								icon={
									<IconV2
										color="neutral-strong"
										name="circle-user-regular"
										size={16}
									/>
								}
							>{`Reports to ${employee.reportsTo}`}</BodyText>
						</LayoutBox>
					)}
					{employee.directReports.length > 0 && (
						<LayoutBox paddingBottom="10px">
							<BodyText
								color="neutral-weak"
								icon={
									<IconV2
										color="neutral-strong"
										name="sitemap-regular"
										size={16}
									/>
								}
							>{`${employee.directReports.length} direct reports`}</BodyText>
						</LayoutBox>
					)}
					{isEnabled('CORE_EMPLOYEE_TEAMS') &&
						employee.teams &&
						employee.teams.length > 0 && (
							<EmployeeTeamsInfo teams={employee.teams} />
						)}
				</div>
			</div>
		</div>
	);
};
