import React, { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { IconV2, IconV2Size } from '@bamboohr/fabric';

/**
 * Areas to regression test this ActionFooter:
 * Employee Pages
 * Add Employee
 * NHP
 * Company Add Holidays
 * Create Time Tracking Project
 */
interface PageActionsFooterProps {
	children: React.ReactNode;
	isOpen?: boolean;
	right?: React.ReactNode;
}
const element = document.createElement('div');
const rootFooterClasses = [
	'SiteFooter',
	'SiteFooter--dark-theme',
	'SiteFooter--large-theme',
];

const getAppType = (): 'greenhouse' | 'main' => {
	return window.location.pathname.indexOf('/greenhouse') > -1
		? 'greenhouse'
		: 'main';
};
const timeOutTimer = 300;

const logoData = {
	greenhouse: {
		alt: 'BambooHR Greenhouse',
		href: '/greenhouse',
		iconName: 'fab-greenhouse-logo-150x28',
		size: 28 as IconV2Size,
	},
	main: {
		alt: 'BambooHR',
		href: 'https://www.bamboohr.com',
		iconName: 'fab-bamboohr-logo-115x17',
		size: 16 as IconV2Size,
	},
};

export const PageActionsFooter = ({
	children,
	isOpen = false,
	right,
}: PageActionsFooterProps) => {
	const appType = getAppType();
	const logoDataMap = logoData[appType];
	let footer = document.querySelector('footer');

	useEffect(() => {
		if (!footer) {
			footer = document.createElement('footer');
			footer.classList.add(...rootFooterClasses);
			document.body.appendChild(footer);
		}
		footer.appendChild(element);

		return () => {
			if (footer && footer.contains(element)) {
				footer.removeChild(element);
			}
		};
	}, [footer]);

	const toggleFooterClasses = useCallback(() => {
		if (footer) {
			footer.classList.toggle('SiteFooter--open', !!isOpen);
		}
	}, [isOpen, footer]);

	const handleToggle = useCallback(() => {
		return new Promise<void>((resolve) => {
			setTimeout(() => {
				toggleFooterClasses();
				resolve();
			}, 0);
		});
	}, [toggleFooterClasses]);

	// Force hide the Legacy action footer
	// This is a temporary solution until we can remove the legacy action footer
	// This is needed because the legacy action footer is not being hidden in some cases (EE pages)
	useEffect(() => {
		const actionFooter = document.getElementById('encore-action-footer-js');
		if (actionFooter) {
			actionFooter.style.display = 'none';
		}

		return () => {
			if (actionFooter) {
				actionFooter.style.display = '';
			}
		};
	}, []);

	const toggleOtherFooters = useCallback(() => {
		if (footer) {
			Array.from(footer.children).forEach((child) => {
				if (child !== element) {
					if (child.classList.contains('SiteFooter__container--actions')) {
						child.classList.toggle('hidden', true);
					} else {
						child.classList.toggle('hidden', !!isOpen);
					}
				}
			});
		}
	}, [isOpen, footer]);

	useEffect(() => {
		// Timeouts are present so that the animations have time to complete before footers are hidden
		if (isOpen) {
			handleToggle().then(() => {
				setTimeout(toggleOtherFooters, timeOutTimer);
			});
		} else {
			toggleFooterClasses();
			setTimeout(toggleOtherFooters, timeOutTimer);
		}
	}, [isOpen, handleToggle, toggleFooterClasses, toggleOtherFooters]);

	if (!isOpen) {
		return null;
	}

	return createPortal(
		<div
			className="SiteFooter__container SiteFooter__container--actions PageCapsule__footer--borderTop"
			id="encore-page-actions-js"
		>
			<div className="SiteFooter__wrapper SiteFooter__wrapper--outerReverse SiteContent">
				<div className="SiteFooter__wrapper SiteFooter__wrapper--left">
					{children}
				</div>
				<div className="SiteFooter__wrapper SiteFooter__wrapper--right">
					{right || (
						<a
							className="SiteFooter__link SiteFooter__link--logo"
							href={logoDataMap.href}
							rel="noopener external nofollow noreferrer"
							target="_blank"
						>
							<span className="SiteFooter__logo">
								<IconV2
									ariaLabel={logoDataMap.alt}
									name={logoDataMap.iconName}
									size={logoDataMap.size}
								/>
							</span>
						</a>
					)}
				</div>
			</div>
		</div>,
		element,
	);
};
