import {
	BodyText,
	Divider,
	Headline,
	LayoutBox,
	Section,
	TextArea,
} from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';
import { Question } from 'NHPTemplates.mod/Form/Interfaces';
import { ReactElement } from 'react';

interface Props {
	gtkyQuestions: Array<Question>;
}

export function GetIntroduced(props: Props): ReactElement {
	const { gtkyQuestions = [] } = props;

	const questionElements = gtkyQuestions.map(
		(question: Question, ind: number) => {
			const isLastQuestion =
				gtkyQuestions.indexOf(question) === gtkyQuestions.length - 1;

			return (
				<LayoutBox
					key={question.sortOrder}
					marginBottom={isLastQuestion ? 0 : '20px'}
				>
					<TextArea
						disabled={true}
						id={`question-${ind}`}
						label={question.question}
						required={question.required}
						value={question.employeeResponse ?? ''}
						width={10}
					/>
				</LayoutBox>
			);
		},
	);

	return (
		<Section>
			<Headline size='medium'>
				<Message text={$._('Get Introduced to The Team')} />
			</Headline>
			<LayoutBox marginTop='4px'>
				<BodyText size="extra-small" color="neutral-strong">
					{$._(
						`You're almost done, but first we'd like to get to know a bit more about you so we can introduce you to the rest of the team. Please answer the following questions and we'll send out a quick introduction email to the people you'll be working with.`,
					)}
				</BodyText>
			</LayoutBox>
			<Divider
				color="neutral-extra-weak"
				marginBottom='24px'
				marginTop='16px'
			/>
			<LayoutBox marginBottom='24px'>
				<Headline size="small">
					{$._('Tell us a little about yourself:')}
				</Headline>
			</LayoutBox>
			{questionElements}
		</Section>
	);
}
