/**
 * Computes whether or not to show the given separator line. If the relevant
 * pay period (previous or next) is empty or weekends are hidden and that is
 * all that was in the relevant period, then the separator should not show.
 * @param showWeekends
 * @param entries
 * @returns {boolean}
 */
export function showSeparator(showWeekends, entries) {
	const bothWeekends = (entries.length <= 2 && entries.every((e) => {
		const day = e.moment.day();
		return day === 0 || day === 6;
	}));

	if (!entries || (!showWeekends && bothWeekends)) {
		return false;
	}

	return !!entries.length;
}
