import {
	BlankState,
	Button,
	LayoutBox,
	Tooltip,
} from '@bamboohr/fabric';
import {
	noop,
} from 'lodash';

/**
 * Direct Deposit Blank State Component
 * @param {Object} props [description]
 *
 * Expected props:
 *
 * {Boolean}  hasTrax        whether TRAX integration is active
 * {Boolean}  canEdit        whether the user can edit Direct Deposit
 * {Function} buttonAction   callback for blank state call-to-action
 */
export function DepositBlankState(props) {
	const {
		hasTrax,
		buttonAction,
		canEdit,
		isAddButtonDisabled,
	} = props;

	const buttonText = $.__('Add Direct Deposit');
	const action = typeof buttonAction === 'function' ? buttonAction : noop;
	const title = hasTrax ? $.__('You will receive a paper check until you add direct deposit info.') : $.__(`You don't have any Direct Deposit accounts set up.`);

	return (
		<>
			<BlankState
				icon="bs-coin"
				level="widget"
				title={title}
			/>
			{canEdit && (
				<LayoutBox marginTop={2}>
					{isAddButtonDisabled ? (
						<Tooltip
							content={$.__("You don't have access to edit Direct Deposit.")}
							enterDelay= {300}
						>
							<LayoutBox tabIndex={0}>
								<Button
									color="secondary"
									disabled={true}
									onClick={noop}
									size="small"
									startIcon="circle-plus-regular"
									type="button"
								>
									{buttonText}
								</Button>
							</LayoutBox>
						</Tooltip>
					) : (
						<Button
							color="secondary"
							onClick={action}
							size="small"
							startIcon="circle-plus-regular"
							type="button"
						>
							{buttonText}
						</Button>
					)}
				</LayoutBox>
			)}
		</>
	);
}
