import { IconV2, Tooltip } from '@bamboohr/fabric';

import { useStyles } from './entry-project-task-note.styles';

export function EntryProjectTaskNote(props) {
	const { endString, note, isViewOnly, projectName, renderProjectAndTask, startString, taskName, type } = props;

	const styles = useStyles({ isViewOnly });

	let projectTaskString = '';
	if (projectName && taskName) {
		projectTaskString = type === 'hour' ? `${projectName} \u00BB ${taskName}` : $.__('on %1', `${projectName} \u00BB ${taskName}`);
	} else if (projectName) {
		projectTaskString = type === 'hour' ? projectName : $.__('on %1', `${projectName}`);
	}
	const tooltipTitle = type === 'hour' ? null : `${startString} - ${endString}`;

	const icon = <IconV2 color='neutral-extra-strong' name='memo-solid' size={12} />;

	return (
		<div className={styles.wrapper}>
			{renderProjectAndTask && <span className={styles.projectTaskNote}>{projectTaskString}</span>}
			{note && (
				<span className={styles.noteIcon} id='entry-note-icon'>
					{isViewOnly && (
						<Tooltip content={note} placement='right' title={tooltipTitle}>
							<span>{icon}</span>
						</Tooltip>
					)}
					{!isViewOnly && icon}
				</span>
			)}
		</div>
	);
}
