import { FunctionComponent, ReactElement } from 'react';
import { Props } from './text';
import { IconV2 } from '@bamboohr/fabric';

const iconMap = {
    default: <IconV2 color="neutral-strong" name="comment-regular" size={16}/>,
    inputAolIcon: <IconV2 color="neutral-strong" name="globe-solid" size={16}/>,
    inputSkypeIcon: <IconV2 color="neutral-strong" name="skype" size={16}/>,
    inputEducationIcon: <IconV2 color="neutral-strong" name="diploma-solid" size={16}/>,
    inputHomeIcon: <IconV2 color="neutral-strong" name="house-regular" size={16}/>,
    inputMobileIcon: <IconV2 color="neutral-strong" name="mobile-regular" size={16}/>,
    inputFacebookIcon: <IconV2 color="neutral-strong" name="facebook" size={16}/>,
    inputLinkedinIcon: <IconV2 color="neutral-strong" name="linkedin" size={16}/>,
    inputPinterestIcon: <IconV2 color="neutral-strong" name="pinterest" size={16}/>,
    inputTwitterIcon: <IconV2 color="neutral-strong" name="square-x-twitter" size={16}/>,
    inputOfficeIcon: <IconV2 color="neutral-strong" name="building-regular" size={16}/>,
    inputInstagramIcon: <IconV2 color="neutral-strong" name="square-instagram" size={16}/>,
};

const TextIconField: FunctionComponent<React.PropsWithChildren<Props>> = ({ props, input, settings, context }) => {
	const { icon } = settings;
	const inputIcon = (iconMap[icon] || iconMap.default) as ReactElement;

	return (
		<span className="fab-TextInputNub">
			{ inputIcon }
		</span>
	);
};

export default TextIconField;
