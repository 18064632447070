import {
	BlankState,
	BodyText,
	Button,
	Divider,
	Flex,
	Headline,
	IconV2,
	LayoutBox,
	Section,
	StyledBoxV2
} from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';
import { DynamicForm } from 'dynamic-form';
import { Fragment, ReactElement, useEffect } from 'react';

interface Props {
	data: any;
	isNHPT: boolean;
	photoRequired: boolean;
	photoURL: string;
	previewMode: boolean;
	showPhoto: boolean;
	employeeId: number | string;
	showPayrollFederal: boolean;
	showPayrollState: boolean;
}

export function NewEmployeeInfo(props: Props): ReactElement {
	const {
		data,
		isNHPT,
		photoURL = '',
		previewMode = true,
		showPhoto = true,
		employeeId = -1,
		showPayrollFederal = false,
		showPayrollState = false,
	} = props;

	let hasPhoto = false;

	if (!photoURL.includes('initials')) {
		hasPhoto = true;
	}

	useEffect(() => {
		$('.employeeForm .required')
			.removeClass('required')
			.addClass('visuallyRequired');
		$('.employeeForm span.inputIcons').addClass('disabled');
		$('.employeeForm .bhrToggle--btn').addClass('bhrToggle--btn--disabled');
		$('.employeeForm .fab-SelectToggle').addClass('fab-SelectToggle--disabled');
		// Disabling the date-picker and Fabric labels
		setTimeout(function () {
			$(
				'.employeeForm .fab-Label, .AdditionalWithholding .fab-FormField',
			).addClass('fab-Label--disabled');
		});
		$('.employeeForm .BhrTable__header--section').removeClass(
			'BhrTable__header--section',
		);
		$('.employeeForm')
			.find('input, button, ba-select')
			.addClass('disabled')
			// @ts-ignore
			.attr('disabled', true);
	});

	function onPhotoUploadHandler() {
		if (previewMode) {
			window.disabledForPermsTest();
		}
	}

	return (
		<Fragment>
			<Section>
					<Headline color="primary" component="h2" size="medium">
						<Message text={$._('New Employee Information')} />
					</Headline>
					<BodyText>
						<Message text={$._(`Let's start with the basics. `)} />
						<Message
							text={$._(
								`Please complete the following new employee form and add your profile photo. `,
							)}
						/>
						<Message
							text={$._(
								`This will ensure we have the most up-to-date information. `,
							)}
						/>
						<Message
							text={$._(
								`It will also help us get you up and running quickly with the required paperwork. `,
							)}
						/>
					</BodyText>
					{isNHPT && (
						<>
							<Divider
								color="neutral-extra-weak"
								marginBottom="24px"
								marginTop="16px"
							/>

							<LayoutBox paddingTop="20px">
								<BlankState
									icon="bs-tumbleweed"
									level="page"
									subtitle={$.__(
										'You’ll be able to preview this step, along with the actual fields, when sending the actual New Hire Packet.',
									)}
									title={$.__(
										`Because this is just the template, we can't show an accurate preview of this step.`,
									)}
								/>
							</LayoutBox>
						</>
					)}
				</Section>
			{isNHPT && undefined}
			{!isNHPT && (
				<Fragment>
					{showPhoto && (
						<StyledBoxV2
							backgroundColor="neutral-weak"
							borderRadius="medium"
							marginBottom={3}
							marginTop={3}
							padding={4}
						>
							<Headline size="medium">{$.__('Profile Photo')}</Headline>
							<input
								id="photo"
								name="photo"
								type="hidden"
								value={hasPhoto === true ? 1 : undefined}
							/>
							<StyledBoxV2
								backgroundColor="neutral-strong"
								borderRadius="large"
								height="224px"
								marginTop={4}
								width="224px"
							>
								{photoURL.includes('initials') ? (
									<Flex
										alignItems="center"
										height="224px"
										justifyContent="center"
										width="224px"
									>
										<IconV2
											color="neutral-forced-white"
											name="user-solid"
											size={170}
										/>
									</Flex>
								) : (
									<img
										alt="Employee Photo"
										src={photoURL}
										style={{ borderRadius: 15 }}
										width="100%"
									/>
								)}
							</StyledBoxV2>
							<StyledBoxV2 marginLeft={3} marginTop={4}>
								<Button
									color="secondary"
									onClick={onPhotoUploadHandler}
									size="large"
									startIcon='circle-plus-regular'
								>
									{$._('Add\nPhoto')}
								</Button>
							</StyledBoxV2>
						</StyledBoxV2>
					)}
					<div className="employeeForm">
						<DynamicForm
							additionalOptions={{
								employeeId,
								isNHPPacket: true,
								showPayrollFederal,
								showPayrollState,
							}}
							data={data}
							isAddEmployeeForm={false}
							previewMode={true}
						/>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
}
