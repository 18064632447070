import Ajax from '@utils/ajax';
import { Address, OnSaveMethodProps, SuggestedAddress, ValidateAddressIsEnabledResponse } from './types';
import { renderAddressValidationModal } from './render-address-validation-modal';
import { renderNoSuggestionModal } from './render-no-suggestion-modal';
import { renderErrorModal } from './render-error-modal';
import { AxiosPromise } from 'axios';


const callIsEnabledEndpoint = (): AxiosPromise<ValidateAddressIsEnabledResponse> => {
  return Ajax.get('/address-validation/is-enabled');
}

/**
 * Show the address validation modal with the suggested address.
 */
const onSuggestionFound = (
  originalAddress: Address,
  suggestedAddress: SuggestedAddress,
  onSubmit: (address?: Address) => void,
  onCancel: () => void
) => {
  renderAddressValidationModal({
    onCancel,
    onSave: ({
      useOriginalAddress,
      useSuggestedAddress,
      suggestedAddress,
    }: OnSaveMethodProps) => {
      if (useOriginalAddress) {
        onSubmit(originalAddress);
      } else if (useSuggestedAddress) {
        onSubmit(suggestedAddress);
      }
    },
    originalAddress: originalAddress,
    suggestedAddress: suggestedAddress,
  });
}

/**
 * Show the address validation "error" modal.
 */
const onValidationError = (
  originalAddress: Address,
  onSubmit: (address?: Address) => void,
  onCancel: () => void
) => {
  renderErrorModal({
    onCancel,
    onSave: () => onSubmit(originalAddress),
    originalAddress: originalAddress,
  });
}

/**
 * Show the address validation "no suggestion" modal.
 */
const onNoSuggestionFound = (
  originalAddress: Address,
  onSubmit: (address?: Address) => void,
  onCancel: () => void
) => {
  renderNoSuggestionModal({
    onCancel,
    onSave: () => onSubmit(originalAddress),
    originalAddress: originalAddress,
  });
}

/**
 * Perform address validation, and render the appropriate modal based on the result.
 *
 * @param originalAddress The address to validate
 * @param onSubmit Submit callback
 * @param onCancel Cancel callback
 */
export const validateAddress = async (
  originalAddress: Address,
  onSubmit: (address: Address) => void,
  onCancel: () => void
) => {
  // if address validation is disabled, just submit the address as is.
  const { data } = await callIsEnabledEndpoint();
  if (!data.isEnabled) {
    onSubmit(originalAddress);
    return;
  }

  await Ajax.post(
    '/address-validation/validate-address',
    originalAddress,
  ).then(({data}) => {
    // if address is valid, submit it as is.
    if (data.isValid) {
      onSubmit(originalAddress);
    } else if (data.error) {
      // http status is 200 ok, but payload contains an error message
      onValidationError(originalAddress, onSubmit, onCancel);
    } else if (data.suggestedAddress) {
      // address is not valid, but we have a suggested address.
      onSuggestionFound(originalAddress, data.suggestedAddress, onSubmit, onCancel);
    } else {
      // address is not valid, and the api returned with no suggestions.
      onNoSuggestionFound(originalAddress, onSubmit, onCancel);
    }
  }).catch(error => {
    onValidationError(originalAddress, onSubmit, onCancel);
  })
};