import SummaryBreakdown from './summary-breakdown.react';

import {
	BadgeV2,
	BodyText,
	Divider,
	Flex,
	IconTile,
	InlineMessage,
	LayoutBox,
} from '@bamboohr/fabric';

export default function SummaryPayPeriod(props) {
	const {
		approvalMonthAndDayStr,
		payPeriodRangeText,
		payPeriodTotal,
		periodOvertime,
		periodTimeOff,
		periodHoliday,
		shiftDifferentials,
		holidaySummary,
		hourSummary,
	} = props;

	return (
		<div className='TimesheetSummary__payPeriod js-timesheet-showPayPeriodTotal'>
			<Flex flexDirection='column' gap={1.5}>
				<BadgeV2
					color='neutral-extra-extra-strong'
					icon={<IconTile icon='calendar-regular' size={40} variant='secondary'/>}
					size='small'
					subtitle={
						<>
							<BodyText color='neutral-weak' size='small'>
								{$.__('Pay Period')}
							</BodyText>
							<BodyText color='neutral-weak' size='small'>
								{payPeriodRangeText}
							</BodyText>
						</>
					}
					title={payPeriodTotal}
				/>
				{!!periodOvertime.length ||
				!!periodTimeOff ||
				!!periodHoliday ||
				!!shiftDifferentials.length ||
				!!holidaySummary.length ||
				!!hourSummary.length ? (
					<>
						<LayoutBox width='100%'>
							<Divider color='neutral-extra-weak' marginTop={0.5}/>
						</LayoutBox>
						<SummaryBreakdown {...{
							periodOvertime,
							periodTimeOff,
							periodHoliday,
							shiftDifferentials,
							holidaySummary,
							hourSummary,
						}} />
					</>
				) : null}
				<InlineMessage
					description={$.__('Will be sent for approval on %s', approvalMonthAndDayStr, {note: 'placeholder is a date/time'})}
					icon='circle-check-regular'
				/>
			</Flex>
		</div>
	);
}
