import {
	BodyText,
	Divider,
	Flex,
	Headline,
	LayoutBox,
	Section
} from '@bamboohr/fabric';
import { Fragment, ReactElement } from 'react';
import { EmployeeInfo } from '../EmployeeInfo';

import { Team } from 'NHPTemplates.mod/Previewer/Components/Finished/teamInterface';

interface Props {
	employeeName: string;
	companyName: string;
	children: Array<ReactElement>;
	team?: Team;
	isNHPTemplate?: boolean;
}

export function Finished(props: Props): ReactElement {
	const {
		employeeName,
		companyName,
		children,
		team,
	} = props;

	const { manager, members = [] } = team;

	return (
		<Fragment>
			<Section>
				<Flex flexDirection='column' height='100%'>
					<Headline size="medium">
						{$.__(`%1$s, You're All Ready To Go.`, employeeName)}
					</Headline>
					<BodyText size="extra-small">
						{$.__(
							`We're looking forward to having you here at %1$s.`,
							companyName,
						)}
					</BodyText>
					<Divider color="neutral-extra-weak" marginTop='17px' />
				</Flex>
				<LayoutBox marginBottom={4} marginTop={4}>
					{children}
				</LayoutBox>
				{(members?.length > 0 || manager) && (
					<LayoutBox>
						<Divider color="neutral-extra-weak" />
						<LayoutBox margin='32px 0px 20px'>
							<Headline size="small">{$._('Get to Know the Team')}</Headline>
						</LayoutBox>
						{manager && (
							<LayoutBox marginBottom='15px'>
								<LayoutBox marginBottom='20px'>
									<Headline size="extra-small" color="neutral-strong">
										{$._('Your Manager')}
									</Headline>
								</LayoutBox>
								<EmployeeInfo employee={manager} avatarSize={32} />
							</LayoutBox>
						)}
						{members && members.length > 0 && (
							<>
								<LayoutBox marginBottom='15px'>
									<Headline size="extra-small" color="neutral-strong">
										{$._('Your Team')}
									</Headline>
								</LayoutBox>
								<Flex gap={5} flexWrap='wrap'>
									{members.map((employee) => (
										<EmployeeInfo
											avatarSize={32}
											employee={employee}
											key={`${employee.firstName}_${employee.lastName}`}
										/>
									))}
								</Flex>
							</>
						)}
					</LayoutBox>
				)}
			</Section>
		</Fragment>
	);
}
