import { render } from 'base/wrapped-render';
import {
	renderToStaticMarkup
} from 'react-dom/server';
import {
	getMaxZIndex,
} from '@utils/dom';

import 'jquery.calendar-picker.lib';
import {
	isEnabled,
} from 'FeatureToggle.util';
import { ifFeature } from '@bamboohr/utils/lib/feature';

// To reduce the amount of risk taken on by the calendar update we are only targeting the NHP, to fix this proper all uses of this should be swapped out
const replaceCalendarComponent = window.location.href.includes('/self_onboarding/');

import { convertAttributesAsReactPropsObject, SingleDateComponent } from './FabricCalendarPicker.mod';

const DEFAULT_CALENDAR_SETTINGS = {
	toggleButton: ifFeature('encore', 
		`<div class="fab-PickerButton">
			<div class="fab-PickerButton__divider"></div>
			<button type="button" class="fab-PickerButton__button">
				${ renderToStaticMarkup(
					<ba-icon encore-name="calendar-regular" encore-size="16" />
				) }
			</button>
		</div>`,
		`<button type="button" class="fab-TextInput__button fab-TextInput__button--right">
			${ renderToStaticMarkup(
				<span className="fab-TextInput__buttonIcon">
					<ba-icon name="fab-calendar-15x16" />
				</span>
			) }
		</button>`
	),
	events: {
		onRender: setUpChosen,
		onAfterShow: setUpChosen
	},
};

$(function() {
	createCalendarPickers($('[calendar-picker]'));

	$('[calendar-picker]').observe(function() { createCalendarPickers(this); });
});

/**
 * @deprecated
 * @param elements
 */
export function createCalendarPickers(elements) {
	if (replaceCalendarComponent) {
		createFabricDatePickers(elements);
	} else {
		legacyCreator(elements);
	}
}

function shouldMask($element) {
	return (
		!$element.is('[no-mask]')
	);
}

function setUpMask($input, format) {
	var dateMask = format || window.GLOBAL_DATE_MASK || 'mm/dd/yyyy';

	if ($input.inputmask) {
		$input.inputmask({
			alias: dateMask,
			oncomplete() {
				$input.trigger('inputmask:complete');
				$input.one('blur', () => {
					$input.trigger('change');
				});
			},
			onincomplete() {
				$input.trigger('inputmask:incomplete');
			},
			isComplete(buffer, opts) {
				const dateFormat = moment.convert.jQuery(dateMask);

				return moment(buffer.join(''), dateFormat, true).isValid();
			},
		})
			.trigger('masked');
	}
}

function setUpPlaceholder($input) {
	if (!$input.attr('placeholder') && isEnabled('jade')) {
		$input.attr('placeholder', $input.inputmask('getemptymask'));
	}

	$input.data('placeholderTrigger', 'focus');
}

function getCustomFormat($element) {
	let format = null;

	switch ($element.attr('calendar-picker')) {
		case 'month':
		case 'month-range':
			format = 'MMM YYYY';
			break;
	}

	return format;
}

function setUpRangeLegacy($element, settings) {
	var $start = $element.find('[range-start]');
	var $end = $element.find('[range-end]');

	if (shouldMask($element)) {
		setUpMask($start, settings.customFormat);
		setUpMask($end, settings.customFormat);
	}

	$element.calendarPicker(settings);

	setUpPlaceholder($start);
	setUpPlaceholder($end);

	setUpValidation($start);
	setUpValidation($end);
}

function setUpSingleLegacy($input, settings) {
	if (shouldMask($input)) {
		setUpMask($input, settings.customFormat);
	}

	$input.calendarPicker(settings);

	setUpPlaceholder($input);

	setTimeout(() => {
		setUpValidation($input);
	});
}

function setUpValidation($input) {
	var $form = $input.closest('[bhrvalidate-initiated]');

	if (!$form.length) {
		return;
	}

	$input.rules('add', {
		validateCalendarPicker: true
	});
}

function setUpChosen(calendarPicker) {
	const {$popover} = calendarPicker;
	const $popoverSelects = $popover.find('select');

	if (!$popover.is(':visible')) {
		return;
	}

	calendarPicker.$popover.css({
		zIndex: getMaxZIndex()
	});

	$popoverSelects
		.attr('data-disable-search', 'true')
		.chosen();
}

function legacyCreator(elements) {
	$(elements).each(function(index, element) {
		const $element = $(element);
		legacyCalendarRender($element);
	});
}

function legacyCalendarRender($element) {
	const settings = {
		...DEFAULT_CALENDAR_SETTINGS,
		customFormat: getCustomFormat($element),
		noFacade: $element[0].hasAttribute('no-facade') && $element[0].getAttribute('no-facade') !== 'false'
	};
	switch ($element.attr('calendar-picker')) {
		case 'date-range':
		case 'month-range':
			setUpRangeLegacy($element, settings);
			break;
		default:
			setUpSingleLegacy($element, settings);
			break;
	}
}

function setUpSingle($input, props) {
	const element = $input.parent('.dateFieldWrapper')[0];
	if (element) {
		render(<SingleDateComponent {...props} />, element);

		setTimeout(() => {
			setUpValidation($input);
		});
	}
}

function setUpRange($element, props) {
	// We don't need to address this at this time for A11Y compliance. At the time of writing this we had 14 instances of range picker via twig with 5 being in greenhouse. We may just want to convert those instead of trying to make it fit here.
	setUpRangeLegacy($element, props);
}

function getCalendarRendererByType(calendarType, renderLegacy = false) {
	if (renderLegacy) {
		switch (calendarType) {
			case 'date-range':
			case 'month-range':
				return setUpRangeLegacy;
			default:
				return setUpSingleLegacy;
		}
	}
	switch (calendarType) {
		case 'date-range':
			return setUpRange;
		case 'month':
			return setUpSingleLegacy;
		case 'month-range':
			return setUpRangeLegacy;
		default:
			return setUpSingle;
	}
}

function createFabricDatePickers(elements) {
	$(elements).each(function(index, element) {
		const $element = $(element);
		if ($element.parents('.repeatPrototype')[0]) {
			// We don't want to convert the prototypes because then we lose all the data
			return;
		}

		if ($element[0].hasAttribute('render-jQuery-date-picker')) {
			legacyCalendarRender($element);
		}

		const props = convertAttributesAsReactPropsObject(element);

		const calendarRenderer = getCalendarRendererByType($element.attr('calendar-picker'));

		calendarRenderer($element, { customFormat: getCustomFormat($element), ...props });
	});
}
