import React from 'react';
import Error from './error';
import { uniqueId } from 'lodash';
import { resolveResourceUrl } from './resources/resolve-resource-url';
import { setResourceUrl } from './resources/set-resource-url';

const manifestPromisesByPath = {};
function getManifestPromise(path, forwardedProps) {
	if (manifestPromisesByPath[path] && forwardedProps.retry !== true) {
		return manifestPromisesByPath[path];
	}

	const { host, name, noLoadJSFromManifest, loadScript } = forwardedProps;
	manifestPromisesByPath[path] = fetch(path)
		.then((response) => response.json())
		.then((fetchedManifest) => {
			if (fetchedManifest) {
				setResourceUrl(name, host, fetchedManifest['main.js']);

				// Attach CSS files
				Object.keys(fetchedManifest)
					.filter((key) => key.endsWith('.css'))
					.forEach((key) => {
						const link = document.createElement('link');
						link.rel = 'stylesheet';
						link.href = fetchedManifest[key].startsWith('http')
							? fetchedManifest[key]
							: resolveResourceUrl(host, fetchedManifest[key]);
						link.dataset.microfrontend = name;
						document.head.appendChild(link);
					});

				// Attach JS files
				const scripts = Object.keys(fetchedManifest)
					.filter((key) => key.endsWith('.js'))
					.filter((key) => !noLoadJSFromManifest.includes(key))
					.map((file) => loadScript(fetchedManifest[file], file));

				return scripts;
			}
		});
	return manifestPromisesByPath[path];
}

export class MicroFrontend extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: false, containerId: uniqueId('micro-container') };
	}

	_loadResource(retry = false) {
		const { host, manifest, name, noLoadJSFromManifest } = this.props;

		const loadScript = (url, file) => {
			return new Promise((resolve) => {
				const tag = document.createElement('script');
				tag.src = url.startsWith('http') ? url : resolveResourceUrl(host, url);
				tag.async = true;
				tag.onload = () => resolve();
				tag.dataset.microfrontend = name;
				document.head.appendChild(tag);
			});
		};

		const manifestPath = `${host}/${manifest}`;
		const manifestPromise = getManifestPromise(manifestPath, {
			host,
			manifest,
			name,
			noLoadJSFromManifest,
			loadScript,
			retry,
		});

		this._processManifestPromise(manifestPromise, retry);
	}

	_processManifestPromise(manifestPromise, hasRetried) {
		const retryWaitTime = 1000;
		manifestPromise
			.then((scripts) => {
				Promise.all(scripts).then(() => this._renderMicroFrontend());
			})
			.catch((error) => {
				// We only want to retry once
				if (hasRetried) {
					this.setState({ error: true });
					console.warn(error);
				} else {
					setTimeout(() => {
						this._loadResource(true);
					}, retryWaitTime);
				}
			});
	}

	_unmountMicroFrontend() {
		const { host, manifest, name } = this.props;
		const { containerId } = this.state;
		const manifestPath = `${host}/${manifest}`;

		const unmountFunction = window[`unmount_${name}`];
		if (typeof unmountFunction === 'function') {
			unmountFunction(containerId);
		} else {
			console.error(`No function exists to unmount "${name}" micro front-end!`);
		}

		// Remove all stylesheet/script elements added from the manifest
		document.querySelectorAll(`[data-microfrontend="${name}"]`).forEach((element) => {
			element.parentNode.removeChild(element);
		});

		// Remove manifest from cache so that it can be refetched
		delete manifestPromisesByPath[manifestPath];
	}

	_renderMicroFrontend = () => {
		const { name, payload } = this.props;
		const { containerId } = this.state;

		// eslint-disable-next-line no-unused-expressions
		window[`render_${name}`]?.(containerId, { ...payload });
	};

	componentDidMount() {
		this._loadResource();
	}

	componentDidUpdate(prevProps) {
		const { host } = this.props;

		if (prevProps.host && prevProps.host !== host) {
			this._unmountMicroFrontend();
			this._loadResource();
		} else {
			this._renderMicroFrontend();
		}
	}

	componentWillUnmount() {
		this._unmountMicroFrontend();
	}

	render() {
		const { className, customErrorMessage } = this.props;
		const { error, containerId } = this.state;

		if (error) {
			return <Error customMessage={customErrorMessage} />;
		}

		return <main className={className} id={containerId} aria-label={containerId} style={{ height: '100%', width: '100%' }} />;
	}
}

MicroFrontend.defaultProps = {
	customErrorMessage: '',
	payload: {},
	host: '',
	name: '',
	resourceType: '',
	window,
	noLoadJSFromManifest: [],
};
