import { RenderErrorOrNoSuggestionModalProps } from './types';
import { PoMicroFrontend } from 'micro-frontend.react';
import React from 'react';
import { render } from 'base/wrapped-render';

/**
 * Render the address validation "error" modal
 */
export function renderErrorModal({
  onCancel,
  onSave,
  originalAddress,
}: RenderErrorOrNoSuggestionModalProps): void {
  const rootEl = document.createElement('div');
  document.body.appendChild(rootEl);

  render(
    <PoMicroFrontend
      route="/address-validation/error-modal"
      pageLoadData={{
        onCancel,
        onSave,
        originalAddress,
      }}
    />,
    rootEl,
  );
}
