import { Message } from '@bamboohr/utils/lib/message';
import { Modal } from 'modal-legacy';
import { PureComponent, ReactElement } from 'react';


import { IconV2, LayoutBox } from '@bamboohr/fabric';
import './styles.styl';

interface EmployeeData {
	fullName: string;
	firstName: string;
	jobTitle: string;
}

interface Props {
	body: string;
	employeeBadgeComponent: ReactElement;
	employeeData: EmployeeData;
	getToKnowYouQuestions: Array<string>;
	isOpen: boolean;
	primaryAction: () => void;
	showQuestionsPlaceholder: boolean;
}

export class WelcomeEmailPreviewer extends PureComponent<Props> {
	_renderGTKYQuestions(showPlaceHolder): ReactElement {
		const { employeeData, getToKnowYouQuestions = [] } = this.props;

		if (showPlaceHolder) {
			return (
				<div className="WelcomeEmailPreviewer__placeholderQuestion">
					<LayoutBox marginRight={'12px'}>
						<IconV2
							name="person-circle-question-solid"
							color="neutral-weak"
							size={36}
						/>
					</LayoutBox>
					<div className="PlaceholderQuestion__text">
						{$.__(
							'The “Get to Know You” questions and the employee responses will show in this space.',
						)}
					</div>
				</div>
			);
		}

		return (
			<div className="WelcomeEmailPreviewerQuestions">
				<h4 className="WelcomeEmailPreviewerQuestions__title">
					Get to Know {employeeData.firstName}
				</h4>
				{getToKnowYouQuestions.map((question, index) => {
					return (
						<div key={index}>
							<div className="WelcomeEmailPreviewerQuestions__question">
								{question}
							</div>
							<div className="WelcomeEmailPreviewerQuestions__answer">
								Employee's answer will go here.
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	render(): ReactElement {
		const {
			body = '',
			employeeBadgeComponent,
			employeeData,
			isOpen,
			primaryAction,
			showQuestionsPlaceholder = false,
		} = this.props;

		const { fullName, firstName, jobTitle } = employeeData;

		return (
			<Modal
				alternativeActionText={null}
				contentHasPadding={false}
				isOpen={isOpen}
				onClose={primaryAction}
				primaryAction={primaryAction}
				primaryActionText={$.__('Close')}
				title={$.__('Preview of Get to Know You Email')}
			>
				<div className="WelcomeEmailPreviewer">
					<div className="WelcomeEmailPreviewer__emailHeader">
						<div className="EmailHeader__icon">
							<IconV2
								name={'hand-wave-regular'}
								size={60}
								color="neutral-forced-white"
							/>
						</div>
						<p className="EmailHeader__title">
							<Message params={[fullName]} text={$._('Welcome {1}!')} />
						</p>
						{jobTitle !== '' && (
							<p className="EmailHeader__subTitle">
								<Message
									params={[firstName, jobTitle]}
									text={$._('{1} joins us today as {2}')}
								/>
							</p>
						)}
						<div className="borderBar"></div>
					</div>
					{employeeBadgeComponent && (
						<div className="WelcomeEmailPreviewer__badgeContainer">
							{employeeBadgeComponent}
						</div>
					)}
					<div className="WelcomeEmailPreviewer__emailBody">
						<div className="WelcomeEmailPreviewer__emailBodyText">{body}</div>
						<hr />
						<div>{this._renderGTKYQuestions(showQuestionsPlaceholder)}</div>
					</div>
				</div>
			</Modal>
		);
	}
}
