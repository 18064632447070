import { IconV2 } from '@bamboohr/fabric';
import { FunctionComponent } from 'react';

export const HoverEmail: FunctionComponent<React.PropsWithChildren<DynamicForm.Hover>> = ({
	value,
	isEditable,
	context,
	children,
}) => {
	const {
		controls: { HoverBase },
	} = context;

	return (
        <HoverBase hoverChild={ children } isEditable={ isEditable }>
            <>
				<span className="HoverHint__icon">
					<IconV2 color='info-strong' name="envelope-solid" size={ 16 } />
				</span>
				<a className="HoverHint__link" href={ `mailto:${ value }` }>
					{ $.__('Email') }
				</a>
			</>
        </HoverBase>
    );
};

export default HoverEmail;
